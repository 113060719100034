import axios from 'axios'
import AppConstants from '../../ApplicationConstants'
export const seeProfileInformation = async (id) => {
    axios.get(`${AppConstants.userApi}getUserId/${id}`).then(async res => {
        if (res.data.data[0].isProfessional) {
            window.location.href = `${AppConstants.BASE_DEV_URL +
                `/professional-profile/adminDomain/` +
                id}`
        }
        else {
            window.location.href = `${AppConstants.BASE_DEV_URL +
                `/student-profile/adminDomain/` +
                id}`
        }
    });
}