export async function EmotionButtonsFunctionality(rawStoriesArray) {
    const tempArr = []
    await rawStoriesArray.map(async (obj, index) => {
        tempArr.push(new Promise(async (resolve, reject) => {
            const emotionsArr = []
            await obj.feelings.map(async (ele, index) => {
                if (ele == 'Love') {
                    emotionsArr.push({ emotion: "Love", emotionBtnBgColor: "Emt-btn-love " })
                } else if (ele == 'Submission') {
                    emotionsArr.push({ emotion: "Submission", emotionBtnBgColor: "Emt-btn-submission" })
                } else if (ele == 'Anxiety') {
                    emotionsArr.push({ emotion: "Anxiety", emotionBtnBgColor: "Emt-btn-anxiety" })
                } else if (ele == 'Awe') {
                    emotionsArr.push({ emotion: "Awe", emotionBtnBgColor: "Emt-btn-awe" })
                } else if (ele == 'Disapproval') {
                    emotionsArr.push({ emotion: "Disapproval", emotionBtnBgColor: "Emt-btn-disapproval" })
                } else if (ele == 'Remorse') {
                    emotionsArr.push({ emotion: "Remorse", emotionBtnBgColor: "Emt-btn-remorse" })
                } else if (ele == 'Contempt') {
                    emotionsArr.push({ emotion: "Contempt", emotionBtnBgColor: "Emt-btn-contempt" })
                } else if (ele == 'Aggression') {
                    emotionsArr.push({ emotion: "Aggression", emotionBtnBgColor: "Emt-btn-aggression" })
                } else if (ele == 'Optimism') {
                    emotionsArr.push({ emotion: "Optimism", emotionBtnBgColor: "Emt-btn-optimism" })
                } else if (ele == 'Joy') {
                    emotionsArr.push({ emotion: "Joy", emotionBtnBgColor: "Emt-btn-joy" })
                } else if (ele == 'Trust') {
                    emotionsArr.push({ emotion: "Trust", emotionBtnBgColor: "Emt-btn-trust" })
                } else if (ele == 'Fear') {
                    emotionsArr.push({ emotion: "Fear", emotionBtnBgColor: "Emt-btn-fear" })
                } else if (ele == 'Surprise') {
                    emotionsArr.push({ emotion: "Surprise", emotionBtnBgColor: "Emt-btn-surprise" })
                } else if (ele == 'Sadness') {
                    emotionsArr.push({ emotion: "Sadness", emotionBtnBgColor: "Emt-btn-sadness" })
                } else if (ele == 'Disgust') {
                    emotionsArr.push({ emotion: "Disgust", emotionBtnBgColor: "Emt-btn-disgust" })
                } else if (ele == "Anger") {
                    emotionsArr.push({ emotion: "Anger", emotionBtnBgColor: "Emt-btn-anger" })
                } else if (ele == 'Anticipation') {
                    emotionsArr.push({ emotion: "Anticipation", emotionBtnBgColor: "Emt-btn-anticipation" })
                }
                else {
                    resolve();
                }
            })
            obj['emotionTags'] = JSON.parse(JSON.stringify(emotionsArr))
            return Promise.resolve(obj)
        })

        )
        return Promise.resolve(rawStoriesArray)
    })
    return rawStoriesArray;
}