export async function timeCalculation(latestResponse) {
  latestResponse && latestResponse.map(async (obj, index) => {
    var d1 = new Date()
    var d2 = new Date(obj.createdAt)
    let d3 = d1.getTime() - d2.getTime()
    let commentedOn = ''
    let temp = []
    temp.push(
      new Promise((resolve, reject) => {
        if (Math.round(d3 / 31104000000)) {
          commentedOn = Math.round(d3 / 31104000000) == 1 ? `${Math.round(d3 / 31104000000)} year ago` : `${Math.round(d3 / 31104000000)} years ago`
          obj['commentedOn'] = commentedOn
          resolve()
        } else if (Math.round(d3 / 2592000000)) {
          commentedOn = Math.round(d3 / 2592000000) ? `${Math.round(d3 / 2592000000)} month ago` : `${Math.round(d3 / 2592000000)} months ago`
          obj['commentedOn'] = commentedOn
          resolve()
        } else if (Math.round(d3 / 86400000)) {
          commentedOn = Math.round(d3 / 86400000) == 1 ? `${Math.round(d3 / 86400000)} day ago` : `${Math.round(d3 / 86400000)} days ago`
          obj['commentedOn'] = commentedOn
          resolve()
        } else if (Math.round(d3 / 3600000)) {
          commentedOn = Math.round(d3 / 3600000) == 1 ? `${Math.round(d3 / 3600000)} hr ago` : `${Math.round(d3 / 3600000)} hrs ago`
          obj['commentedOn'] = commentedOn
          resolve()
        } else if (Math.round(d3 / 60000)) {
          commentedOn = Math.round(d3 / 60000) == 1 ? `${Math.round(d3 / 60000)} min ago` : `${Math.round(d3 / 60000)} mins ago`
          obj['commentedOn'] = commentedOn
          resolve()
        } else commentedOn = Math.round(d3 / 1000) == 1 ? `${Math.round(d3 / 1000)} sec ago` : `${Math.round(d3 / 1000)} secs ago`
        obj['commentedOn'] = commentedOn
        resolve()
      })
    )
    return Promise.resolve(latestResponse)
  })
  return latestResponse;
}
