
import './App.css';
// import { socket, SocketContext } from './context/socket'
import Routers from './routes'
import jwt_decode from 'jwt-decode';


function App() {

  if (localStorage.accessToken) {
    const decoded = jwt_decode(localStorage.accessToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      localStorage.clear();
      window.location.href = "/";
    }
    else if(!localStorage.userId){
      window.location.href = "/";
    }
  }else{
    console.log("localStorage.jwtTokenAdmin");
  }
  return (
    // <SocketContext.Provider value={socket}>
      <div className="App">
        <Routers />
      </div>
    // </SocketContext.Provider>
  );
}

export default App;
