import React from 'react';
import { useEffect } from 'react';
import {
    useNavigate,
    //  useParams, 
    useLocation
} from 'react-router-dom';
import AppConstants from '../../ApplicationConstants'
import axios from 'axios'

const Storiesauthentication = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    // const params = useParams();

    //  ********************************************
    //        Setting local storage
    //  ********************************************
    let totalToken = location.search
    const setLocalStorage = async () => {
        if (totalToken.length > 0) {
            let myArray = totalToken.split('&')
            let userId = myArray[0].toString().substring(1);
            localStorage.setItem('userId', userId);
            localStorage.setItem('accessToken', myArray[1]);
            localStorage.setItem('isAdmin', myArray[2]);
            localStorage.setItem('universityId', myArray[3]);
            localStorage.setItem('isUpsquadStatus', myArray[4]);
            localStorage.setItem('refreshToken', myArray[5]);
            localStorage.setItem('apiKey', AppConstants.API_KEY)
            await axios.get(`${AppConstants.userApi}getUserId/${userId}`).then(async res => {
                const userData = res.data.data[0]
                localStorage.setItem('fName', userData.firstName);
                localStorage.setItem('lName', userData.lastName);
                localStorage.setItem('email', userData.email);
                navigate('/dmFsaWRhdGUtc3Rvcmllcw==')
            })
        }
    }
    useEffect(() => {
        let localStorageStatus = setLocalStorage()
    }, []);
    return (
        <div className="upsquardStoriesPage">
            <div className="storiesAuthentication loading"></div>
        </div>
    );
};

export default Storiesauthentication;