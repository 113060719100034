import axios, { Axios } from 'axios'
import serviceIntercept from '../../services/interceptorServices'
import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  AUTHENTICATE_USER_REQUEST,
  AUTHENTICATE_USER_SUCCESS,
  VALIDATE_USER_REQUEST,
  VALIDATE_USER_SUCCESS,
  FETCH_USER_SETTINGS_REQUEST,
  FETCH_USER_SETTINGS_SUCCESS,
  FETCH_PROFILE_ORGANIZATIONS_LIST_REQUEST,
  FETCH_PROFILE_ORGANIZATIONS_LIST_SUCCESS,
  FETCH_STORIES_REQUEST,
  FETCH_STORIES_SUCCESS,
  INSERT_STORIES_REQUEST,
  INSERT_STORIES_SUCCESS,
  UPDATE_STORIES_REQUEST,
  UPDATE_STORIES_SUCCESS,
  DELETE_STORIES_REQUEST,
  DELETE_STORIES_SUCCESS,
  REPORT_STORY_REQUEST,
  REPORT_STORY_SUCCESS,
  MY_STORIES_REQUEST,
  MY_STORIES_SUCCESS,
  TAP_STORY_REQUEST,
  TAP_STORY_SUCCESS,
  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_SUCCESS,
  INSERT_COMMENTS_REQUEST,
  INSERT_COMMENTS_SUCCESS,
  FETCH_EMOJIS_REQUEST,
  FETCH_EMOJIS_SUCCESS,
  INSERT_EMOJIS_REQUEST,
  INSERT_EMOJIS_SUCCESS,
  FETCH_VIDEOS_REQUEST,
  FETCH_VIDEOS_SUCCESS,
  INSERT_VIDEOS_REQUEST,
  INSERT_VIDEOS_SUCCESS,
  FETCH_ACTION_FAIL,
  SEARCH_STORIES_REQUEST,
  SEARCH_STORIES_SUCCESS,
  STORE_STORY_OBJECT_REQUEST,
  STORE_STORY_OBJECT_SUCCESS,
  VISIBILITY_SETTINGS_STORY_OBJECT_DATA_REQUEST,
  VISIBILITY_SETTINGS_STORY_OBJECT_DATA_SUCCESS,
  FETCH_PUBLIC_STORY_REQUEST,
  FETCH_PUBLIC_STORY_SUCCESS,
  RESET_RESPONSE_PROPERTIES_REQUEST,
  RESET_RESPONSE_PROPERTIES_SUCCESS,
  RESET_STORY_OBJECT_REQUEST,
  RESET_STORY_OBJECT_SUCCESS,
  STORE_SEARCHED_EMOTIONS_DATA,
  RESET_SEARCHED_EMOTIONS_DATA,
  STORE_UPLOADED_STORY_EDIT_REQUEST,
  CLEAR_UPLOADED_STORY_EDIT_REQUEST
} from './actiontypes'

import ApplicationConsts from '../../ApplicationConstants'
const { BASE_URL, STORIES_BASE_URL, SOCIAL_URL, STORIES_LOCAL_URL } = ApplicationConsts


// ******* USERS ********
// export const fetchUserRequest = () => {
//   return {
//     type: FETCH_USER_REQUEST
//   }
// }
// export const fetchUserSuccess = users => {
//   return {
//     type: FETCH_USER_SUCCESS,
//     data: users
//   }
// }
export const authenticateUserRequest = () => {
  return {
    type: AUTHENTICATE_USER_REQUEST
  }
}
export const authenticateUserSuccess = users => {
  return {
    type: AUTHENTICATE_USER_SUCCESS,
    authenticateUserSuccess: users
  }
}
export const validateUserRequest = () => {
  return {
    type: VALIDATE_USER_REQUEST
  }
}
export const validateUserSuccess = post => {
  return {
    type: VALIDATE_USER_SUCCESS,
    validateUserSuccess: post
  }
}

export const getUserSettingsFromProfileRequest = () => {
  return {
    type: FETCH_USER_SETTINGS_REQUEST
  }
}
export const getUserSettingsFromProfileSuccess = post => {
  return {
    type: FETCH_USER_SETTINGS_SUCCESS,
    getUserSettingsFromProfileSuccess: post
  }
}
export const getProfileOrganizationsListRequest = () => {
  return {
    type: FETCH_PROFILE_ORGANIZATIONS_LIST_REQUEST
  }
}
export const getProfileOrganizationsListSuccess = post => {
  return {
    type: FETCH_PROFILE_ORGANIZATIONS_LIST_SUCCESS,
    getProfileOrganizationsListSuccess: post
  }
}



//  ******* STORIES ********
export const fetchStoriesRequest = () => {
  return {
    type: FETCH_STORIES_REQUEST
  }
}
export const fetchStoriesSuccess = post => {
  return {
    type: FETCH_STORIES_SUCCESS,
    fetchStoriesSuccess: post
  }
}

export const insertStoriesRequest = () => {
  return {
    type: INSERT_STORIES_REQUEST
  }
}
export const insertStoriesSuccess = post => {
  return {
    type: INSERT_STORIES_SUCCESS,
    insertStoriesSuccess: post
  }
}
export const updateStoriesRequest = () => {
  return {
    type: UPDATE_STORIES_REQUEST
  }
}
export const updateStoriesSuccess = post => {
  return {
    type: UPDATE_STORIES_SUCCESS,
    updateStoriesSuccess: post
  }
}
export const deleteStoryRequest = () => {
  return {
    type: DELETE_STORIES_REQUEST,
  }
}
export const deleteStorySuccess = post => {
  return {
    type: DELETE_STORIES_SUCCESS,
    deletedStory: post
  }
}

export const reportStoryRequest = () => {
  return {
    type: REPORT_STORY_REQUEST
  }
}
export const reportStorySuccess = post => {
  return {
    type: REPORT_STORY_SUCCESS,
    reportStorySuccess: post
  }
}
export const myStoriesRequest = () => {
  return {
    type: MY_STORIES_REQUEST
  }
}
export const myStoriesSuccess = post => {
  return {
    type: MY_STORIES_SUCCESS,
    myStoriesSuccess: post
  }
}

export const tapAStoryRequest = () => {
  return {
    type: TAP_STORY_REQUEST
  }
}
export const tapAStorySuccess = post => {
  return {
    type: TAP_STORY_SUCCESS,
    tapAStorySuccess: post
  }
}
export const searchStoriesRequest = () => {
  return {
    type: SEARCH_STORIES_REQUEST
  }
}
export const searchStoriesSuccess = post => {
  return {
    type: SEARCH_STORIES_SUCCESS,
    searchStoriesSuccess: post
  }
}
export const storeStoryObjectRequest = (post) => {
  return {
    type: STORE_STORY_OBJECT_REQUEST,
    storeStoryObjectSuccess: post
  }
}
export const storeStoryObjectSuccess = post => {
  return {
    type: STORE_STORY_OBJECT_SUCCESS,
    storeStoryObjectSuccess: post
  }
}

export const storeVisibilitySettingsStoryObjectDataRequest = (post) => {
  return {
    type: VISIBILITY_SETTINGS_STORY_OBJECT_DATA_REQUEST,
    storeVisibilitySettingsStoryObjectDataSuccess: post
  }
}
export const storeVisibilitySettingsStoryObjectDataSuccess = post => {
  return {
    type: VISIBILITY_SETTINGS_STORY_OBJECT_DATA_SUCCESS,
    storeVisibilitySettingsStoryObjectDataSuccess: post
  }
}

export const resetStoryObjectRequest = () => {
  return {
    type: RESET_STORY_OBJECT_REQUEST,
    resetStoryObjectSuccess: []
  }
}
export const resetStoryObjectSuccess = post => {
  return {
    type: RESET_STORY_OBJECT_SUCCESS,
    resetStoryObjectSuccess: []
  }
}

export const fetchPublicStoryRequest = () => {
  return {
    type: FETCH_PUBLIC_STORY_REQUEST,
  }
}
export const fetchPublicStorySuccess = (post) => {
  return {
    type: FETCH_PUBLIC_STORY_SUCCESS,
    fetchPublicStorySuccess: post
  }
}

export const storeUploadedEditStoryId = (post) => {
  return {
    type: STORE_UPLOADED_STORY_EDIT_REQUEST,
    storeUploadedEditStoryId: post
  }
}
export const clearUploadedEditStoryId = (post) => {
  return {
    type: CLEAR_UPLOADED_STORY_EDIT_REQUEST,
    clearUploadedEditStoryId: post
  }
}
//  ******* COMMENTS ********
export const fetchCommentsRequest = () => {
  return {
    type: FETCH_COMMENTS_REQUEST
  }
}
export const fetchCommentsSuccess = post => {
  return {
    type: FETCH_COMMENTS_SUCCESS,
    fetchCommentsSuccess: post
  }
}

export const insertCommentsRequest = () => {
  return {
    type: INSERT_COMMENTS_REQUEST
  }
}
export const insertCommentsSuccess = post => {
  return {
    type: INSERT_COMMENTS_SUCCESS,
    insertCommentsSuccess: post
  }
}



//   ******* EMOJIS ********
export const fetchEmojisRequest = () => {
  return {
    type: FETCH_EMOJIS_REQUEST
  }
}
export const fetchEmojisSuccess = post => {
  return {
    type: FETCH_EMOJIS_SUCCESS,
    fetchEmojisSuccess: post
  }
}
export const insertEmojisRequest = () => {
  return {
    type: INSERT_EMOJIS_REQUEST
  }
}
export const insertEmojisSuccess = post => {
  return {
    type: INSERT_EMOJIS_SUCCESS,
    insertEmojisSuccess: post
  }
}



//  ******* VIDEOS ********
export const fetchVideosRequest = () => {
  return {
    type: FETCH_VIDEOS_REQUEST
  }
}
export const fetchVideosSuccess = post => {
  return {
    type: FETCH_VIDEOS_SUCCESS,
    fetchVideosSuccess: post
  }
}
export const insertVideosRequest = () => {
  return {
    type: INSERT_VIDEOS_REQUEST
  }
}
export const insertVideosSuccess = post => {
  return {
    type: INSERT_VIDEOS_SUCCESS,
    insertVideosSuccess: post
  }
}
export const resetResponsePropertiesRequest = post => {
  return {
    type: RESET_RESPONSE_PROPERTIES_REQUEST,
    resetResponsePropertiesRequest: post
  }
}
export const storeSearchEmotinsData = post => {
  return {
    type: STORE_SEARCHED_EMOTIONS_DATA,
    storeSearchEmotinsData: post
  }
}
export const resetSearchEmotinsData = post => {
  return {
    type: RESET_SEARCHED_EMOTIONS_DATA,
    storeSearchEmotinsData: post
  }
}

// ******* FAILE CASE ********
export const fetchActionfail = error => {
  return {
    type: FETCH_ACTION_FAIL,
    data: error
  }
}



// ******* USERS ********
// export const fetchUsers = () => {
//   return function (dispatch) {
//     dispatch(fetchUserRequest())
//     axios
//       .get('https://jsonplaceholder.typicode.com/users')
//       .then(response => {
//         let users = response.data
//         dispatch(fetchUserSuccess(users))
//       })
//       .catch(error => {
//         dispatch(fetchActionfail(error))
//       })
//   }
// }

export const authenticateUser = (data, callback) => {
  return async function (dispatch) {
    await dispatch(authenticateUserRequest())
    await axios
      .post(`${BASE_URL}users/authenticate`, data)
      .then(async response => {
        let userData = response.data
        callback(userData)
        await dispatch(authenticateUserSuccess(userData))
      })
      .catch(error => {
        dispatch(fetchActionfail(error))
      })
  }
}

export const validateUser = (data, callback) => {
  return async function (dispatch) {
    await dispatch(validateUserRequest())
    await serviceIntercept
      .post(`${STORIES_BASE_URL}validateStorySession`, data)
      .then(async response => {
        await callback(response.data)
        await dispatch(validateUserSuccess(response.data))
      })
      .catch(error => { dispatch(fetchActionfail(error)) })
  }
}

export const getUserSettingsFromProfile = (data, callback) => {
  return async dispatch => {
    await dispatch(getUserSettingsFromProfileRequest())
    await serviceIntercept
      .get(`${BASE_URL}settings/getUserSettings/${localStorage.userId}`,
        { headers: { Authorization: `Bearer ${localStorage.accessToken}` } })
      .then(async response => {
        await callback(response.data)
        await dispatch(getUserSettingsFromProfileSuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchActionfail(error))
      })
  }
}

export const getProfileOrganizationsList = (data2, callback) => {
  let data = {
    email: localStorage.email,
    userID: localStorage.userId
  }
  return async dispatch => {
    await dispatch(getProfileOrganizationsListRequest())
    await serviceIntercept
      .post(`${BASE_URL}subscriptionUsers/getProfileOrganizations`, data)
      .then(async response => {
        await callback(response.data)
        await dispatch(getProfileOrganizationsListSuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchActionfail(error))
      })
  }
}

// *******  STORIES ********
// ******* stories fetch  ********
export const fetchStories = (data, callback) => {
  const { sLimit, eLimit, status } = data;
  return async function (dispatch) {
    await dispatch(fetchStoriesRequest())
    await
      serviceIntercept
        .get(
          `${STORIES_BASE_URL}stories?sLimit=${sLimit}&eLimit=${eLimit}&universityId=${localStorage.universityId}&storyStatus=${status}&reportUserId=${localStorage.userId}`,
          // { headers: { "userId": localStorage.userId, "universityId": localStorage.universityId, Authorization: 'Bearer ' + localStorage.accessToken, } }
        )
        .then(async response => {
          if (response.data.data.stories.length > 0) {
            await dispatch(fetchStoriesSuccess(response.data))
            await callback(response.data)
          }
          else {
            await callback(response.data)
          }
        })
        .catch(error => {
          dispatch(fetchActionfail(error))
        })
  }
}
// ******* stories insert  ********
export const insertStories = (data, callback) => {
  return async function (dispatch) {
    await dispatch(insertStoriesRequest())
    await serviceIntercept
      .post(`${STORIES_BASE_URL}stories?universityId=${localStorage.universityId}`, data)
      .then(async response => {
        let story = response.data
        await callback(story)
        await dispatch(insertStoriesSuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchActionfail(error))
      })
  }
}
// ******* stories update  ********
export const updateStories = (data, callback) => {
  return async function (dispatch) {
    await dispatch(updateStoriesRequest())
    await serviceIntercept
      .patch(`${STORIES_BASE_URL}stories/${data._id}`, data)
      .then(async response => {
        callback(response.data)
        dispatch(updateStoriesSuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchActionfail(error))
      })
  }
}
// ******* stories delete  ********
export const deleteStory = (storyId, callback) => {
  return async function (dispatch) {
    await dispatch(deleteStoryRequest())
    await serviceIntercept
      .delete(`${STORIES_BASE_URL}stories/${storyId}`)
      .then(async response => {
        callback(response.data)
        dispatch(deleteStorySuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchActionfail(error))
      })
  }
}
// ******* report stories   ********
export const reportStory = (data, callback) => {
  return async function (dispatch) {
    await dispatch(reportStoryRequest())
    await serviceIntercept
      .patch(`${STORIES_BASE_URL}stories/reportStory/${data.reportHistory.storyId}`, data)
      .then(async response => {
        await callback(response.data)
        await dispatch(reportStorySuccess(response.data))
      })
      .catch(async err => {
        await dispatch(fetchActionfail(err))
      })
  }
}

// ******* my stories   ********
export const fetchMyStories = (data, callback) => {
  let { eLimit, sLimit, status } = data
  return async function (dispatch) {
    await dispatch(myStoriesRequest())
    await serviceIntercept
      .get(
        `${STORIES_BASE_URL}stories?universityId=${localStorage.universityId}&sLimit=${sLimit}&eLimit=${eLimit}&userId=${localStorage.userId}&storyStatus=${status}&reportUserId=${localStorage.userId}`)
      .then(async response => {
        let myStories = response.data
        await dispatch(myStoriesSuccess(myStories))
        await callback(myStories)
      })
      .catch(async err => {
        await dispatch(fetchActionfail(err))
      })
  }
}

// ******* tap stories   ********
export const tapingAStoryToSomeone = (data, callback) => {
  return async function (dispatch) {
    await dispatch(tapAStoryRequest())
    await serviceIntercept
      // .post(`${SOCIAL_URL}/feed/tapUser`,`${STORIES_BASE_URL}stories/tapStory`
      .post(`${STORIES_BASE_URL}stories/tapStory`,
        data,
        {
          headers: {
            "Authorization": 'Bearer ' + localStorage.accessToken
          }
        })
      .then(async response => {
        await callback(response.data)
        await dispatch(tapAStorySuccess(response.data))
      })
      .catch(async err => {
        await dispatch(fetchActionfail(err))
      })
  }
}

// ******* search stories   ********
export const searchStories = (data, callback) => {
  let { eLimit, sLimit, feelings, storyStatus } = data
  let accessToken = localStorage.getItem('accessToken')
  return async function (dispatch) {
    await dispatch(searchStoriesRequest())
    await serviceIntercept
      .get(
        `${STORIES_BASE_URL}stories/feelings?feelings=${feelings}&sLimit=${sLimit}&eLimit=${eLimit}&universityId=${localStorage.universityId}&reportUserId=${localStorage.userId}&storyStatus=${storyStatus}`)
      .then(async response => {
        callback(response.data)
        await dispatch(searchStoriesSuccess(response.data))
      })
      .catch(async err => {
        await dispatch(fetchActionfail(err))
      })
  }
}

// ******* public story  ********
export const fetchPublicStory = (data, callback) => {
  let { storyId } = data
  return async function (dispatch) {
    await dispatch(fetchPublicStoryRequest())
    await axios
      .get(
        `${STORIES_BASE_URL}stories/pstory/${storyId}`)
      .then(async response => {
        callback(response.data)
        await dispatch(fetchPublicStorySuccess(response.data))
      })
      .catch(async err => {
        await dispatch(fetchActionfail(err))
      })
  }
}

// ******* store story object data (preentered data in titile,description. etc.. and by default data fileds) create to visibility ********
export const storeDataObjectData = (data) => {
  return async function (dispatch) {
    await dispatch(storeStoryObjectRequest(data))
    await dispatch(storeStoryObjectSuccess(data))
  }
}

// ******* store story object data from visibility settings  (change by default settings in story visibilty settings )  visibility to create ********
export const visibilitySettingsObjectData = (data) => {
  return async function (dispatch) {
    await dispatch(storeVisibilitySettingsStoryObjectDataRequest(data))
    await dispatch(storeVisibilitySettingsStoryObjectDataSuccess(data))
  }
}

// ******* reset Story Total Object   ********
export const resetStoryTotalObject = () => {
  return async function (dispatch) {
    await dispatch(resetStoryObjectRequest())
    await dispatch(resetStoryObjectSuccess())
  }
}

// *******   COMMENTS   ********
// ******* fetch comments   ********
export const fetchComments = (data, callback) => {
  const { sLimit, eLimit, storyId } = data
  return async function (dispatch) {
    await dispatch(fetchCommentsRequest())
    await serviceIntercept
      .get(`${STORIES_BASE_URL}comments?universityId=${localStorage.universityId}&sLimit=${sLimit}&eLimit=${eLimit}&storyId=${storyId}`)
      .then(response => {
        callback(response.data)
        dispatch(fetchCommentsSuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchActionfail(error))
      })
  }
}
// ******* insert comments   ********
export const insertComments = (data, callback) => {
  return async function (dispatch) {
    await dispatch(insertCommentsRequest())
    await serviceIntercept
      .post(`${STORIES_BASE_URL}comments`, data)
      .then(response => {
        callback(response.data)
        dispatch(insertCommentsSuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchActionfail(error))
      })
  }
}

// *******   EMOJIS   ********
// *******  fetch emojis   ********
export const fetchEmojis = (data, callback) => {
  const { sLimit, eLimit, storyId } = data
  return async function (dispatch) {
    await dispatch(fetchEmojisRequest())
    await serviceIntercept
      .get(`${STORIES_BASE_URL}emojis?universityId=${localStorage.universityId}&sLimit=${sLimit}&eLimit=${eLimit}&storyId=${storyId}`)
      .then(response => {
        callback(response.data)
        dispatch(fetchEmojisSuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchActionfail(error))
      })
  }
}

// ******* fetch emojis   ********
export const insertEmojis = (data, callback) => {
  return async function (dispatch) {
    await dispatch(insertEmojisRequest())
    await serviceIntercept
      .post(`${STORIES_BASE_URL}emojis`, data)
      .then(response => {
        callback(response.data)
        dispatch(insertEmojisSuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchActionfail(error))
      })
  }
}

// *******   VIDEOS    ********
// ******* fetch videos   ********
export const fetchVideos = (data, callback) => {
  const { sLimit, eLimit, storyId } = data;
  return async function (dispatch) {
    await dispatch(fetchVideosRequest())
    await serviceIntercept
      .get(`${STORIES_BASE_URL}videos?universityId=${localStorage.universityId}&sLimit=${sLimit}&eLimit=${eLimit}&storyId=${storyId}`)
      .then(response => {
        callback(response.data)
        dispatch(fetchVideosSuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchActionfail(error))
      })
  }
}

// ******* insert videos   ********
export const insertVideos = (data, callback) => {
  return async function (dispatch) {
    await dispatch(insertVideosRequest())
    await serviceIntercept
      .post(`${STORIES_BASE_URL}videos`, data)
      .then(response => {
        callback(response.data)
        dispatch(insertVideosSuccess(response.data))
      })
      .catch(error => {
        dispatch(fetchActionfail(error))
      })
  }
}
// ******* store story object data   ********
export const resetResponseProperties = (data) => {
  return async function (dispatch) {
    await dispatch(resetResponsePropertiesRequest(data))
    // await dispatch(resetResponsePropertiesSuccess(data))
  }
}
export const setSearchEmotionsDataInReduxStore = (data) => {
  return async function (dispatch) {
    await dispatch(storeSearchEmotinsData(data))
    // await dispatch(resetResponsePropertiesSuccess(data))
  }
}

export const resetSearchEmotionsDataInReduxStore = (data) => {
  return async function (dispatch) {
    await dispatch(resetSearchEmotinsData(data))
    // await dispatch(resetResponsePropertiesSuccess(data))
  }
}

export const setUploadedEditStoryInReduxStore = (data) => {
  return async function (dispatch) {
    await dispatch(storeUploadedEditStoryId(data))
  }
}

export const clearUploadedEditStoryInReduxStore = (data) => {
  return async function (dispatch) {
    await dispatch(clearUploadedEditStoryId(data))
  }
}