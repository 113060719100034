import React from 'react'
import socketClient from 'socket.io-client'
// const Sockette = require('sockette');
// import Sockette from 'sockette'
// import { socket, SocketContext } from './context/socket'
import { useRef, useCallback } from 'react'
import { useContext, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  fetchComments,
  insertComments
} from '../../redux/actions/storiesAction'
import { timeCalculation } from '../utils/timeUtils'
import AppConstants from '../../ApplicationConstants'

const Storycomments = ({ dropDownStatus, setDropDownStatus, ...props }) => {
  const [postComment, setPostComment] = useState('')
  const [comments, setComments] = useState([])
  const [errors, setErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [load, setLoad] = useState(false)
  const [showNoData, setShowNoData] = useState(false)
  // const [dropDownStatus, setShowReactionsScreen] = useState(false)

  // const socket = useContext(SocketContext);
  const storyId = props.story._id
  const story = props.story
  const dispatch = useDispatch()

  //  ********************************************
  //          Accessing comments
  //  ********************************************
  const getData = async storyId => {
    setDropDownStatus(true)
    // return
    let data = {
      storyId: storyId,
      sLimit: 0,
      eLimit: 100
    }
    dispatch(
      fetchComments(data, res => {
        setShowNoData(false)
        if (res.data.comments.length > 0) {
          setShowNoData(false)
          setShowNoData(false)
          getCommentsData(res.data.comments)
        } else {
          let latestComments = []
          getCommentsData(latestComments)
          // setIsLoading(false)
        }
      })
    )
    setShowNoData(false)
  }
  const getCommentsData = async latestComments => {
    setComments([])
    if (story && story.allowComments) {
      setShowNoData(false)
    }
    if (latestComments && latestComments.length == 0) {
      setIsLoading(false)
      setComments([])
      setShowNoData(true)
    } else {
      setShowNoData(false)
      timeCalculation(latestComments && latestComments).then(res => {
        setComments(res)
        setIsLoading(false)
        setShowNoData(false)
      })
    }
    // setIsLoading(false)
    // setComments([])
  }

  const getstoryComments = storyId => {
    setIsLoading(true)
    setDropDownStatus(!dropDownStatus)
    setComments([])
    getData(storyId)
    setShowNoData(false)
  }
  // useEffect(() => {
  //   setShowNoData(false)
  //   setIsLoading(true)
  //   getData(storyId)
  //   configureSocket();
  // }, [storyId])

  // Sockette("wss://iyd7fnycec.execute-api.ap-south-1.amazonaws.com/dev", {
  //   // timeout: 5e3,
  //   maxAttempts: 10,
  //   onOpen: e => console.log('Connected!', e),
  //   options: {
  //     headers: {
  //       userId: localStorage.userId,
  //       universityId: localStorage.universityId,
  //       authorization: "bearer " + localStorage.accessToken
  //     }
  //   },
  //   onmessage: e => console.log('Received:', e),
  //   onreconnect: e => console.log('Reconnecting...', e),
  //   onmaximum: e => console.log('Stop Attempting!', e),
  //   onclose: e => console.log('Closed!', e),
  //   onerror: e => console.log('Error:', e),

  // },
  // );
  const configureSocket = () => {
    // var socket = socketClient("wss://iyd7fnycec.execute-api.ap-south-1.amazonaws.com/dev", {
    //   Headers: {
    //     Authorization: `Bearer ${localStorage.accessToken}`,
    //     universityId: `${localStorage.universityId}`,
    //     userId: `${localStorage.userId}`
    //   },
    //   withCredentials: true,
    //   transports: ["websocket", "polling"] // use WebSocket first, if available
    //   // cors: {
    //   //   origin: "*",
    //   //   methods: ["GET", "POST"]
    //   // }
    // });
    // console.log(socket, 'socket');
    // socket.on('connect', () => {
    //   console.log('connection established');
    // });
    // socket.on('commentcreated', message => {
    //   console.log({ message });
    // });
  }

  //  ********************************************
  //           Posting comment
  //  ********************************************
  const handleChange = async e => {
    setErrors(false)
    setPostComment(e.target.value)
  }
  const handleSubmit = async e => {
    e.preventDefault()
    if (!postComment.trim()) {
      setErrors(true)
      return
    } else {
      setTimeout(() => {
        setLoad(true)
      }, 1)
      let data = {
        userId: localStorage.userId,
        storyId: storyId,
        universityId: localStorage.universityId,
        content: postComment
      }
      console.log(data, 'data')
      dispatch(
        insertComments(data, (res, error) => {
          if (res.status == 'success') getData(storyId)
          else return error
          setPostComment('')
          setLoad(false)
        })
      )
    }
  }

  console.log(dropDownStatus, 'dropDownStatus')
  return (
    <div>
      {dropDownStatus ? (
        <div className='message-comment '>
          {story && !story.responseTypes.includes('comments') ? (
            ''
          ) : (
            <form>
              <div className='d-flex justify-content-between p-3 pb-0'>
                <div className='message-comment-search'>
                  <input
                    className={
                      errors ? 'form-control errorBorder' : 'form-control'
                    }
                    autoComplete='off'
                    type='text'
                    placeholder={
                      errors ? '**Please write something here' : 'Add Comment'
                    }
                    name='postComment'
                    value={postComment}
                    onChange={handleChange}
                    aria-label='text'
                  />
                </div>
                {load ? (
                  <div className='send loading' />
                ) : (
                  <div className='message-comment-icon' onClick={handleSubmit}>
                    <button type='submit' className='border-0 bg-transparent'>
                      <i className='bi bi-send-fill'></i>
                    </button>
                  </div>
                )}
              </div>
            </form>
          )}

          <div className='px-3'>
            {story &&
            story.allowComments == 'private' &&
            story.userId !== localStorage.userId ? (
              <h6 className=''> Comments</h6>
            ) : (
              <h6 className='mb-0'>{`${
                comments && comments ? comments.length : ''
              }  Comments`}</h6>
            )}
            {story &&
            story.allowComments == 'private' &&
            story.userId !== localStorage.userId ? (
              <div>
                <p className='noResponseMessage'>
                  Only Author can see Reactions.
                </p>
              </div>
            ) : isLoading ? (
              <div className='Storycomment loading' />
            ) : showNoData ? (
              <div>
                <p className='noResponseMessage'>There is no responses.</p>
              </div>
            ) : (
              <div className='chat-comment-cont'>
                {comments &&
                  comments.map((e, index) => {
                    return (
                      <div>
                        <div className='chat-comments'>
                          <div className='d-flex'>
                            <div className='chat-img'>
                              <a
                                href={
                                  AppConstants.BASE_DEV_URL +
                                  `/?` +
                                  localStorage.userId +
                                  `&` +
                                  localStorage.accessToken +
                                  `&` +
                                  localStorage.isAdmin +
                                  `&` +
                                  localStorage.universityId +
                                  `&` +
                                  localStorage.isUpsquadStatus +
                                  `&` +
                                  localStorage.refrshToken
                                }
                              >
                                <img
                                  src={
                                    (e.userProperties[0] &&
                                      e.userProperties[0].profileImg ==
                                        undefined) ||
                                    (e.userProperties[0] &&
                                      e.userProperties[0].profileImg == '')
                                      ? 'https://www.careerquo.com/assets/images/18.png'
                                      : e.userProperties[0] &&
                                        e.userProperties[0].profileImg
                                  }
                                  className='rounded-circle'
                                  width='100%'
                                  height='100%'
                                  alt=''
                                  key={index}
                                />
                              </a>
                            </div>
                            <div className='chat-cont d-flex align-items-center'>
                              <h6 className='mb-0'>
                                <a
                                  href={
                                    AppConstants.BASE_DEV_URL +
                                    `/?` +
                                    localStorage.userId +
                                    `&` +
                                    localStorage.accessToken +
                                    `&` +
                                    localStorage.isAdmin +
                                    `&` +
                                    localStorage.universityId +
                                    `&` +
                                    localStorage.isUpsquadStatus +
                                    `&` +
                                    localStorage.refrshToken
                                  }
                                >
                                  {e.userProperties[0] &&
                                  e.userProperties[0].firstName == ''
                                    ? 'xxx'
                                    : e.userProperties[0] &&
                                      e.userProperties[0].firstName}{' '}
                                  {e.userProperties[0] &&
                                  e.userProperties[0].lastName == ''
                                    ? 'yyy'
                                    : e.userProperties[0] &&
                                      e.userProperties[0].lastName}
                                </a>
                              </h6>
                              <p className='mb-0 ps-3'>{e.commentedOn}</p>
                            </div>
                          </div>
                          <p className='mb-0'>{e.content}</p>
                          <hr />
                        </div>
                      </div>
                    )
                  })}
              </div>
            )}
          </div>
        </div>
      ) : (
        ''
      )}

      {(story &&
        story.responseTypes.includes('comments') &&
        story &&
        story.storyStatus == 'approved') ||
      (story &&
        story.responseTypes.includes('comments') &&
        story &&
        story.storyStatus == 'approved') ? (
        <div className=''>
          <button
            type='button'
            className='border-0 bg-transparent trianglePostion'
            onClick={() => getstoryComments(storyId)}
          >
            <span>
              <i className='bi bi-chat-left-text commentRes'></i>
            </span>
            {dropDownStatus ? (
              <div className='triangle-arrow '>
                <i className='bi bi-triangle-fill'></i>
              </div>
            ) : (
              ''
            )}
          </button>
        </div>
      ) : (
        <span className='disableReactionsAction'>
          <i className='bi bi-chat-left-text commentRes'></i>
        </span>
      )}
    </div>
  )
}
const mapStateToProps = state => {
  return {
    // comments: state.users.comments
  }
}
const mapDispatchToProps = dispatch => {
  return {
    // fetchComments: () => dispatch(fetchComments())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Storycomments)
