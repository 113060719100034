import axios from 'axios'
import ApplicationConsts from "../ApplicationConstants";
import { userStoryModuleValidation } from '../components/utils/storyVlidateUtil'

// LocalStorageServices
const userId = localStorage.getItem('userId')
const universityId = localStorage.getItem('universityId')
const accessToken = localStorage.getItem('accessToken')
const axiosClient = axios.create()
const isAuth = true

//Add a request interceptor
axiosClient.interceptors.request.use(
    (config) => {
        if (localStorage.accessToken && localStorage.userId && localStorage.universityId) {
            config.headers.Authorization = `Bearer ${localStorage.accessToken}`;
            config.headers.universityId = `${localStorage.universityId}`
            config.headers.userId = `${localStorage.userId}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

//Add a response interceptor
axiosClient.interceptors.response.use(
    (response) => {
        return response
    },
    function (error) {
        localStorage.removeItem("accessToken");
        const originalRequest = error.config;
        if (error.message = "Request failed with status code 401" ||
            error.message == "userId, universityId, token not matched." ||
            error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            // console.log({ error });
            return axios.post(`${ApplicationConsts.BASE_URL}users/refresh`, {
                "refreshToken": localStorage.refreshToken,
                "apiKey": localStorage.apiKey
            }).then(async (response) => {
                localStorage.setItem('accessToken', response.data.idToken);
                let data = await userStoryModuleValidation(response.data.idToken, response.data.expiresIn)
                if (response.status == 200 && data == 200) {
                    error.config.headers['userId'] = `${localStorage.userId}`
                    error.config.headers['universityId'] = `${localStorage.universityId}`
                    error.config.headers['Authorization'] = `Bearer ${response.data.idToken}`;
                    return axios(originalRequest)
                }
                else if (error && error.response && error.response.status < 500) {
                    // return 
                    // localStorage.removeItem("userId");
                    // localStorage.removeItem("universityId");
                    // localStorage.removeItem("fName");
                    // localStorage.removeItem("lastName");
                    // localStorage.removeItem("emojisMaster");
                    // localStorage.removeItem("email");
                    // localStorage.removeItem("refreshToken");
                    // localStorage.removeItem("apiKey");
                    // window.location.href = "/";
                }
            })
                .catch(error => {
                    return Promise.reject(error)
                })
        }
        return Promise.reject(error)
    }
)

export const ProtectRoutes = () => {
    return isAuth
}

export default axiosClient;