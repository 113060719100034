import React from 'react';
import ReactPlayer from 'react-player'
import AppConstants from '../../ApplicationConstants'

function PlayingFrameFour({ playStoriesArr, swapObj, moveToProfileDetails }) {

    const playRandomSelectedStoryByUser = (obj, id) => {
        swapObj(obj, id)
    }
    const moveToSpecificProfileDetails = (id) => {
        moveToProfileDetails(id)
    }
    return (
        <div>
            <div className='gallery-item gallery-item-4' data-index='4'>
                <div className='card border-0 text-white' >
                    <div id='video-viewport'>
                        {
                            playStoriesArr[3] && playStoriesArr[3].isAnonymous ?
                                <div className='front-img'><img src='../../assets/images/Anonymous2.jpg' width="100%" height="100%" /></div> :
                                ""
                        }
                        <ReactPlayer
                            url={playStoriesArr[3] && playStoriesArr[3].vid}
                            width='100%'
                            height='100%'
                        />
                    </div>
                    <div className='card-img-overlay1'>
                        <div className='selectedVideo' onClick={() => playRandomSelectedStoryByUser(playStoriesArr[3], 3)}></div>
                        <div className='card-cont'>
                            <h5 className='card-title'>
                                {playStoriesArr[3] && playStoriesArr[3].title}
                            </h5>
                            <p className='card-text'>
                                {playStoriesArr[3] && playStoriesArr[3].description}
                            </p>
                            <p className='card-text mb-0'>
                                {
                                    playStoriesArr[3] && playStoriesArr[3].isAnonymous ?
                                        <>
                                            <div className='galleryProfile'>
                                                <a>
                                                    <img
                                                        src={
                                                            playStoriesArr[3] && playStoriesArr[3].isAnonymous || playStoriesArr[3] && playStoriesArr[3].userProperties[0].profileImg == null ?
                                                                "https://www.careerquo.com/assets/images/18.png" :
                                                                playStoriesArr[3] &&
                                                                playStoriesArr[3].userProperties[0].profileImg
                                                        }
                                                        width='100%'
                                                        height='100%'
                                                        alt=''
                                                    />
                                                </a>
                                            </div>
                                            <a >
                                                <strong>
                                                    {' '}
                                                    {playStoriesArr[3] && playStoriesArr[3].isAnonymous
                                                        ? "Anonymous" :
                                                        `${playStoriesArr[3] &&
                                                        playStoriesArr[3].userProperties[0]
                                                            .firstName} ${playStoriesArr[3] &&
                                                            playStoriesArr[3].userProperties[0].lastName}`}
                                                </strong>
                                            </a>
                                        </>
                                        :
                                        <>
                                            <div className='galleryProfile'>
                                                <a onClick={() => moveToSpecificProfileDetails(playStoriesArr[3].userId)}>
                                                    <img
                                                        src={
                                                            playStoriesArr[3] && playStoriesArr[3].isAnonymous || playStoriesArr[3] && playStoriesArr[3].userProperties[0].profileImg == null ?
                                                                "https://www.careerquo.com/assets/images/18.png" :
                                                                playStoriesArr[3] &&
                                                                playStoriesArr[3].userProperties[0].profileImg
                                                        }
                                                        width='100%'
                                                        height='100%'
                                                        alt=''
                                                    />
                                                </a>
                                            </div>
                                            <a onClick={() => moveToSpecificProfileDetails(playStoriesArr[3].userId)}>
                                                <strong>
                                                    {' '}
                                                    {playStoriesArr[3] && playStoriesArr[3].isAnonymous
                                                        ? "Anonymous" :
                                                        `${playStoriesArr[3] &&
                                                        playStoriesArr[3].userProperties[0]
                                                            .firstName} ${playStoriesArr[3] &&
                                                            playStoriesArr[3].userProperties[0].lastName}`}
                                                </strong>
                                            </a>
                                        </>
                                }
                            </p>
                            {/* {playStoriesArr?.[3] == undefined ? "" :
                                          <div className='telle-me-tap'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                              <div className='d-flex justify-content-between comment-tell-tap'>
                                                {playStoriesArr[3] && playStoriesArr[3].isAnonymous || localStorage.userId == playStoriesArr[3].userId ?
                                                  "" :
                                                  <button
                                                    type='button'
                                                    className='btn btn-primary tellMe'
                                                  >
                                                    <div className='chat-icon-svg'>
                                                      <img
                                                        src='../../assets/images/chat-icon.svg'
                                                        alt=''
                                                        width='90%'
                                                        height='90%'
                                                      /></div> Tell me more
                                                  </button>
                                                }
                                                {localStorage.userId == playStoriesArr[3].userId ? "" : <div className='requestaStoryLink'><a>Request A Story</a></div>}
                                              </div>
                                              <span className=''>
                                                <i className='bi bi-fullscreen fullscreen-icon'></i>
                                              </span>
                                            </div>
                                          </div>} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlayingFrameFour;