import React, { forwardRef, useImperativeHandle } from 'react';
import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import HelmetMetaData from '../utils/metaUtilit';
import AppConstants from '../../ApplicationConstants'

import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton
} from "react-share";
import InstagramEmbed from 'react-instagram-embed';
import staticContent from '../helpers/staticContent';

const Sharestory = forwardRef(({ dropDownStatus, setDropDownStatus, ref, ...props }) => {
    const story = props.story

    const [copySuccess, setCopySuccess] = useState('');
    const [copyStatus, setCopyStatus] = useState(false)

    useImperativeHandle(ref, () => ({
        pauseVolume1: (val) => {
            setCopyStatus(false)
        }
    }));
    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess('Copied link');
            setCopyStatus(true)
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    };
    const openSharingScreenModel = () => [
        setDropDownStatus(!dropDownStatus)
    ]
    return (
        <div>
            {
                dropDownStatus ?
                    <div className="p-3 share-comment">

                        <HelmetMetaData></HelmetMetaData>
                        <p className="mb-2 mb-0 fw-bold">{staticContent.share_title_ctn}</p>
                        <div className="d-flex flex-wrap social-link">
                            <div>
                                <div className="share-link" onClick={() => copyToClipBoard(`${AppConstants.BLOOMING_DEV_URL}/cGxheVB1YmxpY1N0b3JpZXM/:${story._id}`)}>
                                    <img src="../../assets/images/share-icons/copy.png" alt="" width="100%" height="100%" />
                                </div>
                                {copyStatus ?
                                    <p className="mt-2 mb-0">{copySuccess}</p> :
                                    <p className="mt-2 mb-0">{staticContent.share_copy_cnt}</p>
                                }

                            </div>
                            <div>
                                <div className="share-link">
                                    <FacebookShareButton
                                        windowWidth="1200%"
                                        windowHeight="1000%"
                                        url={`${AppConstants.BLOOMING_DEV_URL}/cGxheVB1YmxpY1N0b3JpZXM/:${story._id}`}
                                    // quote={story.title}
                                    // hashtag="#sdgffdg"
                                    // className={classes.socialMediaButton}
                                    >
                                        <img src="../../assets/images/share-icons/facebook.png" alt="" width="100%" height="100%" />
                                    </FacebookShareButton>
                                </div>
                                <p className="mt-2 mb-0">{staticContent.share_fb_cnt}</p>
                            </div>
                            <div>
                                <div className="share-link">
                                    <TwitterShareButton
                                        windowWidth="1200%"
                                        windowHeight="1000%"
                                        url={`${AppConstants.BLOOMING_DEV_URL}/cGxheVB1YmxpY1N0b3JpZXM/:${story._id}`}
                                    // title={story.title}
                                    // className="social-icons"
                                    >
                                        <img src="../../assets/images/share-icons/twitter.png" alt="" width="100%" height="100%" />
                                    </TwitterShareButton>
                                </div>
                                <p className="mt-2 mb-0">{staticContent.share_twtr_cnt}</p>
                            </div>
                            <div>
                                <div className="share-link">
                                    <LinkedinShareButton
                                        url={`${AppConstants.BLOOMING_DEV_URL}/cGxheVB1YmxpY1N0b3JpZXM/:${story._id}`}
                                        windowWidth="1200%"
                                        windowHeight="1000%"
                                    >
                                        <img src="../../assets/images/share-icons/linkedin.png" alt="" width="100%" height="100%" />
                                    </LinkedinShareButton>
                                </div>
                                <p className="mt-2 mb-0">{staticContent.share_lnkdn_cnt}</p>
                            </div>
                            <div>
                                <div className="share-link">
                                    <WhatsappShareButton
                                        windowWidth="1200%"
                                        windowHeight="1000%"
                                        url={`${AppConstants.BLOOMING_DEV_URL}/cGxheVB1YmxpY1N0b3JpZXM/:${story._id}`}
                                    // title={story.description}
                                    >
                                        <img src="../../assets/images/share-icons/whatsapp.png" alt="" width="100%" height="100%" />
                                    </WhatsappShareButton>
                                </div>
                                <p className="mt-2 mb-0">{staticContent.share_wtsapp_cnt}</p>
                            </div>
                        </div>
                    </div> : ""
            }



            {
                (story && story.allowSharing && ["approved", "aws_pending"].includes(story && story.storyStatus)) ?
                    <div className=''>
                        <button
                            type='button'
                            className='border-0 bg-transparent trianglePostion'
                            onClick={() => openSharingScreenModel()}
                        >
                            <span>
                                <i className='bi bi-share shareRes'></i>
                            </span>
                            {
                                dropDownStatus ?
                                    <div className="triangle-arrow " >
                                        <i className="bi bi-triangle-fill"></i>
                                    </div>
                                    : ""
                            }

                        </button>
                        <div className=''>
                        </div>
                    </div>
                    :
                    <span className='disableReactionsAction'>
                        <i className='bi bi-share shareRes'></i>
                    </span>
            }
        </div>
    )
})
export default Sharestory;