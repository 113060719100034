const ApplicationConsts = {

    //Dev Urls
    BASE_URL: `https://devapi.upsquad.com/`,
    STORIES_BASE_URL: `https://xt9zovnkl8.execute-api.ap-south-1.amazonaws.com/dev/api/v1/`,
    STORIES_LOCAL_URL: `http://localhost:3002/api/v1/`,
    userApi: 'https://devapi.upsquad.com/users/', //for users data
    // STORIES_BASE_URL: `https://storiesdev.upsquad.com/api/v1/`,
    // https://d-0gifyfd201.execute-api.ap-south-1.amazonaws.com/dev/api/v1/
    SOCIAL_DEV_URL: `https://socialdev.upsquad.com`,
    MESSAGE_DEV_URL: 'https://messagesdev.upsquad.com',
    BASE_DEV_URL: 'https://dev.upsquad.com',
    FILES_DEV_URL: "https://dmsdev.upsquad.com",
    BLOOMING_DEV_URL: "https://storiesdev.upsquad.com",
    API_KEY: "AIzaSyCmYl_8x4r9kRkwDJBa_7kSMMONzkuV7dE",
    SOCIAL_URL: 'https://socialapidev.upsquad.com',
    EMOTIONS_BROWSE_URL:'https://simple.m.wikipedia.org/wiki/List_of_emotions',



    //UAT Urls

    // BASE_URL: `https://uatapi.upsquad.com/`,
    // STORIES_BASE_URL: `https://nzj5v3h7u9.execute-api.us-east-1.amazonaws.com/uat/api/v1/`,
    // STORIES_LOCAL_URL: `http://localhost:3002/api/v1/`,
    // userApi: 'https://uatapi.upsquad.com/users/', //for users data
    // SOCIAL_DEV_URL: `https://socialuat.upsquad.com`,
    // MESSAGE_DEV_URL: 'https://messagesuat.upsquad.com',
    // BASE_DEV_URL: 'https://uat.upsquad.com',
    // FILES_DEV_URL: "https://dmsuat.upsquad.com",
    // BLOOMING_DEV_URL: "https://storiesuat.upsquad.com",
    // API_KEY: "AIzaSyCmYl_8x4r9kRkwDJBa_7kSMMONzkuV7dE",
    // SOCIAL_URL: 'https://socialapiuat.upsquad.com',
    // EMOTIONS_BROWSE_URL:'https://simple.m.wikipedia.org/wiki/List_of_emotions',

    //Prod Urls

    // BASE_URL: `https://api.upsquad.com/`,
    // STORIES_BASE_URL: `https://xt9zovnkl8.execute-api.ap-south-1.amazonaws.com/dev/api/v1/`,
    // STORIES_LOCAL_URL: `http://localhost:3002/api/v1/`,
    // userApi: 'https://api.upsquad.com/users/', //for users data
    // SOCIAL_DEV_URL: `https://social.upsquad.com`,
    // MESSAGE_DEV_URL: 'https://messages.upsquad.com',
    // BASE_DEV_URL: 'https://upsquad.com',
    // FILES_DEV_URL: "https://dms.upsquad.com",
    // BLOOMING_DEV_URL: "https://stories.upsquad.com",
    // API_KEY: "AIzaSyCmYl_8x4r9kRkwDJBa_7kSMMONzkuV7dE",
    // SOCIAL_URL: 'https://socialapiuat.upsquad.com',
    // EMOTIONS_BROWSE_URL:'https://simple.m.wikipedia.org/wiki/List_of_emotions',


}
export default ApplicationConsts;