import React from 'react';
import ReactPlayer from 'react-player'
import AppConstants from '../../ApplicationConstants'

function PlayingFrameFive({ playStoriesArr, swapObj, moveToProfileDetails }) {

    const playRandomSelectedStoryByUser = (obj, id) => {
        swapObj(obj, id)
    }
    const moveToSpecificProfileDetails = (id) => {
        moveToProfileDetails(id)
    }
    return (
        <div>
            <div className='gallery-item gallery-item-5' data-index='5'>
                <div className='card border-0 text-white' >
                    <div id='video-viewport'>
                        {
                            playStoriesArr[4] && playStoriesArr[4].isAnonymous ?
                                <div className='front-img'><img src='../../assets/images/Anonymous1.jpg'></img></div> :
                                ""
                        }
                        <ReactPlayer
                            url={
                                playStoriesArr[4] &&
                                playStoriesArr[4].vid}
                            width='100%'
                            height='100%'
                        // className={animateStory == null ? "" : animateStory ? 'video animate__animated animate__fadeInLeft' : 'video animate__animated animate__fadeInRight'}
                        />
                    </div>
                    <div className='card-img-overlay1'>
                        <div className='selectedVideo' onClick={() => playRandomSelectedStoryByUser(playStoriesArr[4], 4)}></div>
                        <div className='card-cont'>
                            <h5 className='card-title'>
                                {playStoriesArr[4] && playStoriesArr[4].title}
                            </h5>
                            <p className='card-text'>
                                {playStoriesArr[4] && playStoriesArr[4].description}
                            </p>
                            <p className='card-text mb-0'>
                                {
                                    playStoriesArr[4] && playStoriesArr[4].isAnonymous  ?
                                        <>
                                            <div className='galleryProfile'>
                                                <a>
                                                    <img
                                                        src={
                                                            playStoriesArr[4] && playStoriesArr[4].isAnonymous || playStoriesArr[4] && playStoriesArr[4].userProperties[0].profileImg == null ?
                                                                "https://www.careerquo.com/assets/images/18.png" :
                                                                playStoriesArr[4] &&
                                                                playStoriesArr[4].userProperties[0].profileImg
                                                        }
                                                        width='100%'
                                                        height='100%'
                                                        alt=''
                                                    />
                                                </a>
                                            </div>
                                            <a >
                                                <strong>
                                                    {' '}
                                                    {playStoriesArr[4] && playStoriesArr[4].isAnonymous
                                                        ? "Anonymous" :
                                                        `${playStoriesArr[4] &&
                                                        playStoriesArr[4].userProperties[0]
                                                            .firstName} ${playStoriesArr[4] &&
                                                            playStoriesArr[4].userProperties[0].lastName}`}
                                                </strong>
                                            </a>
                                        </> :
                                        <>
                                            <div className='galleryProfile'>
                                                <a onClick={() => moveToSpecificProfileDetails(playStoriesArr[4].userId)}>
                                                    <img
                                                        src={
                                                            playStoriesArr[4] && playStoriesArr[4].isAnonymous || playStoriesArr[4] && playStoriesArr[4].userProperties[0].profileImg == null ?
                                                                "https://www.careerquo.com/assets/images/18.png" :
                                                                playStoriesArr[4] &&
                                                                playStoriesArr[4].userProperties[0].profileImg
                                                        }
                                                        width='100%'
                                                        height='100%'
                                                        alt=''
                                                    />
                                                </a>
                                            </div>
                                            <a onClick={() => moveToSpecificProfileDetails(playStoriesArr[4].userId)}>
                                                <strong>
                                                    {' '}
                                                    {playStoriesArr[4] && playStoriesArr[4].isAnonymous
                                                        ? "Anonymous" :
                                                        `${playStoriesArr[4] &&
                                                        playStoriesArr[4].userProperties[0]
                                                            .firstName} ${playStoriesArr[4] &&
                                                            playStoriesArr[4].userProperties[0].lastName}`}
                                                </strong>
                                            </a>
                                        </>
                                }
                            </p>
                            {/* <div className='telle-me-tap'>
                                          <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between comment-tell-tap'>
                                              {playStoriesArr[4] && playStoriesArr[4].isAnonymous || localStorage.userId == playStoriesArr[4].userId ?
                                                "" : <button
                                                  type='button'
                                                  className='btn btn-primary tellMe'
                                                >
                                                  <div className='chat-icon-svg'>
                                                    <img
                                                      src='../../assets/images/chat-icon.svg'
                                                      alt=''
                                                      width='90%'
                                                      height='90%'
                                                    /></div> Tell me more
                                                </button>
                                              }
                                              {localStorage.userId == playStoriesArr[4].userId ? "" : <div className='requestaStoryLink'><a>Request A Story</a></div>}
                                            </div>
                                            <span className=''>
                                              <i className='bi bi-fullscreen fullscreen-icon'></i>
                                            </span>
                                          </div>
                                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlayingFrameFive;