import axios from 'axios'
import AppConstants from '../../ApplicationConstants'
export async function userStoryModuleValidation(userId, expiresIn) {
    let id = localStorage.userId ? localStorage.userId : userId
    return await axios.get(`${AppConstants.userApi}getUserId/${id}`).then(
        async res => {
            const userData = res.data.data[0]
            localStorage.setItem('isProfessional',res.data.data[0].isProfessional)
            const data = {
                "apiKey": "AIzaSyCmYl_8x4r9kRkwDJBa_7kSMMONzkuV7dE",
                "refreshToken": localStorage.refreshToken,
                "userId": localStorage.userId,
                "universityId": localStorage.universityId,
                "expirationTime": expiresIn ? expiresIn : 604800000,
                "user": {
                    "diversity": userData ? userData.diversity : {
                        "race": []
                    },
                    "gender": userData ? userData.gender : "",
                    "about": userData ? userData.about : "",
                    "dateOfBirth": userData ? userData.dateOfBirth : "",
                    "isBackgroundChecked": userData ? userData.isBackgroundChecked : true,
                    "isStudentFavorite": userData ? userData.isStudentFavorite : true,
                    "missionStatement": userData ? userData.missionStatement : "",
                    "mediaTitle": userData ? userData.mediaTitle : "",
                    "isDonation": userData ? userData.isDonation : true,
                    "nonProfitName": userData ? userData.nonProfitName : "",
                    "nonProfitEin": userData ? userData.nonProfitEin : "",
                    "nonProfitAddress": userData ? userData.nonProfitAddress : "",
                    "donationApproved": userData ? userData.donationApproved : true,
                    "isContactable": userData ? userData.isContactable : true,
                    "customerId": userData ? userData.customerId : "",
                    "skillset": userData ? userData.skillset : "",
                    "currentRole": userData ? userData.currentRole : "",
                    "homeCountry": userData ? userData.homeCountry : "",
                    "currentCountry": userData ? userData.currentCountry : "",
                    "sessionType": userData ? userData.sessionType : [],
                    "currentJobTitle": userData ? userData.currentJobTitle : "",
                    "currentCompany": userData ? userData.currentCompany : "",
                    "currentMajor": userData ? userData.currentMajor : "",
                    "graduationYear": userData ? userData.graduationYear : "",
                    "minor": userData ? userData.minor : "",
                    "fieldOfstudyOrSpecialty": userData ? userData.fieldOfstudyOrSpecialty : "",
                    "currentUniversity": userData ? userData.currentUniversity : "",
                    "isSearchable": userData ? userData.isSearchable : false,
                    "profileImg": userData ? userData.profileImg : "https://www.careerquo.com/assets/images/18.png",
                    "resume": userData ? userData.userDataresume : "",
                    "paypalAccount": userData ? userData.paypalAccount : "",
                    "blocked": userData ? userData.blocked : false,
                    "homeAddressStreet": userData ? userData.homeAddressStreet : "",
                    "homeAddressCity": userData ? userData.homeAddressCity : "",
                    "homeAddressState": userData ? userData.homeAddressState : "",
                    "homeAddressZipCode": userData ? userData.homeAddressZipCode : "",
                    "timezone": userData ? userData.timezone : "",
                    "alumniDonationEnabled": userData ? userData.alumniDonationEnabled : true,
                    "isAdmin": userData ? userData.isAdmin : true,
                    "userId": userData ? localStorage.userId : localStorage.userId,
                    "universityId": userData ? localStorage.universityId : localStorage.universityId,
                    "firstName": userData ? userData.firstName : "Super Admin",
                    "lastName": userData ? userData.lastName : "super Admin",
                    "email": userData ? userData.email : "superAdmin@gmail.com",
                    "isProfessional": userData ? userData.isProfessional : true,
                    "visibleToOthers": userData ? userData.visibleToOthers : false,
                    "education": userData ? userData.education : [],
                    "experience": userData ? userData.experience : [],
                    "mediaLinks": userData ? userData.mediaLinks : [],
                    "certification": userData ? userData.certification : [],
                    "publications": userData ? userData.publications : [],
                    "research": userData ? userData.research : [],
                    "userDevices": [{
                        "deviceToken": "TF7mskS-SA1F4WJ0M61m:APA91bEYwSYdr-WFENpdryzYXZiolRNCmJivFc2Wzby5YozXAxDxHtU3114YMox1-rhjAMICfbJyg6qC7mBEiKIeRQvaZTeOZIUi51GGS9pt0ZpWMBGlb3QNoNqMPg0eD2yB11",
                        "deviceType": "web",
                        "deviceModel": "web browser"
                    }]
                }
            }
            return await axios.post(`${AppConstants.STORIES_BASE_URL}validateStorySession`, data,
                { headers: { "userId": localStorage.userId, "universityId": localStorage.universityId, Authorization: 'Bearer ' + localStorage.accessToken } }
            ).then(res => {
                localStorage.setItem('emojisMaster', JSON.stringify(res.data.data.emojisMaster && res.data.data.emojisMaster))
                return res.status
            })
                .catch(error => console.log(error))
        }
    )
        .catch(error => console.log(error))

}