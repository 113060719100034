import React, { forwardRef, useImperativeHandle } from 'react'
import $, { get } from 'jquery'
import { useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  fetchVideos,
  insertVideos,
  resetResponseProperties
} from '../../redux/actions/storiesAction'
import ReactPlayer from 'react-player/lazy'
import { timeCalculation } from '../utils/timeUtils'
import { AwsS3Uploadfunctionality } from '../utils/awsUtils'
import { seeProfileInformation } from '../utils/userProfile'
import swal from 'sweetalert'
import socketClient from 'socket.io-client'
import AppConstants from '../../ApplicationConstants'
import MSGContent from '../helpers/messageContent'
import staticContent from '../helpers/staticContent'

// const Sockette = require('sockette');
// import Sockette from 'sockette'
// import { socket, SocketContext } from './context/socket'

const Storyvideos = forwardRef((props, ref) => {
  const myRef = useRef(null)

  const storyId = props.story._id
  const story = props.story
  const { REACT_APP_RESPONSE_MAX_DURATION } = process.env

  const [seeAllvideos, setSeeAllvideos] = useState(1)
  const [resVideo, setResVideo] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [videos, setVideos] = useState([])
  const [showNoData, setShowNoData] = useState(false)
  const [maxmizeStoryResponse, setMaxmizeStoryResponse] = useState(true)
  const [newUrl, setNewUrl] = useState('')
  const [newIMageUrl, setNewImageUrl] = useState('')
  const [fullName, setFullName] = useState('')
  const [commentedOn, setCommentedOn] = useState('')
  const [respondedUserId, setRespondedUserId] = useState('')
  const [load, setLoad] = useState(false)
  const [storyResponsePlayStatus, setStoryResponsePlayStatus] = useState(true)
  const [storyResponseMuteStatus, setStoryResponseMuteStatus] = useState(false)

  const dispatch = useDispatch()

  // const getVideos = useSelector(state => state.users.videos)
  // useImperativeHandle(ref, () => ({
  //   pauseVolume: (val) => {
  //     setImp(0)
  //   dispatch(resetResponseProperties(true))

  //   },
  //   updatemaxmizeStoryResponse: (input) => {
  //     setMaxmizeStoryResponse(input)
  //     setNewUrl('')
  //   },
  // }));

  //  **************************************************
  //    Get responses of specific story functionality
  //  **************************************************
  async function getStoryResponses (storyId) {
    let data = {
      storyId: storyId,
      sLimit: 0,
      eLimit: 100
    }
    dispatch(
      fetchVideos(data, response => {
        getVideosData(response.data.videos && response.data.videos)
      })
    )
    setShowNoData(false)
  }
  const getVideosData = getVideos => {
    setVideos([])
    let latestVideos = getVideos
    if (
      latestVideos == null ||
      latestVideos == undefined ||
      latestVideos == ''
    ) {
      setVideos([])
      if (
        story &&
        story.allowVideos == 'private' &&
        story.userId !== localStorage.userId
      ) {
        setShowNoData(false)
        setIsLoading(false)
      } else {
        setShowNoData(true)
        setIsLoading(false)
      }
    } else {
      if (story && story.allowVideos && latestVideos.length > 0) {
        setShowNoData(false)
      } else {
      }
      timeCalculation(latestVideos).then(res => {
        setShowNoData(false)
        setIsLoading(false)
        setVideos(res)
      })
    }
    setIsLoading(false)
  }
  useEffect(() => {
    getStoryResponses(storyId)
  }, [storyId])

  const configureSocket = () => {
    var socket = socketClient(
      'wss://iyd7fnycec.execute-api.ap-south-1.amazonaws.com/dev',
      {
        Headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
          universityId: `${localStorage.universityId}`,
          userId: `${localStorage.userId}`
        },
        withCredentials: true,
        transports: ['websocket', 'polling'] // use WebSocket first, if available
        // cors: {
        //   origin: "*",
        //   methods: ["GET", "POST"]
        // }
      }
    )

    socket.on('connect', () => {
      console.log('connection established')
    })
    socket.on('commentcreated', message => {
      console.log({ message })
    })
  }

  //  ********************************************
  //       Upload response functionality
  //  ********************************************
  async function getDuration (file) {
    const url = URL.createObjectURL(file)
    return new Promise(resolve => {
      const audio = document.createElement('audio')
      audio.muted = true
      const source = document.createElement('source')
      source.src = url //--> blob URL
      audio.preload = 'metadata'
      audio.appendChild(source)
      audio.onloadedmetadata = function () {
        resolve(audio.duration)
      }
    })
  }
  const uploadHandleChange = async e => {
    if (e.target.files[0].type !== 'video/mp4') {
      return swal({
        text: `${MSGContent.response_file_type_msg}`,
        icon: 'warning',
        buttons: true,
        dangerMode: true
      })
    }
    const duration = await getDuration(e.target.files[0])
    if (duration > REACT_APP_RESPONSE_MAX_DURATION) {
      swal({
        text: `${MSGContent.response_durations_msg}`,
        icon: 'warning',
        buttons: true,
        dangerMode: true
      })
    } else {
      setTimeout(() => {
        setLoad(true)
      }, 500)
      AwsS3Uploadfunctionality(e.target.files[0]).then(res => {
        submitTotalVideoObj(res)
      })
    }
  }

  //  ********************************************
  //       Submit response functionality
  //  ********************************************
  const submitTotalVideoObj = async awsStoryObject => {
    const { Location, Bucket, Key, region, type } = awsStoryObject
    let genFileKey = Key.split('.')
    let data = {
      userId: localStorage.userId,
      universityId: localStorage.universityId,
      storyId: storyId,
      videoProperties: {
        url: Location,
        name: genFileKey[0],
        size: `${resVideo.size}.kbs`,
        type: resVideo.type
      }
    }
    await dispatch(insertVideos(data))
      .then(function (res) {
        setLoad(false)
        setResVideo('')
        getStoryResponses(storyId)
      })
      .catch(err => {
        getStoryResponses(storyId)
        setLoad(false)
      })
  }

  //  ********************************************
  //       See all responses functionality
  //  ********************************************
  const seeAllVideosContent = async value => {
    if (value == 2) {
      var elmnt = document.getElementById('scroll3')
      elmnt.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
      setSeeAllvideos(1)
    } else {
      setSeeAllvideos(2)
    }
  }

  //  ********************************************
  //       React-Player functionality
  //  ********************************************
  const maximizeResponse = (
    id,
    newUrl,
    imgUrl,
    fName,
    lName,
    commentedOn,
    userId
  ) => {
    setNewUrl(newUrl)
    setNewImageUrl(imgUrl)
    setFullName(`${fName} ${lName}`)
    setMaxmizeStoryResponse(false)
    setCommentedOn(commentedOn)
    setRespondedUserId(userId)
    props.stopParentPlayingStory(false)
    function intialise () {
      try {
        setTimeout(() => {
          $('.responseCloseLink3').fadeIn(0.1, function () {
            $(this).focus()
          })
        }, 0)
      } catch (error) {}
    }
    intialise()
  }
  const storyResponsePlayingHandler = () => {
    setStoryResponsePlayStatus(!storyResponsePlayStatus)
  }
  const storyResponseMuteHandler = () => {
    setStoryResponseMuteStatus(!storyResponseMuteStatus)
  }
  const moveToProfileDetails = id => {
    seeProfileInformation(id)
  }
  return (
    <div className='video-comment'>
      {maxmizeStoryResponse ? (
        <div>
          {story && !story.responseTypes.includes('videos') ? (
            ''
          ) : (
            <div className='pb-0 video-response'>
              <p className=''>{`Do you want to add a video response to ${story.userProperties[0].firstName} ${story.userProperties[0].lastName}'s story?`}</p>
              <form>
                <div className='d-flex gap-2'>
                  {load ? (
                    <div className='record loading' />
                  ) : (
                    <button
                      type='button'
                      className='btn btn-primary record-comment'
                    >
                      <i className='bi bi-camera-video'>
                        <input
                          type='file'
                          accept='video/mp4'
                          onClick={e => e.target.files[0]('')}
                          onChange={uploadHandleChange}
                        />
                      </i>
                      <span className='mb-0 mx-2'>
                        {staticContent.res_browse_btn_ctn}
                      </span>
                    </button>
                  )}
                </div>
              </form>
              <hr className={load ? 'mt-4' : ''} />
            </div>
          )}

          <div className='responses'>
            <h6 className='mb-0'>{staticContent.resp_rctns_title}</h6>
            {showNoData ? (
              <div>
                <p className='noResponseMessage'>
                  {' '}
                  {staticContent.no_resps_cnt}
                </p>
              </div>
            ) : (
              ''
            )}
            {story &&
            story.allowVideos == 'private' &&
            story.userId !== localStorage.userId ? (
              <div>
                <p className='noResponseMessage'>
                  {staticContent.auth_can_see_rctns}{' '}
                </p>{' '}
              </div>
            ) : isLoading ? (
              <div className='storyVideo loading' />
            ) : (
              <div
                className={
                  seeAllvideos == 2 ? 'videoResponseSeeAll' : 'videoResponse'
                }
              >
                {videos && videos
                  ? videos.map((e, index) => {
                      return (
                        <div
                          className='video-img-thumb'
                          id='scroll3'
                          onClick={() =>
                            maximizeResponse(
                              e._id,
                              e.videoProperties.url,
                              e.userProperties[0].profileImg,
                              e.userProperties[0].firstName,
                              e.userProperties[0].lastName,
                              e.commentedOn,
                              e.userId
                            )
                          }
                        >
                          <div className='top-video-img'>
                            <img
                              src={e.userProperties[0].profileImg}
                              className='rounded-circle'
                              width='100%'
                              height='100%'
                              alt=''
                              key={index}
                            />
                          </div>
                          <div className='base-video-img'>
                            <video
                              src={e.videoProperties.url}
                              width='100%'
                              height='100%'
                              controls={false}
                              disablePictureInPicture
                              // playing={true}
                              // onBuffer={true}
                              loop={true}
                              muted={true}
                              volume={0}
                              autoPlay={true}
                            />
                            {/* <ReactPlayer
                                url={
                                  e.videoProperties.url
                                }
                                width='100%'
                                height='100%'
                                controls={false}
                                playing={true}
                                onBuffer={true}
                                loop={true}
                                volume={0}
                              >
                              </ReactPlayer> */}
                          </div>
                        </div>
                      )
                    })
                  : ''}
              </div>
            )}
          </div>
          {(story &&
            story.allowVideos == 'private' &&
            story.userId !== localStorage.userId) ||
          (videos && videos.length <= 3) ? (
            ''
          ) : (
            <div className='see-all px-3'>
              <hr />
              <a onClick={() => seeAllVideosContent(seeAllvideos)}>
                {seeAllvideos == 2 ? 'Show less' : 'See all'}
              </a>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className='d-flex px-4 py-3 align-items-center'>
            <div className='profile_img'>
              <a onClick={() => moveToProfileDetails(respondedUserId)}>
                <img
                  src={newIMageUrl}
                  className='rounded-circle'
                  width='100%'
                  height='100%'
                  alt=''
                />
              </a>
            </div>
            <div className=' ps-2'>
              <h6 className='mb-0'>
                <a onClick={() => moveToProfileDetails(respondedUserId)}>
                  {fullName}
                </a>
              </h6>
              <small className='ps-3'>{commentedOn}</small>
            </div>
          </div>

          <div className='userVideoFull'>
            {/* <ReactPlayer
              url={newUrl}
              width='100%'
              height='100%'
              controls={false}
              onBuffer={true}
              loop={true}
              volume={1}
              playing={storyResponsePlayStatus}
              muted={storyResponseMuteStatus}
            ></ReactPlayer> */}

            <video
              src={newUrl}
              width='100%'
              height='100%'
              controls={true}
              disablePictureInPicture
              controlsList="nodownload"
              // playing={true}
              // onBuffer={true}
              loop={true}
              muted={storyResponseMuteStatus}
              nodownload
              volume={1}
              autoPlay={true}
            />
            {/* <div className='play-btn'>
              <div
                onClick={storyResponsePlayingHandler}
                aria-hidden='true'
                className='hoverEffectButton'
              >
                <img
                  src={
                    storyResponsePlayStatus
                      ? '../../assets/images/pause-circle.svg'
                      : '../../assets/images/play.svg'
                  }
                  width='100%'
                  height='100%'
                  alt='overlay'
                />
              </div>
            </div> */}
            <div className='mute-btn'>
              <div
                onClick={storyResponseMuteHandler}
                aria-hidden='true'
                className='hoverEffectButton'
              >
                <img
                  src={
                    storyResponseMuteStatus
                      ? '../../assets/images/unmute-icon.svg'
                      : '../../assets/images/mute-icon.svg'
                  }
                  width='100%'
                  height='100%'
                  alt='overlay'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
})
const mapStateToProps = state => {
  return {
    videos: state.users.videos
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchVideos: () => dispatch(fetchVideos())
  }
}
export default Storyvideos
