const MSGContent = {
    search_alert_msg: "Please select emotions or enter text.",
    tap_success_msg: "Successfully tapped story.",
    tap_fail_msg: "File tapped story.",
    file_format_type_msg: "Please select mp4 file format.",
    file_duration_message: "Please select less than 5 minutes video.",
    select_video_msg: "Please select a video",
    title_error_msg: "Title should be 5 to 50 characters.",
    description_error_msg: "Description should be 5 to 100 characters.",
    story_update_success_msg: "Your story updated successfully.",
    story_update_fail_msg: "Your story update failed.",
    story_under_approval_msg: "Your Story created successfully, it is under admin approval process. You will be notified on its later action.",
    story_approved_msg: "Your story has auto approved.",
    report_story_placeholder_msg: "Please provide comments for reporting the story.",
    report_story_title_msg: "Are you sure you want to report the story?",
    report_story_alert_msg: "Kindly provide your comments for report the story.",
    report_story_success_msg: "Successfully reported.",
    report_story_fail_msg:"Fail report story.",
    story_delete_success_msg:"Successfully deleted.",
    story_delete_fail_msg:"Fail delete story.",
    response_file_type_msg:"Please select mp4 file format.",
    response_durations_msg:"Please select less than 1 minute video."
}
export default MSGContent


