import AWS from 'aws-sdk'
export async function AwsS3Uploadfunctionality(videoFile) {
    return new Promise((resolve, reject) => {
        const {
            REACT_APP_ACCESSKEY_UPLOAD,
            REACT_APP_SECRETACCESSKEY_UPLOAD,
            REACT_APP_REGION_UPLOAD,
            REACT_APP_BUCKETNAME_UPLOAD
        } = process.env
        const characters =
            'UPSQUADABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789BLOOMING'
        let genFileKeytem = ''
        for (var i = 0; i < 20; i++) {
            genFileKeytem += characters.charAt(Math.floor(Math.random() * 20))
        }
        const params = {
            ACL: 'public-read',
            Body: videoFile,
            Bucket: REACT_APP_BUCKETNAME_UPLOAD,
            Key: `${genFileKeytem}.mp4`,
            ContentType: videoFile.type
        }
        AWS.config.update({
            accessKeyId: REACT_APP_ACCESSKEY_UPLOAD,
            secretAccessKey: REACT_APP_SECRETACCESSKEY_UPLOAD
        })
        const myBucket = new AWS.S3({
            params: { Bucket: REACT_APP_BUCKETNAME_UPLOAD },
            region: REACT_APP_REGION_UPLOAD
        })
        myBucket.upload(params, async function (err, resposeData) {
            if (resposeData) {
                resolve(resposeData)
            } else {
                console.log(err, err.stack)
            }
        })
    })
}

export async function AwsS3GeneratePresignedUrl(temStories) {
    const { REACT_APP_ACCESSKEY, REACT_APP_SECRETACCESSKEY, REACT_APP_AWSS3BUCKET } = process.env
    var temp = []
    temStories.map(obj => {
        temp.push(
            new Promise(async (resolve, reject) => {
                const {
                    thumbnail,
                    originalURL,
                    parentBucket,
                } = obj.storyProperties
                let url1 = thumbnail
                let url2 = originalURL
                if (!url1 && url2) {
                    resolve()
                } else {
                    let key1 = url1.split('.com/')
                    let key2 = url2.split('.com/')
                    let arrkey = parentBucket.split('/')
                    const s3 = new AWS.S3()
                    await AWS.config.update({
                        accessKeyId: REACT_APP_ACCESSKEY,
                        secretAccessKey: REACT_APP_SECRETACCESSKEY
                    })
                    s3.getSignedUrl(
                        'getObject',
                        {
                            Bucket: arrkey[0],
                            Expires: 129600,
                            Key: key1[1]
                        },
                        async (err, pic) => {
                            if (pic == undefined) {
                                resolve()
                            } else {
                                obj['thumb'] = pic
                                resolve()
                            }
                        }
                    )
                    s3.getSignedUrl(
                        'getObject',
                        {
                            Bucket: REACT_APP_AWSS3BUCKET,
                            Expires: 129600,
                            Key: key2[1]
                        },
                        async (err, pic) => {
                            if (pic == undefined) {
                                resolve()
                            } else {
                                obj['vid'] = pic
                                resolve()
                            }
                        }
                    )
                }
            })
        )
        return Promise.resolve(temStories)
    })
    return temStories
}