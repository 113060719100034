import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { AwsS3Uploadfunctionality } from '../utils/awsUtils'
import AppConstants from '../../ApplicationConstants'
import MSGContent from '../helpers/messageContent'
import $ from 'jquery'
import PlutchikWheel from './plutchikWheel'
import {
  getUserSettingsFromProfile,
  getProfileOrganizationsList,
  insertStories,
  updateStories,
  storeDataObjectData, // This action used to store created story,edit story and uploaded story agian edit data.
  resetStoryTotalObject,
  visibilitySettingsObjectData, //Store story object data  
  clearUploadedEditStoryInReduxStore,//Store story object data

} from '../../redux/actions/storiesAction'
import swal from 'sweetalert'
import UpsquadHeader from '../layouts/header'
import staticContent from '../helpers/staticContent'

window.Buffer = window.Buffer || require('buffer').Buffer
const Createstory = props => {
  const [video, setVideo] = useState('') // new video
  const [uploadedEditStoryURL, setUploadedEditStoryURL] = useState('') //uploaded story video  url 
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [isAnonymous, setIsAnonymous] = useState(false)
  const [feelings, setFeelings] = useState([])
  const [visibilitySettingsIsEdit, setIsEdit] = useState(false)// visibility settings edit status 
  const [selectTeam, setSelectTeams] = useState([])
  const [accessTo, setAccessTo] = useState() //who can see(access) my post
  const [allowEmojis, setAllowEmojis] = useState('') //who can see responses
  const [allowComments, setAllowComments] = useState('') //who can see response
  const [allowVideos, setAllowVideos] = useState('') //who can see responses
  const [allowSharing, setAllowSharing] = useState(false)
  const [emojis, setEmojis] = useState() //who can react
  const [comments, setComments] = useState() //who can react
  const [videos, setVideos] = useState() //who can react
  const [errors, setErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [load, setLoad] = useState(false)
  const [profileSettingsList, setProfileSettingsList] = useState({})
  const [profileOrganizationsList, setProfileOrganizationsList] = useState({})
  const [uploadedStoryEditStatus, setUploadedStoryEditStatus] = useState(false) // uploades story edit status

  const { REACT_APP_STORY_MAX_DURATION, REACT_APP_STORY_MAX_SIZE } = process.env
  const fileInput = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation();

  // While edit store story data in Redux store
  const storyObjectData = useSelector(
    state => state.users.storyObjectData
  )
  // If story settings(visibility settings) edit
  const visibilitySettingsObject = useSelector(
    state => state.users.storeVisibilitySettingsStoryObjectDataSuccess
  )
  // All stories data
  const allCommunityStories = useSelector(
    state => state.users.allStories
  )
  // While edit Uploaded story data store in Redux store
  const uploadedEditStoryDetails = useSelector(
    state => state.users.uploadedEditStoryDetails
  )


  /**  ********************************************
         Moving back to the play stories screen
       ********************************************  */
  const moveBackToCreateStoryScreen = async e => {
    let clearData = [];
    dispatch(clearUploadedEditStoryInReduxStore(clearData))
    dispatch(visibilitySettingsObjectData(clearData));
    navigate('/cGxheXN0b3JpZXM=')
  }
  //Updating emotions 
  const handleDataFromWheelOfEmotions = async index => {
    setFeelings(index)
  }

  /**  ********************************************
             Update state functionality
       ******************************************** */
  const updateUserVisibilityData = async (
    profileSettingsList,
    visibilitySettingsObject
  ) => {
    let editStoryId = uploadedEditStoryDetails && uploadedEditStoryDetails.editStoryId
    let uploadedStoryEdit = uploadedEditStoryDetails && uploadedEditStoryDetails.uploadedStoryEdit
    let editStoryObj = allCommunityStories.filter(obj => {
      return obj._id == editStoryId
    })
    //If user edited visibility settings .
    if (visibilitySettingsObject && visibilitySettingsObject.isEdit) {
      let { title, description, video, feelings, isAnonymous, uploadedEditStoryURL, uploadedStoryEditStatus } = storyObjectData
      setTitle(title)
      setDescription(description)
      setVideo(video)
      setUploadedEditStoryURL(uploadedEditStoryURL)
      setFeelings(feelings)
      setIsAnonymous(isAnonymous)
      setIsEdit(true)
      setSelectTeams([])
      setUploadedStoryEditStatus(uploadedStoryEditStatus)
      let Storysettings =
        profileSettingsList.status == false
          ? ''
          : profileSettingsList.data[0].visibilitySettings.Story
      setAllowEmojis(
        Storysettings == ''
          ? 'private'
          : Storysettings.responsesEmoji == '1'
            ? 'public'
            : 'private'
      )
      setAllowComments(
        Storysettings == ''
          ? 'private'
          : Storysettings.responsesComment == '1'
            ? 'public'
            : 'private'
      )
      setAllowVideos(
        Storysettings == ''
          ? 'private'
          : Storysettings.responsesVideo == '1'
            ? 'public'
            : 'private'
      )
      if (Storysettings == '') {
        setAccessTo('private')
      } else {
        setAccessTo(
          Storysettings.storyPost == '1'
            ? 'private'
            : Storysettings.storyPost == '3'
              ? 'public'
              : 'team'
        )
      }
      if (Storysettings == '') {
      } else {
        await Storysettings.responsesStory.map(async obj => {
          return obj == '1'
            ? setComments('1')
            : obj == '2'
              ? setEmojis('2')
              : obj == '3'
                ? setVideos('3')
                : ''
        })
      }
    }
    //If user edit uploaded story .
    else if (uploadedStoryEdit && uploadedStoryEdit == true) {
      setUploadedStoryEditStatus(true)
      const { title, description, feelings, isAnonymous, accessTo, allowComments, allowEmojis, allowVideos, allowSharing, responseTypes, selectedTeam, storyProperties } = editStoryObj && editStoryObj[0]
      setTitle(title)
      setDescription(description)
      setUploadedEditStoryURL(storyProperties.originalURL)
      setFeelings(feelings)
      setIsAnonymous(isAnonymous)
      setAllowSharing(allowSharing)
      setAllowComments(allowComments);
      setAllowEmojis(allowEmojis);
      setAllowVideos(allowVideos);
      setAccessTo(accessTo)
      setSelectTeams(selectedTeam)
      await responseTypes.map(async obj => {
        return obj == 'comments'
          ? setComments('1')
          : obj == 'emojis'
            ? setEmojis('2')
            : obj == 'videos'
              ? setVideos('3')
              : ''
      })
      let resArr = []
      await responseTypes.map(async obj => {
        return obj == 'comments'
          ? setComments('1')
          : obj == '2'
            ? setEmojis('emojis')
            : obj == '3'
              ? setVideos('videos')
              : ''

      })
      let storyEmotions = {
        feelings: feelings
      }
      dispatch(storeDataObjectData(storyEmotions))
    }
    //If user creating story without visibility settings.
    else {
      // ********    reverse state management   **********
      let { title, description, video, feelings, isAnonymous } = storyObjectData
      setTitle(title)
      setDescription(description)
      setVideo(video)
      setFeelings(feelings)
      setIsAnonymous(isAnonymous)
      setIsEdit(false)
      let Storysettings =
        profileSettingsList.status == false
          ? ''
          : profileSettingsList.data[0].visibilitySettings.Story
      setAllowEmojis(
        Storysettings == ''
          ? 'private'
          : Storysettings.responsesEmoji == '1'
            ? 'public'
            : 'private'
      )
      setAllowComments(
        Storysettings == ''
          ? 'private'
          : Storysettings.responsesComment == '1'
            ? 'public'
            : 'private'
      )
      setAllowVideos(
        Storysettings == ''
          ? 'private'
          : Storysettings.responsesVideo == '1'
            ? 'public'
            : 'private'
      )
      if (Storysettings == '') {
        setAccessTo('private')
      } else {
        setAccessTo(
          Storysettings.storyPost == '1'
            ? 'private'
            : Storysettings.storyPost == '3'
              ? 'public'
              : 'team'
        )
      }
      if (Storysettings == '') {
      } else {
        await Storysettings.responsesStory.map(async obj => {
          return obj == '1'
            ? setComments('1')
            : obj == '2'
              ? setEmojis('2')
              : obj == '3'
                ? setVideos('3')
                : ''
        })

        // }
      }
    }
    setIsLoading(false)
  }

  /**   ********************************************
          Get user profile and organisation data
        ********************************************   */
  const getUserProfileDataAndOrgData = async () => {
    let data = ''
    dispatch(
      getUserSettingsFromProfile(data, res => {
        setProfileSettingsList(res)
      })
    )
    let data1 = ''
    dispatch(
      getProfileOrganizationsList(data1, res => {
        setProfileOrganizationsList(res)
      })
    )
  }
  useEffect(() => {
    getUserProfileDataAndOrgData()
  }, [])


  useEffect(() => {
    if (profileSettingsList && profileOrganizationsList) {
      updateUserVisibilityData(
        profileSettingsList,
        profileOrganizationsList,
        visibilitySettingsObject
      )
    }
  }, [profileSettingsList, profileOrganizationsList, visibilitySettingsObject])

  /**  ********************************************
      Redirection to visibility settings
  ********************************************  */
  const redirectToVisibilitySettings = async () => {
    if (!uploadedStoryEditStatus) {
      let settingObj = {
        title: title && title,
        description: description && description,
        video: video && video,
        feelings: feelings && feelings,
        isAnonymous: isAnonymous && isAnonymous,
        allowEmojis: allowEmojis && allowEmojis, // who can see
        allowComments: allowComments && allowComments, // who can see
        allowVideos: allowVideos && allowVideos, // who can see
        accessTo: accessTo && accessTo,
        emojis: emojis && emojis, //reactions to the story
        comments: comments && comments, //reactions to the story
        videos: videos && videos, //reactions to the story
        uploadedStoryEditStatus: uploadedStoryEditStatus
      }
      // This action used to store created story,edit story and uploaded story agian edit data.
      dispatch(storeDataObjectData(settingObj))
    }
    else {
      // let { title, description, video, feelings, isAnonymous, uploadedEditStoryURL, uploadedStoryEditStatus, } = storyObjectData
      const {
        allowEmojisEdit,
        selectedTeamsEdit,
        allowCommentsEdit,
        allowVideosEdit,
        allowSharingEdit,
        accessToEdit,
        responseTypesEdit,
        isEdit
      } = await visibilitySettingsObject;
      let responseTypes = []
      responseTypes.push(emojis, comments, videos)
      let settingObj = {
        title: title && title,
        description: description && description,
        video: video && video,
        uploadedEditStoryURL: uploadedEditStoryURL,
        feelings: feelings && feelings,
        isAnonymous: isAnonymous && isAnonymous,

        accessToEdit: isEdit ? accessToEdit : accessTo,
        selectedTeamsEdit: isEdit ? selectedTeamsEdit : selectTeam,
        allowEmojisEdit: isEdit ? allowEmojisEdit : allowEmojis, // who can see
        allowCommentsEdit: isEdit ? allowCommentsEdit : allowComments, // who can see
        allowVideosEdit: isEdit ? allowVideosEdit : allowVideos, // who can see
        emojis: emojis && emojis, //reactions to the story
        comments: comments && comments, //reactions to the story
        videos: videos && videos, //reactions to the story
        allowSharingEdit: isEdit ? allowSharingEdit : allowSharing,
        isEdit: true,
        responseTypesEdit: isEdit ? typeof responseTypesEdit[0] == 'undefined' ? [] : responseTypesEdit : typeof responseTypes[0] == 'undefined' ? [] : responseTypes,
        uploadedStoryEditStatus: true
      }
      // This action used to store uploaded story agian edit the data.
      dispatch(storeDataObjectData(settingObj))
      dispatch(visibilitySettingsObjectData(settingObj));
    }
  }

  /**  ********************************************
           video uploading functionality
       ********************************************  */
  async function getDuration(file) {
    const url = URL.createObjectURL(file)
    return new Promise(resolve => {
      const audio = document.createElement('audio')
      audio.muted = true
      const source = document.createElement('source')
      source.src = url //--> blob URL
      audio.preload = 'metadata'
      audio.appendChild(source)
      audio.onloadedmetadata = function () {
        resolve(audio.duration)
      }
    })
  }
  const uploadHandleChange = async e => {
    if (e.target.files[0].type !== 'video/mp4') {
      return swal({
        text: `${MSGContent.file_format_type_msg}`,
        icon: 'warning',
        buttons: true,
        dangerMode: true
      })
    }
    var totalSizeMB = e.target.files[0].size / Math.pow(1024, 2);
    const duration = await getDuration(e.target.files[0])
    if (duration > REACT_APP_STORY_MAX_DURATION) {
      swal({
        text: `${MSGContent.file_duration_message}`,
        icon: 'warning',
        buttons: true,
        dangerMode: true
      })
    }
    else {
      setVideo(e.target.files[0])
    }
  }

  /**  ********************************************
           Submit story functinality
      ********************************************  */
  const handleSubmitStoryData = async (e) => {
    e.preventDefault()
    let uploadedVideo = video ? video : uploadedEditStoryURL
    if (
      title == undefined ||
      description == undefined ||
      uploadedVideo == '' ||
      uploadedVideo == undefined
    ) {
      setIsLoading(false)
      setErrors(true)
      if (uploadedVideo == '' || uploadedVideo == undefined) {
        swal({
          text: `${MSGContent.select_video_msg}`,
          icon: 'warning',
          buttons: true,
          dangerMode: true
        })
        return false
      }
      return false
    }
    else if (
      title.trim() == '' ||
      title.trim().length < 5 ||
      title.trim().length > 50 ||
      description.trim() == '' ||
      description.trim().length < 5 ||
      description.trim().length > 100
    ) {
      setIsLoading(false)
      setErrors(true)
      if (title.trim() == '') {
        setTitle('')
      }
      if (description.trim() == '') {
        setDescription('')
      }
    } else {
      setLoad(true)
      setErrors(false)
      if (uploadedEditStoryDetails && uploadedEditStoryDetails.uploadedStoryEdit) {
        //Uploaded story edit submit.
        submitTotalUploadedStoryObj()
      }
      else {
        //Newly created story submit.
        AwsS3Uploadfunctionality(video).then(res => {
          submitTotalStoryObj(res)
        })
      }
    }
  }
  const submitTotalUploadedStoryObj = async () => {
    let editStoryId = uploadedEditStoryDetails && uploadedEditStoryDetails.editStoryId
    let editStoryObj = allCommunityStories.filter(obj => {
      return obj._id == editStoryId
    })
    const {
      allowEmojisEdit,
      selectedTeamsEdit,
      allowCommentsEdit,
      allowVideosEdit,
      allowSharingEdit,
      accessToEdit,
      responseTypesEdit,
      isEdit
    } = await visibilitySettingsObject
    let resEditArr = [];

    //response types if edit in visibility settings
    if (storyObjectData.isEdit) {
      await responseTypesEdit.map(async obj => {
        return obj == '1'
          ? resEditArr.push('comments')
          : obj == '2'
            ? resEditArr.push('emojis')
            : obj == '3'
              ? resEditArr.push('videos')
              : ''
      })
    }
    else {
      let resArr = []
      resArr.push(emojis, comments, videos)
      await resArr.map(async obj => {
        return obj == '1'
          ? resEditArr.push('comments')
          : obj == '2'
            ? resEditArr.push('emojis')
            : obj == '3'
              ? resEditArr.push('videos')
              : ''
      });
    }

    let uploadedStoryEditObjectData = {
      'title': title,
      'description': description,
      "isAnonymous": isAnonymous == true ? true : false,
      "feelings": feelings,
      "selectedTeam": isEdit !== undefined ? selectedTeamsEdit : selectTeam,
      "allowEmojis": isEdit ? allowEmojisEdit : allowEmojis,
      "allowComments": isEdit ? allowCommentsEdit : allowComments,
      "allowVideos": isEdit ? allowVideosEdit : allowVideos,
      "allowSharing": isEdit ? allowSharingEdit : allowSharing,
      "accessTo": isEdit ? accessToEdit : accessTo,
      "responseTypes": typeof resEditArr[0] == 'undefined' ? [] : resEditArr
    }
    let FinalMutatedObject = { ...editStoryObj[0], ...uploadedStoryEditObjectData }
    dispatch(
      updateStories(FinalMutatedObject, data => {
        let restData = [];
        dispatch(clearUploadedEditStoryInReduxStore(restData))
        const resetStateCreatedState = () => {
          setUploadedStoryEditStatus(false)
          setDescription('')
          setTitle('')
          setFeelings([])
          setIsAnonymous(false)
          setVideo('')
          setIsEdit(false)
          setSelectTeams([])
          setAccessTo('')
          setAllowComments('')
          setAllowEmojis('')
          setAllowVideos('')
          setAllowSharing(false)
          setEmojis('')
          setVideos('')
          setComments('')
          setLoad(false)
          dispatch(resetStoryTotalObject())
          navigate('/cGxheXN0b3JpZXM=')
        }
        if (data.status == 'success') {
          setLoad(false)
          swal({
            text: `${MSGContent.story_update_success_msg}`,
            icon: 'success',
            button: 'okay'
          }).then(value => {
            if (value !== '') {
              resetStateCreatedState()
            }
          })
        } else {
          setLoad(false)
          swal({
            text: `${MSGContent.story_update_fail_msg}`,
            icon: 'success',
            button: 'okay'
          }).then(value => {
            if (value !== '') {
              resetStateCreatedState()
            }
          })
        }
      })
    )
  }
  const submitTotalStoryObj = async awsObj => {
    const { Location, Bucket, Key, region, type } = await awsObj
    let genFileKey = Key.split('.')
    let userId = localStorage.getItem('userId')
    let universityId = localStorage.getItem('universityId')
    //If newly created story edit in visibility settings
    if (visibilitySettingsIsEdit) {
      const {
        allowEmojisEdit,
        selectedTeamsEdit,
        allowCommentsEdit,
        allowVideosEdit,
        allowSharingEdit,
        accessToEdit,
        responseTypesEdit
      } = await visibilitySettingsObject

      let resArr = []
      await responseTypesEdit.map(async obj => {
        return obj == '1'
          ? resArr.push('comments')
          : obj == '2'
            ? resArr.push('emojis')
            : obj == '3'
              ? resArr.push('videos')
              : ''
      })
      let data = {
        userId: userId,
        universityId: universityId,
        title: title,
        description: description,
        isAnonymous: isAnonymous == true ? true : false,
        feelings: feelings,
        selectedTeam: accessToEdit == 'team' ? selectedTeamsEdit : [],
        allowEmojis: allowEmojisEdit,
        allowComments: allowCommentsEdit,
        allowVideos: allowVideosEdit,
        allowSharing: allowSharingEdit,
        accessTo: accessToEdit,
        responseTypes: typeof resArr[0] == 'undefined' ? [] : resArr,
        storyProperties: {
          fileName: genFileKey[0],
          originalURL: Location,
          type: type,
          parentBucket: Bucket,
          originalFileSize: `${video.size}.kbs`
        }
      }
      const demoInsert = async data => {
        let insertedStoryvalue = new Promise((resolve, reject) => {
          dispatch(
            insertStories(data, async res => {
              let queuedStory = res.data.stories
              queuedStory['storyStatus'] = 'uploaded'
              dispatch(
                updateStories(queuedStory, data => {
                  const resetStateCreatedState = () => {
                    setDescription('')
                    setTitle('')
                    setFeelings([])
                    setIsAnonymous(false)
                    setVideo('')
                    setIsEdit(false)
                    setSelectTeams([])
                    setAccessTo('')
                    setAllowComments('')
                    setAllowEmojis('')
                    setAllowVideos('')
                    setAllowSharing(false)
                    setEmojis('')
                    setVideos('')
                    setComments('')
                    setLoad(false)
                    let clearData = [];
                    dispatch(clearUploadedEditStoryInReduxStore(clearData))
                    dispatch(visibilitySettingsObjectData(clearData));
                    dispatch(resetStoryTotalObject())
                    navigate('/cGxheXN0b3JpZXM=')
                  }
                  if (
                    data.message ==
                    'Your story is under admin approval process. You will be notified on its later action.'
                  ) {
                    setLoad(false)
                    swal({
                      text: `${MSGContent.story_under_approval_msg}`,
                      icon: 'success',
                      button: 'okay'
                      // dangerMode: true,
                    }).then(value => {
                      if (value !== '') {
                        resetStateCreatedState()
                      }
                    })
                  } else {
                    setLoad(false)
                    swal({
                      text: `${MSGContent.story_approved_msg}`,
                      icon: 'success',
                      button: 'okay'
                      // dangerMode: true,
                    }).then(value => {
                      if (value !== '') {
                        resetStateCreatedState()
                      }
                    })
                    // resetStateCreatedState()
                  }
                })
              )
            })
          )
        })
      }
      await demoInsert(data)
    }
    //If newly created story withou edit in visibility settings(direct submit)
    else {
      let resArr = []
      resArr.push(emojis, comments, videos)
      var filtered = resArr.filter(function (el) {
        return el != ''
      })

      let resArr1 = []
      await resArr.map(async obj => {
        return obj == '1'
          ? resArr1.push('comments')
          : obj == '2'
            ? resArr1.push('emojis')
            : obj == '3'
              ? resArr1.push('videos')
              : ''
      })
      let data = {
        userId: userId,
        universityId: universityId,
        title: title,
        description: description,
        isAnonymous: isAnonymous == true ? true : false,
        feelings: feelings,
        selectedTeam: [],
        allowEmojis: allowEmojis,
        allowComments: allowComments,
        allowVideos: allowVideos,
        allowSharing: allowSharing,
        accessTo: accessTo == 'public' ? 'public' : 'private',
        responseTypes: typeof resArr1[0] == 'undefined' ? [] : resArr1,
        storyProperties: {
          fileName: genFileKey[0],
          originalURL: Location,
          type: type,
          parentBucket: Bucket,
          originalFileSize: `${video.size}.kbs`
        }
      }
      const demoInsert = async data => {
        let insertedStoryvalue = new Promise((resolve, reject) => {
          dispatch(
            insertStories(data, async res => {
              let queuedStory = res.data.stories
              queuedStory['storyStatus'] = 'uploaded'
              dispatch(
                updateStories(queuedStory, data => {
                  const resetStateCreatedState = () => {
                    setDescription('')
                    setTitle('')
                    setFeelings([])
                    setIsAnonymous(false)
                    setVideo('')
                    setIsEdit(false)
                    setSelectTeams([])
                    setAccessTo('')
                    setAllowComments('')
                    setAllowEmojis('')
                    setAllowVideos('')
                    setAllowSharing(false)
                    setEmojis('')
                    setVideos('')
                    setComments('')
                    setLoad(false)
                    dispatch(resetStoryTotalObject())
                    let clearData = [];
                    dispatch(clearUploadedEditStoryInReduxStore(clearData))
                    dispatch(visibilitySettingsObjectData(clearData));
                    navigate('/cGxheXN0b3JpZXM=')
                  }
                  if (
                    data.message ==
                    'Your story is under admin approval process. You will be notified on its later action.'
                  ) {
                    setLoad(false)
                    swal({
                      text: `${MSGContent.story_under_approval_msg}`,
                      icon: 'success',
                      button: 'okay'
                      // dangerMode: true,
                    }).then(value => {
                      if (value !== '') {
                        resetStateCreatedState()
                      }
                    })
                  } else {
                    setLoad(false)
                    swal({
                      text: `${MSGContent.story_approved_msg}`,
                      icon: 'success',
                      button: 'okay'
                      // dangerMode: true,
                    }).then(value => {
                      if (value !== '') {
                        resetStateCreatedState()
                      }
                    })
                    // resetStateCreatedState()
                  }
                })
              )
            })
          )
        })
      }
      await demoInsert(data)
    }
  }
  const getDataFromHeaders = () => { }
  return (
    <div>
      <UpsquadHeader
        sendDataToCreateStories={getDataFromHeaders}
        sendDataToChild={'createStories'}
      />
      {isLoading ? (
        <div className='playtStories loading' />
      ) : (
        <div>
          <div className='createStoriesPage'>
            <div className='pb-4'>
              <span
                className='backArrowButton'
                onClick={e => {
                  moveBackToCreateStoryScreen(e)
                }}
              >
                <i className='bi bi-arrow-left'></i>
              </span>
            </div>
            <div className=' ourStoryCreation'>
              <div className='create-story-left'>
                <div>
                  <p className='mb-0'>{staticContent.createstories_title_cnt}</p>
                  <small className='vedioLength' >{`${staticContent.createstories_select_video_hint_msg}`}</small>
                </div>
                <div className=''>
                  <div className='createStory-img mt-5'>
                    <div className='add-img'>
                      <div className='add-story'>
                        <div className='videoContainer'>
                          {
                            uploadedStoryEditStatus ?
                              <div className='editVideoTitle'>
                                <video autoPlay loop id='myVideo' src={uploadedEditStoryURL && uploadedEditStoryURL} />
                                <div>{`${staticContent.createstories_edit_video_hint_msg}`}</div>
                              </div>
                              :
                              (video && (
                                <video autoPlay id='myVideo'>
                                  <source src={URL.createObjectURL(video)} />
                                </video>
                              ))
                          }
                        </div>
                        {uploadedStoryEditStatus ?
                          ""
                          :
                          <input
                            type='file'
                            accept='video/mp4'
                            name='video'
                            // ref={fileInput}
                            onChange={uploadHandleChange}
                            onClick={() => setVideo('')}
                          />
                        }


                        {(video == '' || video == undefined || video == null) && (uploadedEditStoryURL == '' || uploadedEditStoryURL == undefined || uploadedEditStoryURL == null) ? (
                          <div>
                            <i className='bi bi-plus-circle-fill'></i>
                            <span>{staticContent.createstories_add_story_cnt}</span>{' '}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='addStoryContent'>
                  <input
                    type='text'
                    className='form-control'
                    id='exampleFormControlInput1'
                    placeholder={staticContent.createstories_title_placeholder}
                    autoComplete='off'
                    name='title'
                    value={title}
                    // onChange={handleChange}
                    onChange={e => {
                      setTitle(e.target.value)
                    }}
                  />
                  {errors &&
                    (title == '' ||
                      title == undefined ||
                      title.length < 5 ||
                      title.length > 50) ? (
                    <span style={{ color: 'red', fontSize: '12px' }}>
                      {' '}
                      {`${MSGContent.title_error_msg}`}{' '}
                    </span>
                  ) : (
                    ''
                  )}
                  <input
                    type='text'
                    className='form-control'
                    id='exampleFormControlInput1'
                    autoComplete='off'
                    placeholder={staticContent.createstories_description_placeholder}
                    name='description'
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value)
                    }}
                  />
                  {errors &&
                    (description == '' ||
                      description == undefined ||
                      description.length < 5 ||
                      description.length > 100) ? (
                    <span style={{ color: 'red', fontSize: '12px' }}>
                      {' '}
                      {`${MSGContent.description_error_msg}`}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className='create-story-right'>
                <div className='wheel-emotion'>
                  <h6>{staticContent.createstories_emt_tile_line1}</h6>
                  <p className='select-Emotion'>
                    {staticContent.createstories_emt_tile_line2}&nbsp;
                    <span className=''>
                      <a href={AppConstants.EMOTIONS_BROWSE_URL}>
                        {' '}
                        {staticContent.createstories_emt_tile_line3}{' '}
                      </a>
                    </span>
                  </p>
                </div>

                <PlutchikWheel
                  sendEmotionsDataToCreateStories={handleDataFromWheelOfEmotions}
                />
                <div className='publish-Story mt-4'>
                  <div className='publish-Story-cont'>
                    <h6>{staticContent.createstories_anon_label}</h6>
                  </div>
                  <div>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='flexSwitchCheckDefault'
                        name='isAnonymous'
                        checked={isAnonymous}
                        onChange={e => {
                          setIsAnonymous(isAnonymous ? false : true)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='mt-3'>
                  <a className='publish-Story-link'>
                    {' '}
                    <div>
                      <Link
                        onClick={redirectToVisibilitySettings}
                        to='/c3Rvcmllc3NldHRpbmdz'
                      >
                        <span>{staticContent.createstories_visi_stng_link}</span>
                      </Link>
                    </div>
                  </a>
                </div>
                <div className='position-relative'>
                  {load ? (
                    <div className='createStory loading' />
                  ) : (
                    <div>
                      <button
                        type='button'
                        className='btn post-but pull-right'
                        onClick={e => {
                          handleSubmitStoryData(e)
                        }}
                      >
                        {staticContent.createstories_post_btn}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default Createstory
