import React, { useCallback } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import { useHistory, useNavigate } from 'react-router-dom'
import AWS, { Organizations } from 'aws-sdk'
import $ from 'jquery'
import axios from 'axios'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  textInput,
  Dropdown
} from 'reactstrap'
import ReactPlayer from 'react-player'
import Storyemojis from './storyEmojis'
import Storycomments from './storyComments'
import Storyvideos from './storyVideos'
import Sharestory from './shareStory'
import Storymoreicon from './moreOptions'
import { AwsS3GeneratePresignedUrl } from '../utils/awsUtils'
import { seeProfileInformation } from '../utils/userProfile'
import { EmotionButtonsFunctionality } from '../utils/emotionsUtil'
import AppConstants from '../../ApplicationConstants'
import MSGContent from '../helpers/messageContent'
import {
  fetchStories,
  fetchMyStories,
  tapingAStoryToSomeone,
  searchStories,
  getProfileOrganizationsList,
  storeDataObjectData,
  setSearchEmotionsDataInReduxStore,
  resetSearchEmotionsDataInReduxStore,
  clearUploadedEditStoryInReduxStore,
  visibilitySettingsObjectData
} from '../../redux/actions/storiesAction'
import swal from 'sweetalert'
import UpsquadHeader from '../layouts/header'
import PlutchikWheel from './plutchikWheel'
import PlayingFrameFive from '../frames/playingFrameFive'
import PlayingFrameOne from '../frames/playingFrameOne'
import PlayinFrameTwo from '../frames/playinFrameTwo'
import PlayingFrameFour from '../frames/playingFrameFour'
import staticContent from '../helpers/staticContent'
import { FilterAuthorizedStories } from '../utils/filterAuthStories'

// require('dotenv').config()

const Playstory = props => {
  const [reactionsScreen, setReactionScreen] = useState(null)
  const [tapMessage, setTapMessage] = useState('')
  const [toUserEmail, setToUserEmail] = useState('')
  const [playStoriesArr, setPlayStoriesArr] = useState([]) //watching
  const [seenStoriesArr, setSeenStoriesArr] = useState([]) //after watching
  const [showAllAndMyStoriesModal, setShowAllAndMyStoriesModal] = useState(true) // showing which stories are active
  const [isLoading, setIsLoading] = useState(true)
  const [tapSubmitLoading, setTapSubmitLoding] = useState(false)
  const [searchItems, setSearchItems] = useState(false)
  const [noDataMsg, setNoDataMsg] = useState(false)
  const [storyPause, setStoryPause] = useState(false)
  const [storyMuteStatus, setStoryMuteStatus] = useState(false)
  const [storyFullscreenStatus, setStoryFullscreenStatus] = useState(false)
  const [searchStoriesStatus, setSearchStoriesStatus] = useState(false)
  const [wheelOfEmotions, setWheelOfEmotions] = useState([])
  const [feelings, setFeelings] = useState('')
  const [allSearchedContent, setAllSearchedContent] = useState('')
  const [editEmotions, setEditEmotions] = useState('')
  const [tapStoryStatus, SetTapStoryStatus] = useState('')
  const [errors, setErrors] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [tapModalDismiss, setTapModalDismiss] = useState(false)
  const [showSingleStoryPlayer, setShowSingleStoryPlayer] = useState(true) // less than 5
  const [playStoriesMinArray, setPlayStoriesMinArra] = useState([]) //watching
  const [seenStoriesMinArray, setSeenStoriesMinArray] = useState([])
  const [sLimit, setSLimit] = useState(0) // where to start
  const [eLimit, setELimit] = useState(100) //how many stories want
  const [storyStatusState, setStoryStatusState] = useState('approved')
  const [typeOfStories, setTypeOfStories] = useState('allStories') // self hitting status  (allStories or my stories status)
  const [showTypeOfStoriesStatus, setShowTypeOfStoriesStatus] =
    useState('All Stories')
  const [selfHitStories, setSelfHitStories] = useState(false)
  const [childComponentState, setChildComponentState] = useState(false)
  const [childComponentEmojiState, setChildComponentEmojiState] =
    useState(false)
  const [childComponentShareState, setChildComponentShareState] =
    useState(false)
  const [childComponentMoreiconState, setChildComponentMoreiconState] =
    useState(false)

  const navigate = useNavigate()
  const textInputRef = useRef()
  const dispatch = useDispatch()
  const allStories = useSelector(state => state.users.allStories)
  const profileSettingsList = useSelector(
    state => state.users.profileOrganizationsList
  )
  const storyVideosRef = useRef(null)
  const storyVideosRef1 = useRef(null)
  const resetEmojis = useRef(null)
  const awsPendingStories = []

  /**  ********************************************
           Shifting to another community
       ********************************************  */
  const universityIdFromHeader = id => {
    setTypeOfStories('allStories')
    setShowSingleStoryPlayer(true)
    setSelfHitStories(false)
    setSLimit(0)
    setELimit(50)
    setPlayStoriesArr([])
    setSeenStoriesArr([])
    setPlayStoriesMinArra([])
    setSeenStoriesMinArray([])
    setSearchStoriesStatus(false)
    setSearchItems(false)
    setNoDataMsg(false)
    setIsLoading(true)
    let data = {
      sLimit: sLimit,
      eLimit: eLimit,
      status: 'approved'
    }
    dispatch(
      fetchStories(data, res => {
        if (res.data.stories.length !== 0) {
          FilterUnauthorizedStories(res.data.stories, profileSettingsList)
        } else {
          setIsLoading(false)
          setNoDataMsg(true)
        }
      })
    )
  }
  const updateShowTypeOfStoriesStatus = value => {
    setShowTypeOfStoriesStatus(value)
  }

  /**  ********************************************
          Access AWS Objects from AWS S3
       ******************************************** */
  var temStories = []
  const AWSaccessFunctionality = async allStories1 => {
    temStories = JSON.parse(JSON.stringify(allStories1))
    const {
      REACT_APP_ACCESSKEY,
      REACT_APP_SECRETACCESSKEY,
      REACT_APP_AWSS3BUCKET
    } = process.env
    var temp = []
    temStories.map(obj => {
      temp.push(
        new Promise(async (resolve, reject) => {
          const { thumbnail, originalURL, parentBucket } = obj.storyProperties
          let url1 = thumbnail
          let url2 = originalURL
          if (!url1 && url2) {
            resolve()
          } else {
            let key1 = url1.split('.com/')
            let key2 = url2.split('.com/')
            let arrkey = parentBucket.split('/')
            const s3 = new AWS.S3()
            AWS.config.update({
              accessKeyId: REACT_APP_ACCESSKEY,
              secretAccessKey: REACT_APP_SECRETACCESSKEY
            })
            s3.getSignedUrl(
              'getObject',
              {
                Bucket: arrkey[0],
                Expires: 129600,
                Key: key1[1]
              },
              async (err, pic) => {
                if (pic == undefined) {
                  resolve()
                } else {
                  obj['thumb'] = pic
                  resolve()
                }
              }
            )
            s3.getSignedUrl(
              'getObject',
              {
                Bucket: REACT_APP_AWSS3BUCKET,
                Expires: 129600,
                Key: key2[1]
              },
              async (err, pic) => {
                if (pic == undefined) {
                  resolve()
                } else {
                  obj['vid'] = pic
                  resolve()
                }
              }
            )
          }
        })
      )
      Promise.all(temp).then(async () => {
        if (selfHitStories) {
          let totalStories = playStoriesArr.concat(temStories)
          setPlayStoriesArr(totalStories)
          setIsLoading(false)
        } else {
          if (temStories.length == 0) {
            setIsLoading(false)
            setNoDataMsg(true)
          } else if (temStories.length < 5) {
            setPlayStoriesMinArra(temStories)
            setShowSingleStoryPlayer(false)
            setIsLoading(false)
            setNoDataMsg(false)
          } else {
            setPlayStoriesArr(temStories ? temStories : playStoriesArr)
            setIsLoading(false)
            setNoDataMsg(false)
          }
        }
      })
    })
  }

  /**  ********************************************
         Access all stories only
       ********************************************  */
  const getStories = async data => {
    let data1 = {}
    dispatch(getProfileOrganizationsList(data1, res => {}))
    let dataAWS_Pending = {
      sLimit: 0,
      eLimit: eLimit,
      status: 'aws_pending'
    }
    let awsPendingStories = dispatch(
      fetchStories(dataAWS_Pending, async res => {
        awsPendingStories = await res.data.stories
      })
    )
    let payload =
      data == undefined
        ? {
            sLimit: sLimit,
            eLimit: eLimit,
            status: 'approved'
          }
        : data
    let data23 = {}
    let Organizations = []
    dispatch(
      getProfileOrganizationsList(data23, res => {
        Organizations = res
      })
    )
    dispatch(
      fetchStories(payload, res => {
        if (res.data.stories.length == 0) {
          setIsLoading(false)
          setNoDataMsg(true)
        } else {
          let allStories1 = [...awsPendingStories, ...res.data.stories]
          FilterUnauthorizedStories(allStories1, Organizations)
        }
      })
    )
    // }
  }

  useEffect(() => {
    let historyStateObject = {
      title: '',
      description: '',
      video: '',
      feelings: '',
      isAnonymous: '',
      allowEmojis: '',
      allowComments: '',
      allowVideos: '',
      accessTo: '',
      emojis: '',
      comments: '',
      videos: ''
    }
    let clearData = []
    dispatch(clearUploadedEditStoryInReduxStore(clearData))
    dispatch(visibilitySettingsObjectData(clearData))
    dispatch(storeDataObjectData(clearData))

    setTimeout(() => {
      getStories()
    }, 500)
  }, [])

  /**   ********************************************
             Filter unauthorized Stories
        ******************************************* */
  const FilterUnauthorizedStories = async (allStories, profileSettingsList) => {
    let userOrgs = (await profileSettingsList) && profileSettingsList.data
    let loginCommunity = await userOrgs.filter(obj => {
      return localStorage.universityId == obj._id
    })
    if (localStorage.isAdmin == true) {
      let emotionsMutatedArr = await EmotionButtonsFunctionality(allStories)
      AWSaccessFunctionality(emotionsMutatedArr)
    } else {
      let finalAllStorie = await FilterAuthorizedStories(
        allStories,
        loginCommunity
      )
      let emotionsMutatedArr = await EmotionButtonsFunctionality(finalAllStorie)
      AWSaccessFunctionality(emotionsMutatedArr)
    }
  }

  /**  ********************************************
         Stories header functionalities
       ********************************************  */
  const redirectToCreateStory = () => {
    let data = []
    dispatch(resetSearchEmotionsDataInReduxStore(data))
    navigate('/Y3JlYXRlc3Rvcmllcw==')
  }
  const moveToPlayScreen = async () => {
    if (wheelOfEmotions.length > 0 || feelings.length > 0) {
      await handleSubmitSearchItems()
    } else {
      let data = []
      dispatch(resetSearchEmotionsDataInReduxStore(data))
      setShowSingleStoryPlayer(true)
      setPlayStoriesArr([])
      setSeenStoriesArr([])
      setPlayStoriesMinArra([])
      setSeenStoriesMinArray([])
      setSearchStoriesStatus(false)
      setSearchItems(false)
      setFeelings('')
      setWheelOfEmotions([])
      setAllSearchedContent([])
      setIsLoading(true)
      getStories()
    }
  }
  //******  Search stories functionality ******
  const handleClickOnSearchStories = () => {
    setPlayStoriesArr([])
    setSeenStoriesArr([])
    setPlayStoriesMinArra([])
    setSeenStoriesMinArray([])
    setShowSingleStoryPlayer(true)
    setSearchStoriesStatus(true)
    setSLimit(0)
    setELimit(100)
    // setFeelings('')
    // setWheelOfEmotions([])
  }
  const handleDataFromWheelOfEmotions = async index1 => {
    // console.log(textInputRef.current, 'textInputRef');
    textInputRef.current.focus()
    // console.error(textInputRef.current)
    setWheelOfEmotions(index1)
  }
  $(function ($) {
    var input = $('#myInput')
    input.on('keydown', function (event) {
      let key = event.code || event.code
      if (key == 'Backspace' && feelings == '') {
        let ArrLastValue = wheelOfEmotions.at(-1)
        setEditEmotions(ArrLastValue)
        if (wheelOfEmotions.includes(ArrLastValue)) {
          setWheelOfEmotions(
            wheelOfEmotions.filter(obj => {
              return obj !== ArrLastValue
            })
          )
        }
      }
    })
  })
  const onSearchKey = e => {
    let text = e.target.value.trim().toString()
    setFeelings(text)
    if (!e.target.value) {
      setSearchItems(false)
      getStories()
    }
  }
  const removeEmotionWheelSearchItems = feel => {
    setEditEmotions(feel)
    if (wheelOfEmotions.includes(feel)) {
      setWheelOfEmotions(
        wheelOfEmotions.filter(obj => {
          return obj == feel
        })
      )
    }
  }
  const clearAllSearchResults = () => {
    let data = []
    dispatch(resetSearchEmotionsDataInReduxStore(data))
    setFeelings('')
  }
  const handleSubmitSearchItems = e => {
    // e.preventDefault()
    setSLimit(0)
    setIsLoading(true)
    setSearchStoriesStatus(false)
    let searchContentByEmo = wheelOfEmotions.toString().replace(/,/g, ' ')
    let searchedContent = searchContentByEmo.concat(' ')
    let finalString = searchedContent.concat(feelings)
    setAllSearchedContent(finalString)
    if (!wheelOfEmotions.length > 0 && !feelings.trim().length > 0) {
      swal({
        text: `${MSGContent.search_alert_msg}`,
        icon: 'warning',
        button: {
          text: 'ok',
          closeModal: true
        },
        dangerMode: true
      })
      setSearchStoriesStatus(true)
      setIsLoading(false)
      return
    } else {
      dispatch(setSearchEmotionsDataInReduxStore(wheelOfEmotions))
      let data1 = {}
      dispatch(getProfileOrganizationsList(data1, res => {}))
      let emoString = wheelOfEmotions.toString().replace(/,/g, ' ')
      let data = {
        feelings:
          emoString.length > 0 && feelings.length > 0
            ? feelings.trim().concat(' ', emoString.trim())
            : feelings.trim().length > 0
            ? feelings.trim()
            : emoString.trim(),
        sLimit: 0,
        eLimit: eLimit,
        storyStatus: 'approved'
      }
      dispatch(
        searchStories(data, res => {
          if (res.data.story.length > 0) {
            setSearchItems(false)
            FilterUnauthorizedStories(res.data.story, profileSettingsList)
            setSearchStoriesStatus(false)
            // setFeelings('')
            // setWheelOfEmotions([])
          } else {
            setSearchItems(true)
            setIsLoading(false)
            setSearchStoriesStatus(false)
            // setFeelings('')
            // setWheelOfEmotions([])
          }
        })
      )
      setEditEmotions('')
      // setFeelings('')
      // setWheelOfEmotions([])
    }
  }

  /** TAP story functionality */
  const handleChangeTapEmail = e => {
    setToUserEmail(e.target.value.trim())
    let mail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      toUserEmail
    )
    if (mail == false || toUserEmail == '' || toUserEmail == undefined) {
      setEmailError(true)
    } else {
      setEmailError(false)
    }
  }
  const handleChangeTapMessage = e => {
    setTapMessage(e.target.value)
    if (tapMessage.trim().length > 1 && tapMessage.trim().length < 5) {
      setErrors(true)
    } else {
      setErrors(false)
    }
  }
  const tapingStoryStatus = value => {
    setErrors(false)
    setEmailError(false)
    SetTapStoryStatus(value)
    setTapModalDismiss(false)
    setTapMessage('')
    setToUserEmail('')
  }
  const tapingAStorySubmission = async e => {
    e.preventDefault()
    let userId = localStorage.getItem('userId')
    let universityId = localStorage.getItem('universityId')
    let tapStoryId = showSingleStoryPlayer
      ? playStoriesArr[2]._id
      : playStoriesMinArray[0]._id
    if (tapStoryStatus == 'feed') {
      if (!tapMessage.trim() || tapMessage.trim().length < 5 || !toUserEmail) {
        setErrors(true)
        return false
      } else {
        setTapSubmitLoding(true)
        let data = {
          userId: userId,
          storyId: tapStoryId,
          content: tapMessage,
          universityId: universityId

          // "universityId": universityId,
          // "receiverEmail": toUserEmail,
          // "senderEmail": localStorage.email,
          // "message": tapMessage,
        }
        dispatch(
          tapingAStoryToSomeone(data, response => {
            // $(function ($) {
            //   $('#exampleModal-tap').on('hidden.bs.modal', function (e) {
            //     $('#exampleModal-tap').removeClass('modal-open');
            //   })
            // });
            // setTapModalDismiss(true)
            setTapMessage('')
            setToUserEmail('')
            setErrors(false)
            if (response.status == 'success') {
              swal({
                text: `${MSGContent.tap_success_msg}`,
                icon: 'success'
              })
              setTapSubmitLoding(false)
            } else {
              setTapSubmitLoding(false)
            }
          })
        )
      }
    } else {
      if (!tapMessage.trim() || tapMessage.trim().length < 5) {
        setErrors(true)
        return false
      } else {
        setTapSubmitLoding(true)
        let data = {
          // "universityId": universityId,
          // "receiverEmail": toUserEmail,
          // "senderEmail": localStorage.email,
          // "message": tapMessage,

          userId: userId,
          storyId: tapStoryId,
          content: tapMessage,
          universityId: universityId
        }
        dispatch(
          tapingAStoryToSomeone(data, response => {
            // setTapModalDismiss(true)
            setTapMessage('')
            setErrors(false)
            if (response) {
              swal({
                text: `${MSGContent.tap_success_msg}`,
                icon: 'success'
              })
              setTapSubmitLoding(false)
            } else {
              setTapSubmitLoding(false)
            }
          })
        )
      }
    }
  }
  //****** Type of stories selection functionality ******
  const getAllStories = async (value, status) => {
    let data3 = []
    dispatch(resetSearchEmotionsDataInReduxStore(data3))
    setShowTypeOfStoriesStatus('All Stories')
    setTypeOfStories('allStories')
    setStoryStatusState(status)
    setShowAllAndMyStoriesModal(value) // showing which stories are active
    setShowSingleStoryPlayer(true)
    setSelfHitStories(false)
    setSLimit(0)
    setELimit(50)
    setPlayStoriesArr([])
    setSeenStoriesArr([])
    setPlayStoriesMinArra([])
    setSeenStoriesMinArray([])
    setFeelings('')
    setWheelOfEmotions([])
    setAllSearchedContent([])
    setSearchStoriesStatus(false)
    setSearchItems(false)
    let data = {
      sLimit: 0,
      eLimit: eLimit,
      status: status
    }
    setNoDataMsg(false)
    setIsLoading(true)
    getStories(data)
  }

  const getMyStories = async (value, status) => {
    let data3 = []
    dispatch(resetSearchEmotionsDataInReduxStore(data3))
    if (value == 2) {
      setShowTypeOfStoriesStatus('My Stories')
    } else if (value == 3) {
      setShowTypeOfStoriesStatus('My Pending Stories')
    } else {
      setShowTypeOfStoriesStatus('My Rejected Stories')
    }
    setTypeOfStories('myStories')
    setStoryStatusState(status)
    setSelfHitStories(false)
    setNoDataMsg(false)
    setSLimit(0)
    setELimit(50)
    setShowAllAndMyStoriesModal(value) // showing which stories are active
    setShowSingleStoryPlayer(true)
    setPlayStoriesArr([])
    setSeenStoriesArr([])
    setPlayStoriesMinArra([])
    setSeenStoriesMinArray([])
    setFeelings('')
    setWheelOfEmotions([])
    setAllSearchedContent([])
    setSearchStoriesStatus(false)
    setSearchItems(false)
    setIsLoading(true)
    let data = {
      sLimit: 0,
      eLimit: eLimit,
      status: status
    }

    let dataAWS_Pending = {
      sLimit: 0,
      eLimit: eLimit,
      status: 'aws_pending'
    }
    let pendingStories = []
    dispatch(
      fetchMyStories(dataAWS_Pending, async res => {
        pendingStories = await res.data.stories
      })
    )
    dispatch(
      fetchMyStories(data, async res => {
        if (res.data.stories.length > 0 || pendingStories.length > 0) {
          let totalStories = [...pendingStories, ...res.data.stories]
          let emotionsMutatedArr = await EmotionButtonsFunctionality(
            totalStories
          )
          AWSaccessFunctionality(emotionsMutatedArr)
        } else {
          setIsLoading(false)
          setNoDataMsg(true)
        }
      })
    )
  }
  //****** Type of stories selection functionality ******

  /**  ***************************************************** 
        Stories scrolling and swapping functionalities
       ***************************************************** 
       *********  stories scroll functionality  *********  */
  const rigthMoveDirection = async key => {
    setChildComponentState(false)
    setChildComponentEmojiState(false)
    setChildComponentShareState(false)
    setChildComponentMoreiconState(false)
    if (showSingleStoryPlayer) {
      let lastObj = seenStoriesArr.at(-1)
      setSeenStoriesArr(seenStoriesArr.slice(0, seenStoriesArr.length - 1))
      playStoriesArr.splice(0, 0, lastObj)
    } else {
      let lastObj = seenStoriesMinArray.at(-1)
      setSeenStoriesMinArray(
        seenStoriesMinArray.slice(0, seenStoriesMinArray.length - 1)
      )
      playStoriesMinArray.splice(0, 0, lastObj)
    }
  }
  const leftMoveDirection = async key => {
    setChildComponentState(false)
    setChildComponentEmojiState(false)
    setChildComponentShareState(false)
    setChildComponentMoreiconState(false)
    if (showSingleStoryPlayer) {
      setSeenStoriesArr(preState => {
        return [
          ...preState,
          JSON.parse(JSON.stringify(...playStoriesArr.slice(0, 1)))
        ]
      })
      setPlayStoriesArr(playStoriesArr.slice(1))
      if (playStoriesArr.length == 8) {
        let latestSLimit = sLimit + 10
        setSLimit(latestSLimit)
        let data = {
          sLimit: latestSLimit, // where to start
          eLimit: eLimit, //how many stories want
          status: storyStatusState
        }
        if (typeOfStories == 'allStories') {
          dispatch(
            fetchStories(data, res => {
              if (res.data.stories.length > 0) {
                setSelfHitStories(true)
                FilterUnauthorizedStories(res.data.story, profileSettingsList)
              }
            })
          )
        } else {
          dispatch(
            fetchMyStories(data, res => {
              if (res.data.stories.length > 0) {
                setSelfHitStories(true)
                FilterUnauthorizedStories(res.data.story, profileSettingsList)
              }
            })
          )
        }
      }
    } else {
      setSeenStoriesMinArray(preState => {
        return [
          ...preState,
          JSON.parse(JSON.stringify(...playStoriesMinArray.slice(0, 1)))
        ]
      })
      setPlayStoriesMinArra(playStoriesMinArray.slice(1))
    }
  }
  //  *********  stories swap functionality  *********
  const playRandomSelectedStoryByUser = (todo, delta) => {
    setChildComponentState(false)
    setChildComponentEmojiState(false)
    setChildComponentShareState(false)
    setChildComponentMoreiconState(false)
    const newCounts = [...playStoriesArr]
    const currentIndex = newCounts.indexOf(todo)
    newCounts.splice(currentIndex, 1)
    newCounts.splice(2, 0, todo)
    setPlayStoriesArr(newCounts)
  }
  //  *********  Remove remoported story   *********
  const removeReportedStoryFromPlayList = async storyId => {
    let remArr = await playStoriesArr.filter(obj => {
      return obj._id !== storyId
    })
    setPlayStoriesArr(remArr)
  }

  /**  ***************************************************** 
            React-Player functionalities
       *****************************************************   */
  const changeToFullScreen = () => {
    setStoryFullscreenStatus(true)
  }
  const closeFullScreenModal = () => {
    setStoryFullscreenStatus(false)
  }
  const pauseStoryPlaying = () => {
    setStoryPause(false)
  }
  const storyPlayingHandler = () => {
    setStoryPause(!storyPause)
  }
  const storyMuteStatusHandler = () => {
    setStoryMuteStatus(!storyMuteStatus)
  }

  /**  ***************************************************** 
             Stories bottom bar (reactions)
          ***************************************************** 
          *********  stories reactions functionality  *********  */
  const showReactionsScreen = val => {
    setChildComponentState(false)
    setChildComponentEmojiState(false)
    setChildComponentShareState(false)
    setChildComponentMoreiconState(false)
    if (reactionsScreen == val) {
      setReactionScreen(null)
    } else {
      setReactionScreen(val)
    }
  }
  useEffect(() => {
    $(document).mouseup(function (e) {
      var container = $('.video-comment')
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        if (reactionsScreen == 3) {
          setReactionScreen(null)
        }
      }
    })
  }, [reactionsScreen])

  const moveToProfileDetails = id => {
    seeProfileInformation(id)
  }

  const handleParentClick = event => {
    // event.preventDefault()
    if (event.target === event.currentTarget) {
      setChildComponentState(false)
      setChildComponentEmojiState(false)
      setChildComponentShareState(false)
      setChildComponentMoreiconState(false)
    }
  }

  const incrementChildEmojiState = number => {
    setChildComponentEmojiState(number)
    setChildComponentState(false)
    setChildComponentShareState(false)
    setChildComponentMoreiconState(false)
  }
  const memoizedCallbackForEmojis = useCallback(
    state => incrementChildEmojiState(state),
    []
  )

  const incrementChildCommentState = number => {
    setChildComponentState(number)
    setChildComponentEmojiState(false)
    setChildComponentShareState(false)
    setChildComponentMoreiconState(false)
  }
  const memoizedCallback = useCallback(
    state => incrementChildCommentState(state),
    []
  )

  const incrementChildShareState = number => {
    setChildComponentShareState(number)
    setChildComponentState(false)
    setChildComponentEmojiState(false)
    setChildComponentMoreiconState(false)
  }
  const memoizedCallbackForShare = useCallback(
    state => incrementChildShareState(state),
    []
  )

  const incrementChildMoreiconState = number => {
    setChildComponentMoreiconState(number)
    setChildComponentState(false)
    setChildComponentEmojiState(false)
    setChildComponentShareState(false)
  }
  const memoizedCallbackForMoreicon = useCallback(
    state => incrementChildMoreiconState(state),
    []
  )

  return (
    <div>
      <div className='upsquardStoriesPage'>
        <UpsquadHeader
          sendDataToPlayStories={universityIdFromHeader}
          sendDataToPlayStoriesStoriesStatus={updateShowTypeOfStoriesStatus}
        />
        {/* <div >
        Parent element
        </div> */}
        {searchStoriesStatus ? (
          <div>
            <div className='d-flex gap-lg-3 gap-md-3 gap-3 p-4 justify-content-center align-items-center emofltmedia'>
              <span className='backToStories' onClick={moveToPlayScreen}>
                <i className='bi bi-arrow-left'></i>
              </span>
              <div className='emotionsFilters'>
                <form onSubmit={handleSubmitSearchItems} id='myInput'>
                  <div
                    className={
                      (wheelOfEmotions && wheelOfEmotions.length > 0) ||
                      searchItems.length > 0
                        ? 'serachFeelingBut'
                        : ''
                    }
                  >
                    {wheelOfEmotions.length > 0 &&
                      wheelOfEmotions.map((name, index) => {
                        return (
                          <button type='button' className='btn' key={index}>
                            {name}{' '}
                            <span
                              className='emotionsBut'
                              onClick={() =>
                                removeEmotionWheelSearchItems(name)
                              }
                            >
                              {'X'}
                            </span>
                          </button>
                        )
                      })}
                    <div />
                  </div>
                  <input
                    className={
                      (wheelOfEmotions && wheelOfEmotions.length > 0) ||
                      searchItems.length > 0
                        ? 'wheelSearchData form-control '
                        : 'form-control wheelSearch'
                    }
                    ref={ref => {
                      textInputRef.current = ref
                    }}
                    type='text'
                    placeholder='Search by text...'
                    aria-label='Search'
                    onChange={e => onSearchKey(e)}
                    id='myInput'
                    autoComplete='off'
                    autofocus='true'
                    value={feelings}
                  />
                </form>
                <div className='emotionsSearch'>
                  <i
                    className='bi bi-search '
                    onClick={handleSubmitSearchItems}
                  ></i>
                </div>
              </div>

              <button
                className='searchAllStories'
                onClick={handleSubmitSearchItems}
              >
                <span>
                  <i className='bi bi-search '></i>
                  {staticContent.playstories_search_button_cnt}
                </span>
              </button>
              <button className='backToStories' onClick={clearAllSearchResults}>
                <span>{staticContent.playstories_clear_all_button_cnt}</span>
              </button>
            </div>
          </div>
        ) : (
          <div className='bg-every-one'>
            <div className='container'>
              <div className='everyOneStory'>
                <div className='whatsYours'>
                  <h4>
                    {staticContent.playstories_header_line1}{' '}
                    <span
                      onClick={() => {
                        redirectToCreateStory()
                      }}
                    >
                      <span className='pe-1'>
                        {' '}
                        {staticContent.playstories_header_line2}{' '}
                      </span>
                      <button className='btn storyBtn'>
                        <span className='plusIconBtn'>+</span>{' '}
                        {staticContent.playstories_create_story_btn}
                      </button>
                    </span>{' '}
                  </h4>
                </div>
                <div className='searchEmotion'>
                  <span>
                    <i className='bi bi-search'></i>
                  </span>
                  <input
                    className='form-control'
                    type='search'
                    placeholder='Search by text or emotion'
                    name='feelings'
                    value={searchStoriesStatus ? '' : allSearchedContent}
                    onClick={e => handleClickOnSearchStories()}
                    aria-label='Search'
                  />
                </div>
                <div className='filerTap'>
                  <div className='filterUsage'>
                    <div className='filterIcon'>
                      {/* <Dropdown > */}
                      <UncontrolledDropdown>
                        <DropdownToggle caret isOpen={true}>
                          {showTypeOfStoriesStatus}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            on
                            onClick={() => {
                              getAllStories(1, 'approved')
                            }}
                            className='allStories'
                          >
                            {staticContent.playstories_all_stories_drop_cnt}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              getMyStories(2, 'approved')
                            }}
                          >
                            {staticContent.playstories_my_stories_drop_cnt}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              getMyStories(3, 'uploaded')
                            }}
                          >
                            {staticContent.playstories_pending_stories_drop_cnt}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              getMyStories(4, 'rejected')
                            }}
                          >
                            {staticContent.playstories_rejected_drop_cnt}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <div className='qmarkIcon'>
                      <div
                        data-bs-toggle='modal'
                        data-bs-target='#exampleModal-tips'
                      >
                        <img
                          src='../../assets/images/qmark-icon.svg'
                          alt=''
                          width='100%'
                          height='100%'
                        />
                      </div>
                      <div
                        className='modal fade'
                        id='exampleModal-tips'
                        tabIndex='-1'
                        aria-labelledby='exampleModalLabel'
                        aria-hidden='true'
                      >
                        <div className='modal-dialog modal-dialog-centered justify-content-center'>
                          <div className='modal-content'>
                            <div className='modal-header'>
                              <h5
                                className='modal-title'
                                id='exampleModalLabel'
                              ></h5>
                              <button
                                type='button'
                                className='btn-close'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                              ></button>
                            </div>
                            <div className='modal-body'>
                              <div className='cont'>
                                <h6>
                                  {staticContent.playstories_house_rules_line1}
                                </h6>
                                <p>
                                  {staticContent.playstories_house_rules_line2}{' '}
                                </p>
                                <p>
                                  {staticContent.playstories_house_rules_line3}
                                </p>
                                <p>
                                  {staticContent.playstories_house_rules_line4}{' '}
                                </p>
                                <p>
                                  {' '}
                                  {staticContent.playstories_house_rules_line5}
                                </p>
                                <p>
                                  {' '}
                                  {
                                    staticContent.playstories_house_rules_line6
                                  }{' '}
                                </p>
                                <p>
                                  {staticContent.playstories_house_rules_line7}{' '}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tapIcon'>
                    {/* <div data-bs-toggle='modal' data-bs-target='#exampleModal-tap' onClick={() => tapingStoryStatus("feed")}>
                        <img
                          src='../../assets/images/user-icon-latest.svg'
                          alt=''
                          width='100%'
                          height='100%'
                        />
                        <span>Tap</span>
                      </div> */}
                    {/* <form onSubmit={tapingAStorySubmission}> */}
                    <div
                      className={
                        tapModalDismiss ? 'modal fade' : 'modal fade show'
                      }
                      id='exampleModal-tap'
                      tabIndex='-1'
                      aria-labelledby='exampleModalLabel'
                      aria-hidden='true'
                      style={
                        tapModalDismiss
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <div className='modal-dialog modal-dialog-centered justify-content-center'>
                        <div
                          className={
                            tapStoryStatus == 'feed'
                              ? 'modal-content'
                              : 'modal-content anotherStory-modal'
                          }
                        >
                          <div className='modal-header'>
                            <h5
                              className='modal-title'
                              id='exampleModalLabel1'
                            ></h5>
                            <button
                              type='button'
                              className='btn-close'
                              data-bs-dismiss='modal'
                              aria-label='Close'
                              // aria-hidden={tapModalDismiss ? true : false}
                              // aria-modal={tapModalDismiss ? false : true}
                              // role={tapModalDismiss ? "" : 'dialog'}
                            ></button>
                          </div>
                          <div className='modal-body'>
                            <div>
                              {tapStoryStatus == 'feed' ? (
                                <p>{staticContent.tap_title_line1}</p>
                              ) : (
                                <div>
                                  <p>
                                    {staticContent.tap_title_line2} <br />
                                    {staticContent.tap_title_line3}
                                  </p>
                                </div>
                              )}
                            </div>
                            {/* {
                                tapStoryStatus == "feed" ? */}
                            {/* <div className='addStoryContent'>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='exampleFormControlInput1'
                                  name='toUserEmail'
                                  autoComplete='off'
                                  value={toUserEmail}
                                  // ref={textInput}
                                  onChange={handleChangeTapEmail}
                                  placeholder='Their email address'
                                />
                                {emailError ? <span style={{ color: "red", fontSize: "12px" }}> {staticContent.tap_email_hint_error_msg} </span> : ""}
                              </div> */}
                            {/* : "" */}
                            {/* } */}
                            <div className='addStoryContent'>
                              <input
                                type='text'
                                className='form-control'
                                id='exampleFormControlInput1'
                                name='tapMessage'
                                autoComplete='off'
                                // ref={textInput}
                                onChange={handleChangeTapMessage}
                                value={tapMessage}
                                placeholder='Your message'
                              />
                              <div class='form-text hintText'>
                                {staticContent.tap_message_error_hint_msg}
                              </div>
                              {/* {errors && (tapMessage.trim() == "" || tapMessage.trim().length < 5) ? <span style={{ color: "red", fontSize: "12px" }}> {staticContent.tap_message_error_hint_msg}  </span> : ""} */}
                            </div>
                          </div>
                          <div className='modal-footer pt-3 pb-3'>
                            {tapSubmitLoading ? (
                              <div
                                className={
                                  tapStoryStatus == 'feed'
                                    ? 'feedTapSend loading'
                                    : 'tapSend loading'
                                }
                              />
                            ) : tapStoryStatus && tapStoryStatus == 'feed' ? (
                              // !errors &&
                              tapMessage.length > 4 ? (
                                //  && toUserEmail.length > 5
                                <button
                                  type='button'
                                  className='btn btn-primary tap-but'
                                  data-bs-dismiss='modal'
                                  onClick={e => {
                                    tapingAStorySubmission(e)
                                  }}
                                >
                                  {staticContent.tap_btn_cnt}
                                </button>
                              ) : (
                                ''
                              )
                            ) : // !emailError && !errors &&
                            tapMessage.trim().length > 4 ? (
                              //  && toUserEmail.length > 5
                              <button
                                type='button'
                                className='btn btn-primary tap-but'
                                data-bs-dismiss='modal'
                                onClick={e => {
                                  tapingAStorySubmission(e)
                                }}
                              >
                                {staticContent.tap_btn_cnt}
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* stories header  */}
        {searchStoriesStatus ? (
          <div className='searchStoriesByEmotions mt-2'>
            <p className='text-center'>
              {staticContent.playstories_serach_hint_line1}{' '}
              <span className='searchStoryHeading'>
                <a href={AppConstants.EMOTIONS_BROWSE_URL}>
                  {staticContent.playstories_serach_hint_line2}
                </a>
              </span>
            </p>
            <div className='searchStoriesByWheel'>
              <PlutchikWheel
                onUseEffect={() => {
                  textInputRef.current.focus()
                }}
                sendEmotionsDataToPlayStories={handleDataFromWheelOfEmotions}
                sendDataToChild={searchStoriesStatus}
                sendDataToChildToEditEmotion={editEmotions}
              />
            </div>
          </div>
        ) : isLoading ? (
          <div className='playtStories loading' />
        ) : (
          <div>
            {noDataMsg ? (
              <div className='all-stories-msg'>
                <h6>{staticContent.playstories_no_stories_cnt}</h6>
              </div>
            ) : (
              <div>
                {searchItems ? (
                  <div className='empty-stories-msg'>
                    {staticContent.playstories_no_match_cnt}
                  </div>
                ) : (
                  <div
                    className={
                      storyFullscreenStatus
                        ? 'bg-video-gallery fullScreenVedioDisplay'
                        : 'bg-video-gallery '
                    }
                  >
                    <div className='gallery'>
                      <div
                        className='gallery-container'
                        onClick={handleParentClick}
                      >
                        {showSingleStoryPlayer ? (
                          <>
                            <PlayingFrameOne
                              playStoriesArr={playStoriesArr && playStoriesArr}
                              swapObj={playRandomSelectedStoryByUser}
                              moveToProfileDetails={moveToProfileDetails}
                            />
                            <PlayinFrameTwo
                              playStoriesArr={playStoriesArr && playStoriesArr}
                              swapObj={playRandomSelectedStoryByUser}
                              moveToProfileDetails={moveToProfileDetails}
                            />
                            {seenStoriesArr && seenStoriesArr.length > 0 ? (
                              <div
                                className='biIconLeft'
                                onClick={() => rigthMoveDirection('next')}
                              >
                                <span>
                                  {/* <i className='bi bi-chevron-left'></i> */}
                                  <i
                                    class='fa fa-chevron-left'
                                    aria-hidden='true'
                                  ></i>
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                            <div
                              className={
                                storyFullscreenStatus
                                  ? 'gallery-item gallery-item-3 fullScreenVedioDisplay2'
                                  : 'gallery-item gallery-item-3'
                              }
                              data-index='3'
                            >
                              <span
                                className='crossMark'
                                onClick={() => closeFullScreenModal()}
                              >
                                X
                              </span>
                              <div
                                className={
                                  playStoriesArr[2] &&
                                  playStoriesArr[2].isAnonymous
                                    ? 'card border-0 text-white anonymousImgHgt'
                                    : 'card border-0 text-white'
                                }
                              >
                                <div>
                                  {playStoriesArr[2] &&
                                  playStoriesArr[2].isAnonymous ? (
                                    <div className='anonymousImg'>
                                      <img
                                        className='front-img'
                                        src='../../assets/images/Anonymous3.jpg'
                                        width='100%'
                                        height='100%'
                                      />
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div id='video-viewport'>
                                    <ReactPlayer
                                      url={
                                        playStoriesArr[2] &&
                                        playStoriesArr[2].vid
                                      }
                                      width='100%'
                                      height='100%'
                                      config={{
                                        file: {
                                          attributes: {
                                            disablepictureinpicture: 'true' ,
                                            controlsList: 'nodownload noplaybackrate'
                                          }
                                        }
                                      }}
                                      pip={true}
                                      pictureIn
                                      // controlsList="nodownload"
                                      controls={true}
                                      playing={storyPause}
                                      muted={storyMuteStatus}
                                      loop={true}
                                    ></ReactPlayer>
                                  </div>
                                </div>
                                <div className='rejmute'>
                                  <div className='d-flex align-items-center rejmutebg justify-content-end'>
                                    <div className='recjectionVideos'>
                                      {playStoriesArr[2] &&
                                      playStoriesArr[2].storyStatus ==
                                        'rejected' ? (
                                        <p className='rejectedContent mb-0'>
                                          {
                                            staticContent.playstories_reject_cmt_cnt
                                          }{' '}
                                          {
                                            playStoriesArr[2].history[2]
                                              .comments
                                          }
                                        </p>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                    <div
                                      onClick={storyMuteStatusHandler}
                                      aria-hidden='true'
                                      className='hoverEffectButtonMute'
                                    >
                                      <img
                                        src={
                                          storyMuteStatus
                                            ? '../../assets/images/unmute-icon.svg'
                                            : '../../assets/images/mute-icon.svg'
                                        }
                                        width='100%'
                                        height='100%'
                                        alt='overlay'
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className='playingBtn '
                                    onClick={storyPlayingHandler}
                                  >
                                    <div className='play-btn'>
                                      <img
                                        src={
                                          storyPause
                                            ? '../../assets/images/pause-circle.svg'
                                            : '../../assets/images/play.svg'
                                        }
                                        width='100%'
                                        height='100%'
                                        alt='overlay'
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className='card-img-overlay1'>
                                  <div className='card-cont'>
                                    {/* 
                                        <div className="play-btn" onClick={storyPlayingHandler}>
                                          <div className="playWithBg"></div>
                                          <div
                                            aria-hidden="true"
                                            className="hoverEffectButton"
                                          >
                                            <img
                                              src={
                                                storyPause
                                                  ? "../../assets/images/pause-circle.svg"
                                                  : "../../assets/images/play.svg"
                                              }
                                              width="100%"
                                              height="100%"
                                              alt="overlay"
                                            />
                                          </div>
                                        </div> */}
                                    <div className='p-3 fullScreenNone'>
                                      <h5 className='card-title'>
                                        {playStoriesArr[2] &&
                                          playStoriesArr[2].title}
                                      </h5>
                                      <p className='card-text'>
                                        {playStoriesArr[2] &&
                                          playStoriesArr[2].description}
                                      </p>
                                      <p className='card-text mb-2'>
                                        {playStoriesArr[2] &&
                                        playStoriesArr[2].isAnonymous ? (
                                          <>
                                            <div className='galleryProfile'>
                                              <a>
                                                <img
                                                  src={
                                                    (playStoriesArr[2] &&
                                                      playStoriesArr[2]
                                                        .isAnonymous) ||
                                                    (playStoriesArr[2] &&
                                                      playStoriesArr[2]
                                                        .userProperties[0]
                                                        .profileImg == null)
                                                      ? 'https://www.careerquo.com/assets/images/18.png'
                                                      : playStoriesArr[2] !==
                                                          undefined &&
                                                        playStoriesArr[2]
                                                          .userProperties[0]
                                                          .profileImg
                                                  }
                                                  width='100%'
                                                  height='100%'
                                                  alt=''
                                                />
                                              </a>
                                            </div>
                                            <a>
                                              <strong>
                                                {playStoriesArr[2] &&
                                                playStoriesArr[2].isAnonymous
                                                  ? 'Anonymous'
                                                  : `${
                                                      playStoriesArr[2] !==
                                                        undefined &&
                                                      playStoriesArr[2]
                                                        .userProperties[0]
                                                        .firstName
                                                    } ${
                                                      playStoriesArr[2] &&
                                                      playStoriesArr[2]
                                                        .userProperties[0]
                                                        .lastName
                                                    }`}
                                              </strong>
                                            </a>
                                          </>
                                        ) : (
                                          <>
                                            <div className='galleryProfile'>
                                              <a
                                                onClick={() =>
                                                  moveToProfileDetails(
                                                    playStoriesArr[2].userId
                                                  )
                                                }
                                              >
                                                <img
                                                  src={
                                                    (playStoriesArr[2] &&
                                                      playStoriesArr[2]
                                                        .isAnonymous) ||
                                                    (playStoriesArr[2] &&
                                                      playStoriesArr[2]
                                                        .userProperties[0]
                                                        .profileImg == null)
                                                      ? 'https://www.careerquo.com/assets/images/18.png'
                                                      : playStoriesArr[2] !==
                                                          undefined &&
                                                        playStoriesArr[2]
                                                          .userProperties[0]
                                                          .profileImg
                                                  }
                                                  width='100%'
                                                  height='100%'
                                                  alt=''
                                                />
                                              </a>
                                            </div>
                                            <a
                                              onClick={() =>
                                                moveToProfileDetails(
                                                  playStoriesArr[2].userId
                                                )
                                              }
                                            >
                                              <strong>
                                                {playStoriesArr[2] &&
                                                playStoriesArr[2].isAnonymous
                                                  ? 'Anonymous'
                                                  : `${
                                                      playStoriesArr[2] !==
                                                        undefined &&
                                                      playStoriesArr[2]
                                                        .userProperties[0]
                                                        .firstName
                                                    } ${
                                                      playStoriesArr[2] &&
                                                      playStoriesArr[2]
                                                        .userProperties[0]
                                                        .lastName
                                                    }`}
                                              </strong>
                                            </a>
                                          </>
                                        )}
                                      </p>
                                      <div className='reactedEmotions mb-2'>
                                        {playStoriesArr[2] &&
                                          playStoriesArr[2].emotionTags.map(
                                            (obj, index) => {
                                              return (
                                                <h6
                                                  className={
                                                    obj.emotionBtnBgColor
                                                  }
                                                >
                                                  {obj.emotion}
                                                </h6>
                                              )
                                            }
                                          )}
                                      </div>
                                      <div className='telle-me-tap'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                          <div className='d-flex justify-content-center comment-tell-tap '>
                                            {(playStoriesArr[2] &&
                                              playStoriesArr[2].isAnonymous) ||
                                            (playStoriesArr[2] &&
                                              playStoriesArr[2].userId ==
                                                localStorage.userId) ? (
                                              ''
                                            ) : (
                                              <a
                                                href={
                                                  AppConstants.MESSAGE_DEV_URL +
                                                  `/?` +
                                                  localStorage.userId +
                                                  `&` +
                                                  localStorage.accessToken +
                                                  `&` +
                                                  localStorage.isAdmin +
                                                  `&` +
                                                  localStorage.universityId +
                                                  `&` +
                                                  localStorage.isUpsquadStatus +
                                                  `&` +
                                                  localStorage.refreshToken
                                                }
                                                className='btn btn-primary tellMe'
                                              >
                                                <div className='chat-icon-svg'>
                                                  <img
                                                    src='../../assets/images/chat-icon.svg'
                                                    alt=''
                                                    width='90%'
                                                    height='90%'
                                                  />
                                                </div>
                                                {
                                                  staticContent.playstories_tell_me_more_btn
                                                }
                                              </a>
                                            )}
                                            {playStoriesArr[2] &&
                                            playStoriesArr[2].userId ==
                                              localStorage.userId ? (
                                              ''
                                            ) : (
                                              <div
                                                className='filerTap tapIcon icon-tap'
                                                data-bs-toggle='modal'
                                                data-bs-target='#exampleModal-tap'
                                                onClick={() =>
                                                  tapingStoryStatus('story')
                                                }
                                              >
                                                <div
                                                  data-bs-toggle='modal'
                                                  data-bs-target='#exampleModal-tap'
                                                >
                                                  <img
                                                    src='../../assets/images/user-icon-latest.svg'
                                                    alt=''
                                                    width='90%'
                                                    height='90%'
                                                  />
                                                  <span>
                                                    {staticContent.tap_btn_cnt}
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          {/* {playStoriesArr[2] &&
                                                playStoriesArr[2].isAnonymous ? (
                                                ""
                                              ) : (
                                                <span
                                                  className=""
                                                  onClick={() => changeToFullScreen()}
                                                >
                                                  <i className="bi bi-fullscreen fullscreen-icon"></i>
                                                </span>
                                              )} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {playStoriesArr && playStoriesArr.length > 5 ? (
                              <div
                                className='biIconRight'
                                onClick={() => leftMoveDirection('prev')}
                              >
                                <span>
                                  {/* <i className='bi bi-chevron-right'></i> */}
                                  <i
                                    class='fa fa-chevron-right'
                                    aria-hidden='true'
                                  ></i>
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                            <PlayingFrameFour
                              playStoriesArr={playStoriesArr && playStoriesArr}
                              swapObj={playRandomSelectedStoryByUser}
                              moveToProfileDetails={moveToProfileDetails}
                            />
                            <PlayingFrameFive
                              playStoriesArr={playStoriesArr && playStoriesArr}
                              swapObj={playRandomSelectedStoryByUser}
                              moveToProfileDetails={moveToProfileDetails}
                            />
                          </>
                        ) : (
                          <div>
                            {/* *****  single stories player  ***** */}
                            {seenStoriesMinArray &&
                            seenStoriesMinArray.length > 0 ? (
                              <div
                                className='biIconLeft'
                                onClick={() => rigthMoveDirection('next')}
                              >
                                <span>
                                  {/* <i className='bi bi-chevron-left'></i> */}
                                  <i
                                    class='fa fa-chevron-left'
                                    aria-hidden='true'
                                  ></i>
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                            <div
                              className={
                                storyFullscreenStatus
                                  ? 'gallery-item gallery-item-3 fullScreenVedioDisplay2'
                                  : 'gallery-item gallery-item-3'
                              }
                              data-index='3'
                            >
                              <span
                                className='crossMark'
                                onClick={() => closeFullScreenModal()}
                              >
                                X
                              </span>
                              <div
                                className={
                                  playStoriesMinArray[0] &&
                                  playStoriesMinArray[0].isAnonymous
                                    ? 'card border-0 text-white anonymousImgHgt'
                                    : 'card border-0 text-white'
                                }
                              >
                                <div id='video-viewport'>
                                  <ReactPlayer
                                    url={
                                      playStoriesMinArray[0] &&
                                      playStoriesMinArray[0].vid
                                    }
                                    width='100%'
                                    height='100%'
                                    config={{
                                      file: {
                                        attributes: {
                                          controlsList: 'nodownload'
                                        }
                                      }
                                    }}
                                    controls={true}
                                    playing={storyPause}
                                    loop={true}
                                    muted={storyMuteStatus}
                                  ></ReactPlayer>
                                </div>

                                <div className='rejmute'>
                                  <div className='d-flex align-items-center rejmutebg justify-content-end'>
                                    <div className='recjectionVideos'>
                                      {playStoriesMinArray[0] &&
                                      playStoriesMinArray[0].storyStatus ==
                                        'rejected' ? (
                                        <p className='rejectedContent mb-0'>
                                          {
                                            staticContent.playstories_reject_cmt_cnt
                                          }{' '}
                                          {
                                            playStoriesMinArray[0].history[2]
                                              .comments
                                          }
                                        </p>
                                      ) : (
                                        ''
                                      )}

                                      {playStoriesMinArray[0] &&
                                      playStoriesMinArray[0].isAnonymous ? (
                                        <img
                                          className='front-img'
                                          src='../../assets/images/Anonymous3.jpg'
                                          width='100%'
                                          height='100%'
                                        />
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                    <div
                                      onClick={storyMuteStatusHandler}
                                      aria-hidden='true'
                                      className='hoverEffectButtonMute'
                                    >
                                      <img
                                        src={
                                          storyMuteStatus
                                            ? '../../assets/images/unmute-icon.svg'
                                            : '../../assets/images/mute-icon.svg'
                                        }
                                        width='100%'
                                        height='100%'
                                        alt='overlay'
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className='playingBtn '
                                    onClick={storyPlayingHandler}
                                  >
                                    <div className='play-btn'>
                                      <img
                                        src={
                                          storyPause
                                            ? '../../assets/images/pause-circle.svg'
                                            : '../../assets/images/play.svg'
                                        }
                                        width='100%'
                                        height='100%'
                                        alt='overlay'
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className='card-img-overlay1'>
                                  <div className='card-cont'>
                                    <div className='p-3 fullScreenNone'>
                                      <h5 className='card-title'>
                                        {playStoriesMinArray[0] &&
                                          playStoriesMinArray[0].title}
                                      </h5>
                                      <p className='card-text'>
                                        {playStoriesMinArray[0] &&
                                          playStoriesMinArray[0].description}
                                      </p>
                                      <p className='card-text'>
                                        {playStoriesMinArray[0] &&
                                        playStoriesMinArray[0].isAnonymous ? (
                                          <>
                                            <div className='galleryProfile'>
                                              <a>
                                                <img
                                                  src={
                                                    (playStoriesMinArray[0] &&
                                                      playStoriesMinArray[0]
                                                        .isAnonymous) ||
                                                    (playStoriesMinArray[0] &&
                                                      playStoriesMinArray[0]
                                                        .userProperties[0]
                                                        .profileImg == null)
                                                      ? 'https://www.careerquo.com/assets/images/18.png'
                                                      : playStoriesMinArray[0] !==
                                                          undefined &&
                                                        playStoriesMinArray[0]
                                                          .userProperties[0]
                                                          .profileImg
                                                  }
                                                  width='100%'
                                                  height='100%'
                                                  alt=''
                                                />
                                              </a>
                                            </div>
                                            <a>
                                              <strong>
                                                {playStoriesMinArray[0] &&
                                                playStoriesMinArray[0]
                                                  .isAnonymous
                                                  ? 'Anonymous'
                                                  : `${
                                                      playStoriesMinArray[0] !==
                                                        undefined &&
                                                      playStoriesMinArray[0]
                                                        .userProperties[0]
                                                        .firstName
                                                    } ${
                                                      playStoriesMinArray[0] &&
                                                      playStoriesMinArray[0]
                                                        .userProperties[0]
                                                        .lastName
                                                    }`}
                                              </strong>
                                            </a>
                                          </>
                                        ) : (
                                          <>
                                            <div className='galleryProfile'>
                                              <a
                                                onClick={() =>
                                                  moveToProfileDetails(
                                                    playStoriesMinArray[0]
                                                      .userId
                                                  )
                                                }
                                              >
                                                <img
                                                  src={
                                                    (playStoriesMinArray[0] &&
                                                      playStoriesMinArray[0]
                                                        .isAnonymous) ||
                                                    (playStoriesMinArray[0] &&
                                                      playStoriesMinArray[0]
                                                        .userProperties[0]
                                                        .profileImg == null)
                                                      ? 'https://www.careerquo.com/assets/images/18.png'
                                                      : playStoriesMinArray[0] !==
                                                          undefined &&
                                                        playStoriesMinArray[0]
                                                          .userProperties[0]
                                                          .profileImg
                                                  }
                                                  width='100%'
                                                  height='100%'
                                                  alt=''
                                                />
                                              </a>
                                            </div>
                                            <a
                                              onClick={() =>
                                                moveToProfileDetails(
                                                  playStoriesMinArray[0].userId
                                                )
                                              }
                                            >
                                              <strong>
                                                {playStoriesMinArray[0] &&
                                                playStoriesMinArray[0]
                                                  .isAnonymous
                                                  ? 'Anonymous'
                                                  : `${
                                                      playStoriesMinArray[0] !==
                                                        undefined &&
                                                      playStoriesMinArray[0]
                                                        .userProperties[0]
                                                        .firstName
                                                    } ${
                                                      playStoriesMinArray[0] &&
                                                      playStoriesMinArray[0]
                                                        .userProperties[0]
                                                        .lastName
                                                    }`}
                                              </strong>
                                            </a>
                                          </>
                                        )}
                                      </p>
                                      <div className='reactedEmotions mb-2'>
                                        {playStoriesMinArray[0] &&
                                          playStoriesMinArray[0].emotionTags.map(
                                            (obj, index) => {
                                              return (
                                                <h6
                                                  className={
                                                    obj.emotionBtnBgColor
                                                  }
                                                >
                                                  {obj.emotion}
                                                </h6>
                                              )
                                            }
                                          )}
                                      </div>
                                      <div className='telle-me-tap'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                          <div className='d-flex justify-content-between comment-tell-tap'>
                                            {(playStoriesMinArray[0] &&
                                              playStoriesMinArray[0]
                                                .isAnonymous) ||
                                            playStoriesMinArray[0].userId ==
                                              localStorage.userId ? (
                                              ''
                                            ) : (
                                              <a
                                                href={
                                                  AppConstants.MESSAGE_DEV_URL +
                                                  `/?` +
                                                  localStorage.userId +
                                                  `&` +
                                                  localStorage.accessToken +
                                                  `&` +
                                                  localStorage.isAdmin +
                                                  `&` +
                                                  localStorage.universityId +
                                                  `&` +
                                                  localStorage.isUpsquadStatus +
                                                  `&` +
                                                  localStorage.refreshToken
                                                }
                                                // type='button'
                                                className='btn btn-primary tellMe'
                                              >
                                                <div className='chat-icon-svg'>
                                                  <img
                                                    src='../../assets/images/chat-icon.svg'
                                                    alt=''
                                                    width='90%'
                                                    height='90%'
                                                  />
                                                </div>
                                                Tell me more
                                              </a>
                                            )}
                                            {localStorage.userId ==
                                            playStoriesMinArray[0].userId ? (
                                              ''
                                            ) : (
                                              <div
                                                className='filerTap tapIcon icon-tap'
                                                data-bs-toggle='modal'
                                                data-bs-target='#exampleModal-tap'
                                                onClick={() =>
                                                  tapingStoryStatus('story')
                                                }
                                              >
                                                <div
                                                  data-bs-toggle='modal'
                                                  data-bs-target='#exampleModal-tap'
                                                >
                                                  <img
                                                    src='../../assets/images/user-icon-latest.svg'
                                                    alt=''
                                                    width='90%'
                                                    height='90%'
                                                  />
                                                  <span>Tap</span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        {/* {
                                              playStoriesMinArray[0] && playStoriesMinArray[0].isAnonymous ? "" :
                                                <span className='full-screen-icon' onClick={() => changeToFullScreen()}>
                                                  <i className='bi bi-fullscreen fullscreen-icon'></i>
                                                </span>
                                            } */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {playStoriesMinArray &&
                            playStoriesMinArray.length > 1 ? (
                              <div
                                className='biIconRight'
                                onClick={() => leftMoveDirection('prev')}
                              >
                                <span>
                                  {/* <i className='bi bi-chevron-right'></i> */}
                                  <i
                                    class='fa fa-chevron-right'
                                    aria-hidden='true'
                                  ></i>
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                            {/* *****  single stories player  ***** */}
                          </div>
                        )}

                        {/* *****  stories reactions bar  ***** */}
                        {playStoriesArr.length > 0 ||
                        playStoriesMinArray.length > 0 ? (
                          //  && (playStoriesArr[2] && playStoriesArr[2].storyStatus == "approved" || playStoriesMinArray[0] && playStoriesMinArray[0].storyStatus == "approved")
                          <div className='comment-gallery'>
                            <div className='d-flex justify-content-around p-2 CommentDisplay'>
                              <Storyemojis
                                ref={resetEmojis}
                                story={
                                  showSingleStoryPlayer
                                    ? playStoriesArr[2] && playStoriesArr[2]
                                    : playStoriesMinArray[0] &&
                                      playStoriesMinArray[0]
                                }
                                setDropDownStatus={memoizedCallbackForEmojis}
                                dropDownStatus={childComponentEmojiState}
                              />
                              <Storycomments
                                story={
                                  showSingleStoryPlayer
                                    ? playStoriesArr[2] && playStoriesArr[2]
                                    : playStoriesMinArray[0] &&
                                      playStoriesMinArray[0]
                                }
                                dropDownStatus={childComponentState}
                                setDropDownStatus={memoizedCallback}
                              />

                              {
                                // && (playStoriesMinArray[0] && playStoriesMinArray[0].allowVideos == "public" || playStoriesMinArray[0] && playStoriesMinArray[0].userId == localStorage.userId))
                                (playStoriesMinArray[0] &&
                                  playStoriesMinArray[0].allowSharing &&
                                  ['approved', 'aws_pending'].includes(
                                    playStoriesMinArray[0] &&
                                      playStoriesMinArray[0].storyStatus
                                  )) ||
                                (playStoriesArr[2] &&
                                  playStoriesArr[2].responseTypes.includes(
                                    'videos'
                                  ) &&
                                  ['approved', 'aws_pending'].includes(
                                    playStoriesArr[2] &&
                                      playStoriesArr[2].storyStatus
                                  )) ? (
                                  <div className=''>
                                    <button
                                      type='button'
                                      className='border-0 bg-transparent trianglePostion'
                                      onClick={() => showReactionsScreen(3)}
                                    >
                                      <span>
                                        <i className='bi bi-camera-video videoRes'></i>
                                      </span>
                                      {/* <div className="triangle-arrow responseCloseLink3">
                                            <i className="bi bi-triangle-fill"></i>
                                          </div> */}

                                      {reactionsScreen == 3 ? (
                                        <div className='triangle-arrow '>
                                          <i className='bi bi-triangle-fill'></i>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </button>
                                    {reactionsScreen == 3 ? (
                                      // <div className='responseCloseLink3'>
                                      <Storyvideos
                                        ref={storyVideosRef}
                                        stopParentPlayingStory={
                                          pauseStoryPlaying
                                        }
                                        story={
                                          showSingleStoryPlayer
                                            ? playStoriesArr[2] &&
                                              playStoriesArr[2]
                                            : playStoriesMinArray[0] &&
                                              playStoriesMinArray[0]
                                        }
                                      />
                                    ) : (
                                      // </div>
                                      ''
                                    )}
                                  </div>
                                ) : (
                                  <span className='disableReactionsAction'>
                                    <i className='bi bi-camera-video videoRes'></i>
                                  </span>
                                )
                              }
                              <Sharestory
                                ref={storyVideosRef1}
                                story={
                                  showSingleStoryPlayer
                                    ? playStoriesArr[2] && playStoriesArr[2]
                                    : playStoriesMinArray[0] &&
                                      playStoriesMinArray[0]
                                }
                                setDropDownStatus={memoizedCallbackForShare}
                                dropDownStatus={childComponentShareState}
                              />
                              <Storymoreicon
                                removeReportedStory={
                                  removeReportedStoryFromPlayList
                                }
                                story={
                                  showSingleStoryPlayer
                                    ? playStoriesArr[2] && playStoriesArr[2]
                                    : playStoriesMinArray[0] &&
                                      playStoriesMinArray[0]
                                }
                                setDropDownStatus={memoizedCallbackForMoreicon}
                                dropDownStatus={childComponentMoreiconState}
                              />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {/* *****  stories reactions bar  ***** */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    // users: state.users.users,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    // fetchUsers: () => dispatch(fetchUsers()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Playstory)
