import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  AUTHENTICATE_USER_REQUEST,
  AUTHENTICATE_USER_SUCCESS,
  VALIDATE_USER_REQUEST,
  VALIDATE_USER_SUCCESS,
  FETCH_USER_SETTINGS_REQUEST,
  FETCH_USER_SETTINGS_SUCCESS,
  FETCH_PROFILE_ORGANIZATIONS_LIST_REQUEST,
  FETCH_PROFILE_ORGANIZATIONS_LIST_SUCCESS,
  FETCH_STORIES_REQUEST,
  FETCH_STORIES_SUCCESS,
  INSERT_STORIES_REQUEST,
  INSERT_STORIES_SUCCESS,
  UPDATE_STORIES_REQUEST,
  UPDATE_STORIES_SUCCESS,
  DELETE_STORIES_REQUEST,
  DELETE_STORIES_SUCCESS,
  REPORT_STORY_REQUEST,
  REPORT_STORY_SUCCESS,
  MY_STORIES_REQUEST,
  MY_STORIES_SUCCESS,
  TAP_STORY_REQUEST,
  TAP_STORY_SUCCESS,
  SEARCH_STORIES_REQUEST,
  SEARCH_STORIES_SUCCESS,
  FETCH_PUBLIC_STORY_REQUEST,
  FETCH_PUBLIC_STORY_SUCCESS,
  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_SUCCESS,
  INSERT_COMMENTS_REQUEST,
  INSERT_COMMENTS_SUCCESS,
  FETCH_EMOJIS_REQUEST,
  FETCH_EMOJIS_SUCCESS,
  INSERT_EMOJIS_REQUEST,
  INSERT_EMOJIS_SUCCESS,
  FETCH_VIDEOS_REQUEST,
  FETCH_VIDEOS_SUCCESS,
  INSERT_VIDEOS_REQUEST,
  INSERT_VIDEOS_SUCCESS,
  FETCH_ACTION_FAIL,
  STORE_STORY_OBJECT_REQUEST,
  STORE_STORY_OBJECT_SUCCESS,
  VISIBILITY_SETTINGS_STORY_OBJECT_DATA_REQUEST,
  VISIBILITY_SETTINGS_STORY_OBJECT_DATA_SUCCESS,
  RESET_RESPONSE_PROPERTIES_REQUEST,
  RESET_STORY_OBJECT_REQUEST,
  RESET_STORY_OBJECT_SUCCESS,
  STORE_SEARCHED_EMOTIONS_DATA,
  RESET_SEARCHED_EMOTIONS_DATA,
  STORE_UPLOADED_STORY_EDIT_REQUEST,
  CLEAR_UPLOADED_STORY_EDIT_REQUEST
  // RESET_RESPONSE_PROPERTIES_SUCCESS

} from '../actions/actiontypes'

const initialState = {
  users: [],
  userDetails: [],
  masterEmojis: [],
  profileSettingsList: [],
  profileOrganizationsList: [],
  allStories: [],
  insertedStory: [],
  updateStories: [],
  deletedStory: [],
  reportedStory: [],
  myStoriesOnly: [],
  tapedStory: [],
  searchedStories: [],
  publicStory: [],
  storyObjectData: [],
  storeVisibilitySettingsStoryObjectDataSuccess: [],
  comments: [],
  createdComment: [],
  emojis: [],
  createdEmoji: [],
  videos: [],
  createdVideo: [],
  resetReq: [],
  resetSuccess: [],
  error: '',
  isLoading: false,
  searchedEmotionsData: [],
  uploadedEditStoryDetails: []
}
const usersReducer = (state = initialState, action) => {
  //USERS
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.data,
        error: ''
      }
    case AUTHENTICATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetails: action.authenticateUserSuccess,
        error: ''
      }
    case VALIDATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case VALIDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        masterEmojis: action.validateUserSuccess,
        error: ''
      }
    case FETCH_USER_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profileSettingsList: action.getUserSettingsFromProfileSuccess,
        error: ""
      }
    case FETCH_PROFILE_ORGANIZATIONS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_PROFILE_ORGANIZATIONS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profileOrganizationsList: action.getProfileOrganizationsListSuccess,
        error: ""
      }

    //STORIES
    case FETCH_STORIES_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_STORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allStories: action.fetchStoriesSuccess.data.stories,
        error: ''
      }

    case INSERT_STORIES_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case INSERT_STORIES_SUCCESS:
      return {
        storyObjectData: [],
        storeVisibilitySettingsStoryObjectDataSuccess: [],
        ...state,
        insertedStory: action.insertStoriesSuccess
        // isLoading: false,
        // error: ''
      }
    case UPDATE_STORIES_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case UPDATE_STORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateStories: action.updateStoriesSuccess,
        error: ''
      }
    case DELETE_STORIES_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case DELETE_STORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deletedStory: action.deleteStoriesSuccess,
        error: ''
      }
    case REPORT_STORY_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case REPORT_STORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportedStory: action.reportStorySuccess,
        error: ''
      }
    case MY_STORIES_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case MY_STORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        myStoriesOnly: action.myStoriesSuccess,
        error: ''
      }
    case TAP_STORY_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case TAP_STORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tapedStory: action.tapAStorySuccess,
        error: ''
      }
    case SEARCH_STORIES_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case SEARCH_STORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        searchedStories: action.searchStoriesSuccess,
        error: ''
      }
    case FETCH_PUBLIC_STORY_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_PUBLIC_STORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        publicStory: action.fetchPublicStorySuccess,
        error: ''
      }
    case STORE_STORY_OBJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
        storyObjectData: action.storeStoryObjectSuccess,
      }
    case STORE_STORY_OBJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: ''
      }
    case VISIBILITY_SETTINGS_STORY_OBJECT_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        storeVisibilitySettingsStoryObjectDataSuccess: action.storeVisibilitySettingsStoryObjectDataSuccess,
      }
    case VISIBILITY_SETTINGS_STORY_OBJECT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: ''
      }
    case RESET_STORY_OBJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
        storyObjectData: [],
        storeVisibilitySettingsStoryObjectDataSuccess: []
      }
    case RESET_STORY_OBJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: ''
      }
    case STORE_SEARCHED_EMOTIONS_DATA:
      return {
        ...state,
        isLoading: false,
        searchedEmotionsData: action.storeSearchEmotinsData,
      }
    case RESET_SEARCHED_EMOTIONS_DATA:
      return {
        ...state,
        isLoading: false,
        searchedEmotionsData: action.storeSearchEmotinsData,
      }
    case STORE_UPLOADED_STORY_EDIT_REQUEST:
      return {
        ...state,
        isLoading: false,
        uploadedEditStoryDetails: action.storeUploadedEditStoryId,
      }
    case CLEAR_UPLOADED_STORY_EDIT_REQUEST:
      return {
        ...state,
        isLoading: false,
        storeVisibilitySettingsStoryObjectDataSuccess: [],
        uploadedEditStoryDetails: action.clearUploadedEditStoryId,
      }
    //COMMENTS
    case FETCH_COMMENTS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: action.fetchCommentsSuccess
      }
    case INSERT_COMMENTS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case INSERT_COMMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createdComment: action.insertCommentsSuccess,
        error: ''
      }

    //EMOJIS
    case FETCH_EMOJIS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_EMOJIS_SUCCESS:
      return {
        ...state,
        emojis: action.fetchEmojisSuccess
      }
    case INSERT_EMOJIS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case INSERT_EMOJIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createdEmoji: action.insertEmojisSuccess,
        error: ''
      }

    //VIDEOS
    case FETCH_VIDEOS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: action.fetchVideosSuccess
      }
    case INSERT_VIDEOS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case INSERT_VIDEOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createdVideo: action.res,
        error: ''
      }
    case RESET_RESPONSE_PROPERTIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        resetReq: action.resetResponsePropertiesRequest,
      }
    // case RESET_RESPONSE_PROPERTIES_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     resetSuccess: action.resetResponsePropertiesRequest,
    //     error: ''
    //   }

    //FAIL CASE
    case FETCH_ACTION_FAIL:
      return {
        ...state,
        isLoading: false,
        users: [],
        error: action.data
      }

    default:
      return state
  }
}
export default usersReducer
