import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchPublicStory } from '../../redux/actions/storiesAction'
import jwt from 'jwt-decode'
import AppConstants from '../../ApplicationConstants'
import { AwsS3GeneratePresignedUrl } from '../utils/awsUtils'
import axios from 'axios'
import MainPlayingFrame from "../frames/mainPlayingFrame3";
import PublicStoryHeader from "../layouts/publicStoryHeaders";
import { EmotionButtonsFunctionality } from '../utils/emotionsUtil'


const PlayPublicStories = () => {

    const [isAuthunicated, setIsAuthenticated] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [playStoriesArr, setPlayStoriesArr] = useState([]);
    const [noDataMsg, setNoDataMsg] = useState(false)

    const getStory = useSelector(state => state.users.videos)
    const dispatch = useDispatch()
    const params = useParams();
    // const location = useLocation();
    // let id = location.search
    let publicStoryId = params.id.substring(1);

    const publicStoryValidate = async () => {
        let getAccessToken = localStorage.accessToken;
        let getRefreshToken = localStorage.refreshToken
        let apiKey = localStorage.apiKey
        if (getAccessToken == undefined || getAccessToken == "" || getAccessToken == null ||
            getRefreshToken == undefined || getRefreshToken == "" || getRefreshToken == null ||
            apiKey == undefined || apiKey == "" || apiKey == null) {
            setIsAuthenticated(false)
            await getStorySpecificStory();
            setTimeout(() => {
                setIsLoading(false)
            }, 2000);
        }
        else {
            const decodeToken = jwt(getAccessToken);
            await axios.get(`${AppConstants.userApi}getUserId/${decodeToken.user_id}`).then(async res => {
                if (res.data.msg == "success" && res.data.suceess) {
                    setIsAuthenticated(true)
                    await getStorySpecificStory();
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 2000);
                }
            })
        }
    }

    const getStorySpecificStory = async () => {
        let data = {
            storyId: publicStoryId,
        }
        dispatch(fetchPublicStory(data, async response => {
            // console.log(response, 'response');
            if (response.data.story.length > 0) {
                // console.log(response.data.story,'story');
                let temStories = JSON.parse(JSON.stringify(response.data.story))
                let emotionsMutatedArr = await EmotionButtonsFunctionality(temStories)
                let presignedData = await AwsS3GeneratePresignedUrl(emotionsMutatedArr)
                setPlayStoriesArr(presignedData)
            }
            else {
                setNoDataMsg(true)
            }
        }))
    }

    useEffect(() => {
        publicStoryValidate();
    }, [])

    const handleDataFromMainPlayingFrame3 = () => {
    }
    const seeProfileInformation = (id) => {
        axios.get(`${AppConstants.userApi}getUserId/${id}`).then(async res => {
            if (res.data.data[0].isProfessional) {
                window.location.href = `${AppConstants.BASE_DEV_URL +
                    `/professional-profile/adminDomain/` +
                    id}`
            }
            else {
                window.location.href = `${AppConstants.BASE_DEV_URL +
                    `/student-profile/adminDomain/` +
                    id}`
            }
        });
    }
    // console.log(playStoriesArr, 'playStoriesArr share');
    return (
        <div className="upsquardStoriesPage publicStoryPage">
            <PublicStoryHeader
                isAuthunicated={isAuthunicated}
            />
            {isLoading
                ?
                (<div className='playtStories loading' />)
                :
                noDataMsg ? <div className='all-stories-msg'> <h6>There is no story found! </h6> </div >
                    :
                    <MainPlayingFrame
                        sendDataToPlayPublicStories={handleDataFromMainPlayingFrame3}
                        sendDataToChildPlayStoriesArr={playStoriesArr}
                        isAuthunicated1={isAuthunicated}
                        seeProfileInformation={seeProfileInformation}
                    />
            }
        </div >
    );
};

export default PlayPublicStories;