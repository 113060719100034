import React, { useEffect, useState, useRef, useCallback } from 'react'
import ReactPlayer from 'react-player'
import Storyemojis from '../pages/storyEmojis'
import Storycomments from '../pages/storyComments'
import Storyvideos from '../pages/storyVideos'
import Sharestory from '../pages/shareStory'
import Storymoreicon from '../pages/moreOptions'
import $ from 'jquery'
import AppConstants from '../../ApplicationConstants'

function MainPlayingFrame ({ seeProfileInformation, ...props }) {
  const [playStoriesArr, setPlayStoriesArr] = useState(
    props.sendDataToChildPlayStoriesArr
  )
  const [storyFullscreenStatus, setStoryFullscreenStatus] = useState(false)
  const [storyPause, setStoryPause] = useState(false)
  const [isAuthunicated, setIsAuthenticated] = useState(props.isAuthunicated1)
  const [reactionsScreen, setReactionScreen] = useState(null)
  const [storyMuteStatus, setStoryMuteStatus] = useState(false)
  const [childComponentEmojiState, setChildComponentEmojiState] =
    useState(false)
  const [childComponentState, setChildComponentState] = useState(false)
  const [childComponentShareState, setChildComponentShareState] =
    useState(false)
  const [childComponentMoreiconState, setChildComponentMoreiconState] =
    useState(false)

  const storyVideosRef = useRef(null)
  const storyVideosRef1 = useRef(null)
  const resetEmojis = useRef(null)

  const changeToFullScreen = () => {
    setStoryFullscreenStatus(true)
  }
  const closeFullScreenModal = () => {
    setStoryFullscreenStatus(false)
  }
  const storyPlayingHandler = () => {
    setStoryPause(!storyPause)
  }
  const showReactionsScreen = val => {
    setChildComponentState(false)
    setChildComponentEmojiState(false)
    setChildComponentShareState(false)
    setChildComponentMoreiconState(false)
    if (reactionsScreen == val) {
      setReactionScreen(null)
    } else {
      setReactionScreen(val)
    }
  }
  const storyMuteStatusHandler = () => {
    setStoryMuteStatus(!storyMuteStatus)
  }

  // useEffect(() => {
  //   setPlayStoriesArr(sendDataToChildPlayStoriesArr)
  //   setIsAuthenticated(isAuthunicated1)
  // }, [sendDataToChildPlayStoriesArr])

  useEffect(() => {
    $(document).mouseup(function (e) {
      var container = $('.video-comment')
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        if (reactionsScreen == 3) {
          setReactionScreen(null)
        }
      }
    })
  }, [reactionsScreen])
  const moveToProfileDetails = id => {
    seeProfileInformation(id)
  }

  const incrementChildEmojiState = number => {
    setChildComponentEmojiState(number)
    setChildComponentState(false)
    setChildComponentShareState(false)
    setChildComponentMoreiconState(false)
  }
  const memoizedCallbackForEmojis = useCallback(
    state => incrementChildEmojiState(state),
    []
  )

  const incrementChildCommentState = number => {
    setChildComponentState(number)
    setChildComponentEmojiState(false)
    setChildComponentShareState(false)
    setChildComponentMoreiconState(false)
  }
  const memoizedCallback = useCallback(
    state => incrementChildCommentState(state),
    []
  )

  const incrementChildShareState = number => {
    setChildComponentShareState(number)
    setChildComponentState(false)
    setChildComponentEmojiState(false)
    setChildComponentMoreiconState(false)
  }
  const memoizedCallbackForShare = useCallback(
    state => incrementChildShareState(state),
    []
  )

  const incrementChildMoreiconState = number => {
    setChildComponentMoreiconState(number)
    setChildComponentState(false)
    setChildComponentEmojiState(false)
    setChildComponentShareState(false)
  }
  const memoizedCallbackForMoreicon = useCallback(
    state => incrementChildMoreiconState(state),
    []
  )

  const handleParentClick = event => {
    event.preventDefault()
    if (event.target === event.currentTarget) {
      setChildComponentState(false)
      setChildComponentEmojiState(false)
      setChildComponentShareState(false)
      setChildComponentMoreiconState(false)
    }
  }
  return (
    <div className='bg-video-gallery'>
      <div
        className={
          storyFullscreenStatus ? 'gallery fullScreenVedioDisplay' : 'gallery '
        }
      >
        {isAuthunicated ? (
          ''
        ) : (
          <div className='text-center'>
            <p className='mb-0 mt-4'>
              To{' '}
              <strong className='text-dark'>
                React or Respond/Add Comments
              </strong>
              , <a href={`${AppConstants.BASE_DEV_URL}/auth/login`}>Login</a> or{' '}
              <a href={`${AppConstants.BASE_DEV_URL}/auth/login`}>Sign-Up</a>
            </p>
          </div>
        )}
        {playStoriesArr.length > 0 ? (
          <div className='gallery-container' onClick={handleParentClick}>
            <div
              className={
                storyFullscreenStatus
                  ? 'gallery-item gallery-item-3 fullScreenVedioDisplay2'
                  : 'gallery-item gallery-item-3 save-video'
              }
              data-index='3'
            >
              <span
                className='crossMark'
                onClick={() => closeFullScreenModal()}
              >
                X
              </span>{' '}
              <div
                className={
                  playStoriesArr[0] && playStoriesArr[0].isAnonymous
                    ? 'card border-0 text-white anonymousImgHgt'
                    : 'card border-0 text-white'
                }
              >
                <div>
                  {playStoriesArr[0] && playStoriesArr[0].isAnonymous ? (
                    <div className='anonymousImg'>
                      <img
                        className='front-img'
                        src='../../assets/images/Anonymous3.jpg'
                        width='100%'
                        height='100%'
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  <div id='video-viewport'>
                    {/* <ReactPlayer
                      url={playStoriesArr[0].storyProperties.originalURL}
                      width='100%'
                      height='100%'
                      className='video'
                      config={{
                        file: { attributes: { controlsList: 'nodownload' } }
                      }}
                      controls={true}
                      playing={true}
                      onBuffer={true}
                      loop={true}
                      muted={storyMuteStatus}
                    ></ReactPlayer> */}
                    {/* <video
                      src={playStoriesArr[0].storyProperties.originalURL}
                      width='100%'
                      height='100%'
                      controls={true}                     
                      loop={true}
                      muted={false}
                      volume={0}
                      autoPlay={true}
                      config={{
                        file: {
                          attributes: {
                            controlsList: 'nodownload'
                          }
                        }
                      }}
                    /> */}
                    <video
                      src={playStoriesArr[0].storyProperties.originalURL}
                      width='100%'
                      height='100%'
                      controls={true}                     
                      loop={true}
                      muted={false}
                      volume={1}
                      disablePictureInPicture
                      nodownload
                      autoPlay={true}
                      controlsList="nodownload noplaybackrate"
                    />
                  </div>
                </div>
                <div className='rejmute'>
                  <div className='d-flex align-items-center rejmutebg justify-content-end'>
                    <div
                      onClick={storyMuteStatusHandler}
                      aria-hidden='true'
                      className='hoverEffectButtonMute'
                    >
                      <img
                        src={
                          storyMuteStatus
                            ? '../../assets/images/unmute-icon.svg'
                            : '../../assets/images/mute-icon.svg'
                        }
                        width='100%'
                        height='100%'
                        alt='overlay'
                      />
                    </div>
                  </div>
                  {/* <div className='playingBtn ' onClick={storyPlayingHandler}>
                    <div className='play-btn'>
                      <img
                        src={
                          storyPause
                            ? '../../assets/images/pause-circle.svg'
                            : '../../assets/images/play.svg'
                        }
                        width='100%'
                        height='100%'
                        alt='overlay'
                      />
                    </div>
                  </div> */}
                </div>

                <div className='card-img-overlay1'>
                  <div className='card-cont'>
                    <div className='p-3 fullScreenNone'>
                      <h5 className='card-title'>
                        {playStoriesArr[0] && playStoriesArr[0].title}
                      </h5>
                      <p className='card-text'>
                        {playStoriesArr[0] && playStoriesArr[0].description}
                      </p>
                      <p className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center '>
                          {playStoriesArr[0] &&
                          playStoriesArr[0].isAnonymous ? (
                            <>
                              <div className='galleryProfile'>
                                <a>
                                  <img
                                    src={
                                      (playStoriesArr[0] &&
                                        playStoriesArr[0].isAnonymous) ||
                                      (playStoriesArr[0] &&
                                        playStoriesArr[0].userProperties[0]
                                          .profileImg == null)
                                        ? 'https://www.careerquo.com/assets/images/18.png'
                                        : playStoriesArr[0] !== undefined &&
                                          playStoriesArr[0].userProperties[0]
                                            .profileImg
                                    }
                                    width='100%'
                                    height='100%'
                                    alt=''
                                  />
                                </a>
                              </div>
                              <a>
                                <strong>
                                  {playStoriesArr[0] &&
                                  playStoriesArr[0].isAnonymous
                                    ? 'Anonymous'
                                    : `${
                                        playStoriesArr[0] !== undefined &&
                                        playStoriesArr[0].userProperties[0] !==
                                          undefined &&
                                        playStoriesArr[0].userProperties[0]
                                          .firstName
                                      }  ${
                                        playStoriesArr[0] &&
                                        playStoriesArr[0].userProperties[0]
                                          .lastName
                                      }`}
                                </strong>
                              </a>
                            </>
                          ) : (
                            <>
                              <div className='galleryProfile'>
                                <a
                                  onClick={() =>
                                    moveToProfileDetails(
                                      playStoriesArr[0].userId
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      (playStoriesArr[0] &&
                                        playStoriesArr[0].isAnonymous) ||
                                      (playStoriesArr[0] &&
                                        playStoriesArr[0].userProperties[0]
                                          .profileImg == null)
                                        ? 'https://www.careerquo.com/assets/images/18.png'
                                        : playStoriesArr[0] !== undefined &&
                                          playStoriesArr[0].userProperties[0]
                                            .profileImg
                                    }
                                    width='100%'
                                    height='100%'
                                    alt=''
                                  />
                                </a>
                              </div>
                              <a
                                onClick={() =>
                                  moveToProfileDetails(playStoriesArr[0].userId)
                                }
                              >
                                <strong>
                                  {playStoriesArr[0] &&
                                  playStoriesArr[0].isAnonymous
                                    ? 'Anonymous'
                                    : `${
                                        playStoriesArr[0] !== undefined &&
                                        playStoriesArr[0].userProperties[0] !==
                                          undefined &&
                                        playStoriesArr[0].userProperties[0]
                                          .firstName
                                      }  ${
                                        playStoriesArr[0] &&
                                        playStoriesArr[0].userProperties[0]
                                          .lastName
                                      }`}
                                </strong>
                              </a>
                            </>
                          )}
                        </div>
                      </p>
                      <div className='d-flex justify-content-between'>
                        <div className='reactedEmotions mb-2'>
                          {playStoriesArr[0] &&
                            playStoriesArr[0].emotionTags.map((obj, index) => {
                              return (
                                <h6 className={obj.emotionBtnBgColor}>
                                  {obj.emotion}
                                </h6>
                              )
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* *****  stories reactions bar  ***** */}
            {playStoriesArr.length > 0 ? (
              <div className='publicStory comment-gallery'>
                <div className='d-flex justify-content-around p-2 CommentDisplay'>
                  <Storyemojis
                    ref={resetEmojis}
                    story={playStoriesArr[0] && playStoriesArr[0]}
                    setDropDownStatus={memoizedCallbackForEmojis}
                    dropDownStatus={childComponentEmojiState}
                  />
                  <Storycomments
                    story={playStoriesArr[0] && playStoriesArr[0]}
                    dropDownStatus={childComponentState}
                    setDropDownStatus={memoizedCallback}
                  />
                  {playStoriesArr[0] &&
                  playStoriesArr[0].responseTypes.includes('videos') &&
                  playStoriesArr[0] &&
                  playStoriesArr[0].storyStatus == 'approved' &&
                  isAuthunicated ? (
                    <div className=''>
                      <button
                        type='button'
                        className='border-0 bg-transparent trianglePostion'
                        onClick={() => showReactionsScreen(3)}
                      >
                        <span>
                          <i className='bi bi-camera-video videoRes'></i>
                        </span>

                        {reactionsScreen == 3 ? (
                          <div className='triangle-arrow '>
                            <i className='bi bi-triangle-fill'></i>
                          </div>
                        ) : (
                          ''
                        )}
                      </button>
                      {reactionsScreen == 3 ? (
                        <Storyvideos
                          story={playStoriesArr[0] && playStoriesArr[0]}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <span className='disableReactionsAction'>
                      <i className='bi bi-camera-video videoRes'></i>
                    </span>
                  )}
                  <Sharestory
                    ref={storyVideosRef1}
                    story={playStoriesArr[0] && playStoriesArr[0]}
                    setDropDownStatus={memoizedCallbackForShare}
                    dropDownStatus={childComponentShareState}
                  />
                  <Storymoreicon
                    story={playStoriesArr[0] && playStoriesArr[0]}
                    setDropDownStatus={memoizedCallbackForMoreicon}
                    dropDownStatus={childComponentMoreiconState}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default MainPlayingFrame
