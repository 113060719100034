import React, { useEffect } from 'react';
import axios from 'axios'
import ApplicationConsts from '../../ApplicationConstants'
import { userStoryModuleValidation } from '../utils/storyVlidateUtil'
function ValidateStoryModule(props) {
    useEffect(() => {
        validateStoriesFn();
    }, [localStorage.userId])

    //  ********************************************
    //        Validate stories module
    //  ********************************************
    const validateStoriesFn = async () => {
        let expiresIn = "1666783319000"
        let validationStatus = await userStoryModuleValidation(localStorage.userId, expiresIn)
        if (validationStatus == "success" || validationStatus == 200 || validationStatus == 'stories access allowed.' || validationStatus == "Session already existed to this Access token.") {
            window.location.href = "/cGxheXN0b3JpZXM=";
        }
        else {
            axios.post(`${ApplicationConsts.BASE_URL}users/refresh`, {
                "refreshToken": localStorage.refreshToken,
                "apiKey": localStorage.apiKey
            }).then(async (response) => {
                localStorage.setItem('accessToken', response.data.idToken);
                let validationStatus = await userStoryModuleValidation(response.data.idToken, response.data.expiresIn)
                if (validationStatus == "success" || validationStatus == 200 || validationStatus == 'stories access allowed.' || validationStatus == "Session already existed to this Access token.") {
                    window.location.href = "/cGxheXN0b3JpZXM=";
                }
            })
                .catch(error => {
                    return Promise.reject(error)
                })
        }
    }
    return (
        <div>
            <div className="upsquardStoriesPage">
                <div className="storiesAuthentication loading"></div>
            </div>
        </div>
    );
}
export default ValidateStoryModule;