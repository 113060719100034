export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'

export const AUTHENTICATE_USER_REQUEST = 'AUTHENTICATE_USER_REQUEST'
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS'

export const VALIDATE_USER_REQUEST = 'VALIDATE_USER_REQUEST'
export const VALIDATE_USER_SUCCESS = 'VALIDATE_USER_SUCCESS'

export const FETCH_PROFILE_ORGANIZATIONS_LIST_REQUEST = "FETCH_PROFILE_ORGANIZATIONS_LIST_REQUEST"
export const FETCH_PROFILE_ORGANIZATIONS_LIST_SUCCESS = "FETCH_PROFILE_ORGANIZATIONS_LIST_SUCCESS"

export const FETCH_USER_SETTINGS_REQUEST = "FETCH_USER_SETTINGS_REQUEST"
export const FETCH_USER_SETTINGS_SUCCESS= "FETCH_USER_SETTINGS_SUCCESS"


//STORIES
export const FETCH_STORIES_REQUEST = 'FETCH_STORIES_REQUEST'
export const FETCH_STORIES_SUCCESS = 'FETCH_STORIES_SUCCESS'

export const INSERT_STORIES_REQUEST = 'INSERT_STORIES_REQUEST'
export const INSERT_STORIES_SUCCESS = 'INSERT_STORIES_SUCCESS'

export const UPDATE_STORIES_REQUEST = 'UPDATE_STORIES_REQUEST'
export const UPDATE_STORIES_SUCCESS = 'UPDATE_STORIES_SUCCESS'

export const DELETE_STORIES_REQUEST = 'DELETE_STORIES_REQUEST'
export const DELETE_STORIES_SUCCESS = 'DELETE_STORIES_SUCCESS'

export const REPORT_STORY_REQUEST = 'REPORT_STORY_REQUEST'
export const REPORT_STORY_SUCCESS = 'REPORT_STORY_SUCCESS'

export const MY_STORIES_REQUEST = 'MY_STORIES_REQUEST'
export const MY_STORIES_SUCCESS = 'MY_STORIES_SUCCESS'

export const TAP_STORY_REQUEST = 'TAP_STORY_REQUEST'
export const TAP_STORY_SUCCESS = 'TAP_STORY_SUCCESS'

export const SEARCH_STORIES_REQUEST = 'SEARCH_STORIES_REQUEST'
export const SEARCH_STORIES_SUCCESS = 'SEARCH_STORIES_SUCCESS'

export const STORE_STORY_OBJECT_REQUEST = 'STORE_STORY_OBJECT_REQUEST'
export const STORE_STORY_OBJECT_SUCCESS = 'STORE_STORY_OBJECT_SUCCESS'

export const VISIBILITY_SETTINGS_STORY_OBJECT_DATA_REQUEST ="VISIBILITY_SETTINGS_STORY_OBJECT_DATA_REQUEST"
export const VISIBILITY_SETTINGS_STORY_OBJECT_DATA_SUCCESS ="VISIBILITY_SETTINGS_STORY_OBJECT_DATA_SUCCESS"

 
export const STORE_UPLOADED_STORY_EDIT_REQUEST = 'STORE_UPLOADED_STORY_EDIT_REQUEST'
export const CLEAR_UPLOADED_STORY_EDIT_REQUEST = 'CLEAR_UPLOADED_STORY_EDIT_REQUEST'

export const RESET_STORY_OBJECT_REQUEST = 'RESET_STORY_OBJECT_REQUEST'
export const RESET_STORY_OBJECT_SUCCESS = 'RESET_STORY_OBJECT_SUCCESS'

export const FETCH_PUBLIC_STORY_REQUEST = 'FETCH_PUBLIC_STORY_REQUEST'
export const FETCH_PUBLIC_STORY_SUCCESS = 'FETCH_PUBLIC_STORY_SUCCESS'

export const STORE_SEARCHED_EMOTIONS_DATA = 'STORE_SEARCHED_EMOTIONS_DATA'
export const RESET_SEARCHED_EMOTIONS_DATA = 'RESET_SEARCHED_EMOTIONS_DATA'

// COMMENTS
export const FETCH_COMMENTS_REQUEST = 'FETCH_COMMENTS_REQUEST'
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS'

export const INSERT_COMMENTS_REQUEST = 'INSERT_COMMENTS_REQUEST'
export const INSERT_COMMENTS_SUCCESS = 'INSERT_COMMENTS_SUCCESS'

export const UPDATE_COMMENTS_REQUEST = 'UPDATE_COMMENTS_REQUEST'
export const UPDATE_COMMENTS_SUCCESS = 'UPDATE_COMMENTS_SUCCESS'

export const DELETE_COMMENTS_REQUEST = 'DELETE_COMMENTS_REQUEST'
export const DELETE_COMMENTS_SUCCESS = 'DELETE_COMMENTS_SUCCESS'

// EMOJIS
export const FETCH_EMOJIS_REQUEST = 'FETCH_EMOJIS_REQUEST'
export const FETCH_EMOJIS_SUCCESS = 'FETCH_EMOJIS_SUCCESS'

export const INSERT_EMOJIS_REQUEST = 'INSERT_EMOJIS_REQUEST'
export const INSERT_EMOJIS_SUCCESS = 'INSERT_EMOJIS_SUCCESS'

export const UPDATE_EMOJIS_REQUEST = 'UPDATE_EMOJIS_REQUEST'
export const UPDATE_EMOJIS_SUCCESS = 'UPDATE_EMOJIS_SUCCESS'

export const DELETE_EMOJIS_REQUEST = 'DELETE_EMOJIS_REQUEST'
export const DELETE_EMOJIS_SUCCESS = 'DELETE_EMOJIS_SUCCESS'

//VIDEOS
export const FETCH_VIDEOS_REQUEST = 'FETCH_VIDEOS_REQUEST'
export const FETCH_VIDEOS_SUCCESS = 'FETCH_VIDEOS_SUCCESS'

export const INSERT_VIDEOS_REQUEST = 'INSERT_VIDEOS_REQUEST'
export const INSERT_VIDEOS_SUCCESS = 'INSERT_VIDEOS_SUCCESS'

export const DELETE_VIDEOS_REQUEST = 'DELETE_VIDEOS_REQUEST'
export const DELETE_VIDEOS_SUCCESS = 'DELETE_VIDEOS_SUCCESS'

export const RESET_RESPONSE_PROPERTIES_REQUEST = 'RESET_RESPONSE_PROPERTIES_REQUEST'
export const RESET_RESPONSE_PROPERTIES_SUCCESS = 'RESET_RESPONSE_PROPERTIES_SUCCESS'

//FAIL
export const FETCH_ACTION_FAIL = 'FETCH_ACTION_FAIL'
