import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Playstory from '../src/components/pages/playStories';
import Createstory from '../src/components/pages/createStories';
import Storiesauthentication from './components/pages/storiesAuthentication ';
import Storysettings from './components/pages/storyVisibilitySettings';
import PlayPublicStories from './components/pages/playPublicStories'
import { ProtectRoutes } from '../src/services/interceptorServices'
import ValidateStoryModule from './components/pages/validateStoryModule';


const Routers = () => {
    const isAuthunicated = true
    return (
        <Router>
            <Routes>
                <Route
                    exact
                    path="/"
                    element={<Storiesauthentication />}
                />
                <Route
                    exact
                    path="/dmFsaWRhdGUtc3Rvcmllcw=="
                    element={<ValidateStoryModule />}
                />
                <Route
                    exact
                    path="/cGxheXN0b3JpZXM="
                    element={<Playstory />}
                />
                <Route
                    exact
                    path="/Y3JlYXRlc3Rvcmllcw=="
                    element={<Createstory />}
                />
                <Route
                    exact
                    path="/c3Rvcmllc3NldHRpbmdz"
                    element={<Storysettings />}
                />
                <Route
                    exact
                    path="/cGxheVB1YmxpY1N0b3JpZXM/:id"
                    element={<PlayPublicStories />}
                />
            </Routes>
        </Router>
    );
}
export default Routers;
