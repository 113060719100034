const staticContent = {
    playstories_search_button_cnt: "Search",
    playstories_clear_all_button_cnt: "Clear All",
    playstories_all_stories_drop_cnt: "All Stories",
    playstories_my_stories_drop_cnt: "My Stories",
    playstories_pending_stories_drop_cnt: "My Pending Stories",
    playstories_rejected_drop_cnt: "My Rejected Stories",
    tap_hint_msg: "Your message minimum 5 characters.",
    tap_email_hint_error_msg: "**Please enter the valid email address",
    tap_message_error_hint_msg: "**Please enter the message with atleast 5 characters",
    tap_btn_cnt: "Tap",
    tap_title_line1: "Can you think of someone who has an amazing story that the story world needs to hear? Tap them and encourage them to tell a story.",
    tap_title_line2: "Tap them and encourage them to tell",
    tap_title_line3: "another story.",
    playstories_tell_me_more_btn: "Tell me more",
    playstories_no_stories_cnt: "You do not have stories!",
    playstories_no_match_cnt: "No Stories Found!",
    playstories_reject_cmt_cnt: "Rejection comment :",
    playstories_header_line1: "Everyone has a Story.",
    playstories_header_line2: "What's yours?",
    playstories_create_story_btn: "Create Story",
    playstories_serach_hint_line1: "Search by Emotion(s) based on",
    playstories_serach_hint_line2: "Plutchik's Wheel of Emotion(s).",
    playstories_house_rules_line1: "Everyone has a story.  What's yours?",
    playstories_house_rules_line2: "Blooming by UpSquad is a Positive and inspiring space. A place to be kind and build others up. And, leave feeling you can do it.",
    playstories_house_rules_line3: "If you've never shared before on social, share here. If you've shared 1000 times before, share here. Share a story about your life. Share about today. Share what you learned this week. Share what you need help with. Share what you want to help with. Share how someone or an organization has helped you.",
    playstories_house_rules_line4: "It must be true. About yourself. Or, if you share about others, it must be uplifting. Nothing negative about someone else. Only positive comments. Nothing divisive or with profanity.",
    playstories_house_rules_line5: "Tag your story with emotions so others can search by emotions and see how you feel.",
    playstories_house_rules_line6: "Currently, there's a 5 minute video limit (subject to change), but the shorter your video the better. If your video is longer than 5 minutes, you can break it into parts and upload Part 1, Part 2, etc.",
    playstories_house_rules_line7: " By using this space, you agree to help keep this a positive place.",
    createstories_title_cnt: "Create Your Story",
    createstories_select_video_hint_msg: "Video Limit : 5 Minutes.",
    createstories_edit_video_hint_msg: "You can't edit video.",
    createstories_add_story_cnt: "Add Story",
    createstories_title_placeholder: "Story title",
    createstories_description_placeholder: "Description",
    createstories_emt_tile_line1: "What are your feelings about this story?",
    createstories_emt_tile_line2: "Select your Emotion(s) based on",
    createstories_emt_tile_line3: "Plutchik's Wheel of Emotion(s)",
    createstories_anon_label: "Publish story as anonymous",
    createstories_visi_stng_link: "Choose who can see and react to your story.",
    createstories_post_btn: "Post",
    emo_title_cnt: "Select Reaction",
    emo_resp_cnt: "Responses",
    no_resps_cnt: "There is no responses.",
    auth_can_see_rctns: "Only Author can see Reactions.",
    cmnt_resp_tile_cnt: "Comments",
    resp_rctns_title: "Responses",
    res_browse_btn_ctn: "Browse",
    share_title_ctn: "Share",
    share_copy_cnt: "Copy Link",
    share_fb_cnt: "Facebook",
    share_twtr_cnt: "Twitter",
    share_lnkdn_cnt: "LinkedIn",
    share_wtsapp_cnt: "WhatsApp",
    more_report_cnt: "Report",
    more_edit_cnt: "Edit Story",
    more_del_cnt:"Delete Story"
}
export default staticContent