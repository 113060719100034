import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

function PlutchikWheel({ sendEmotionsDataToCreateStories, sendEmotionsDataToPlayStories, sendDataToChild, sendDataToChildToEditEmotion, ...props }) {

  const storyObjectData = useSelector(
    state => state.users.storyObjectData
  )
  const EmotionsDataFromSearch = useSelector(
    state => state.users.searchedEmotionsData
  )
  const [feelings, setFeelings] = useState([])

  //create stories
  let createdStoriesEmotions = storyObjectData && storyObjectData.feelings
  const getCreatedStoriesEmotionsData = async (createdStoriesEmotions) => {
    setFeelings(createdStoriesEmotions)
  }
  useEffect(() => {
    if (createdStoriesEmotions) {
      getCreatedStoriesEmotionsData(createdStoriesEmotions)
    }
  }, [createdStoriesEmotions])


  //searched stories
  let searchedStoriesEmotions = EmotionsDataFromSearch && EmotionsDataFromSearch
  const getSearchedStoriesEmotionsData = async (searchedStoriesEmotions) => {
    setFeelings(searchedStoriesEmotions)
  }
  useEffect(() => {
    if (sendDataToChild) {
      getSearchedStoriesEmotionsData(searchedStoriesEmotions)
    }
  }, [searchedStoriesEmotions])


  const handleChange = async (feel) => {
    if (feelings.includes(feel)) {
      setFeelings(feelings.filter((obj) => { return (obj !== feel) }))
    }
    else {
      setFeelings((prevstate) => {
        return [...prevstate, ...[feel]]
      });
    }
  }
  if (sendDataToChild) {
    sendEmotionsDataToPlayStories(feelings)
  }
  else {
    sendEmotionsDataToCreateStories(feelings)
  }
  useEffect(() => {
    if (feelings.includes(sendDataToChildToEditEmotion)) {
      setFeelings(feelings.filter((obj) => { return (obj !== sendDataToChildToEditEmotion) }))
    }
    // props.onUseEffect()
  }, [sendDataToChildToEditEmotion])

  return (
    <div>
      <div className="circleMenuView">
        <button type="button" className="btn">I FEEL</button>

        <div className="innerCircle2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="300"
            height="300"
            viewBox="0 0 300 300"
          >
            <g
              id="Group_33"
              data-name="Group 33"
              transform="translate(-771 -612)"
            >
              <g id="Group_25" data-name="Group 25">
                <a onClick={() => { handleChange('Trust') }}>
                  <g
                    id="Group_17"
                    data-name="Group 17"
                    transform="translate(920.392 612)"
                  >
                    <path
                      className={feelings && feelings.includes('Trust') ? "disabled-feel" : ""}
                      id="Intersection_1"
                      data-name="Intersection 1"
                      d="M0,0A150.208,150.208,0,0,1,104.307,41.93L0,150.7Z"
                      fill="#b8dd67"
                    />
                  </g>
                  <text
                    id="Trust"
                    transform="translate(935 684)"
                    font-size="15"
                    font-family="Roboto-Medium, Roboto"
                    font-weight="500"
                  >
                    <tspan x="0" y="0">Trust</tspan>
                  </text>
                </a>
              </g>
              <g id="Group_26" data-name="Group 26">
                <a onClick={() => { handleChange('Fear') }}>
                  <g
                    id="Group_18"
                    data-name="Group 18"
                    transform="translate(920.296 653.93)"
                  >
                    <path
                      className={feelings && feelings.includes('Fear') ? "disabled-feel" : ""}
                      id="Intersection_1-2"
                      data-name="Intersection 1"
                      d="M0,108.775,104.307,0a150.259,150.259,0,0,1,46.4,108.775Z"
                      fill="#5dc27e"
                    />
                  </g>
                  <text
                    id="Fear"
                    transform="translate(983 730)"
                    font-size="15"
                    font-family="Roboto-Medium, Roboto"
                    font-weight="500"
                  >
                    <tspan x="0" y="0">Fear</tspan>
                  </text>
                </a>
              </g>
              <g id="Group_27" data-name="Group 27">
                <a onClick={() => { handleChange('Surprise') }}>
                  <g
                    id="Group_19"
                    data-name="Group 19"
                    transform="translate(920.296 761.296)"
                  >
                    <path
                      className={feelings && feelings.includes('Surprise') ? "disabled-feel" : ""}
                      id="Intersection_1-3"
                      data-name="Intersection 1"
                      d="M0,0H150.7a150.273,150.273,0,0,1-47.073,109.419Z"
                      fill="#49cbee"
                    />
                  </g>
                  <text
                    id="Surprise"
                    transform="translate(983 796)"
                    font-size="15"
                    font-family="Roboto-Medium, Roboto"
                    font-weight="500"
                  >
                    <tspan x="0" y="0">Surprise</tspan>
                  </text>
                </a>
              </g>
              <g id="Group_28" data-name="Group 28">
                <a onClick={() => { handleChange('Sadness') }}>
                  <g
                    id="Group_20"
                    data-name="Group 20"
                    transform="translate(920.393 761.296)"
                  >
                    <path
                      className={feelings && feelings.includes('Sadness') ? "disabled-feel" : ""}
                      id="Intersection_1-4"
                      data-name="Intersection 1"
                      d="M0,0,103.631,109.419A150.194,150.194,0,0,1,0,150.7Z"
                      fill="#4999e9"
                    />
                  </g>
                  <text
                    id="Sadness"
                    transform="translate(929 854)"
                    font-size="15"
                    font-family="Roboto-Medium, Roboto"
                    font-weight="500"
                  >
                    <tspan x="0" y="0">Sadness</tspan>
                  </text>
                </a>
              </g>
              <g id="Group_29" data-name="Group 29">
                <a onClick={() => { handleChange('Disgust') }}>
                  <g
                    id="Group_21"
                    data-name="Group 21"
                    transform="translate(817.977 761.296)"
                  >
                    <path
                      className={feelings && feelings.includes('Disgust') ? "disabled-feel" : ""}
                      id="Intersection_1-5"
                      data-name="Intersection 1"
                      d="M0,150.7,103.631,41.285A150.194,150.194,0,0,0,0,0Z"
                      transform="translate(103.631 150.704) rotate(180)"
                      fill="#8f71cd"
                    />
                  </g>
                  <text
                    id="Disgust"
                    transform="translate(859 854)"
                    font-size="15"
                    font-family="Roboto-Medium, Roboto"
                    font-weight="500"
                  >
                    <tspan x="0" y="0">Disgust</tspan>
                  </text>
                </a>
              </g>
              <g id="Group_30" data-name="Group 30">
                <a onClick={() => { handleChange('Anger') }}>
                  <g
                    id="Group_22"
                    data-name="Group 22"
                    transform="translate(771 761.296)"
                  >
                    <path
                      className={feelings && feelings.includes('Anger') ? "disabled-feel" : ""}
                      id="Intersection_1-6"
                      data-name="Intersection 1"
                      d="M0,109.419H150.7A150.273,150.273,0,0,0,103.631,0Z"
                      transform="translate(150.704 109.419) rotate(180)"
                      fill="#e8706e"
                    />
                  </g>
                  <text
                    id="Anger"
                    transform="translate(809 798)"
                    font-size="15"
                    font-family="Roboto-Medium, Roboto"
                    font-weight="500"
                  >
                    <tspan x="0" y="0">Anger</tspan>
                  </text>
                </a>
              </g>
              <g id="Group_31" data-name="Group 31">
                <a onClick={() => { handleChange('Anticipation') }}>
                  <g
                    id="Group_23"
                    data-name="Group 23"
                    transform="translate(771 653.93)"
                  >
                    <path
                      className={feelings && feelings.includes('Anticipation') ? "disabled-feel" : ""}
                      id="Intersection_1-7"
                      data-name="Intersection 1"
                      d="M0,0,104.307,108.775A150.259,150.259,0,0,0,150.7,0Z"
                      transform="translate(150.704 108.775) rotate(180)"
                      fill="#fd9e59"
                    />
                  </g>
                  <text
                    id="Anticipation"
                    transform="translate(789 730)"
                    font-size="15"
                    font-family="Roboto-Medium, Roboto"
                    font-weight="500"
                  >
                    <tspan x="0" y="0">Anticipation</tspan>
                  </text>
                </a>
              </g>
              <g id="Group_32" data-name="Group 32">
                <a onClick={() => { handleChange('Joy') }}>
                  <g
                    id="Group_24"
                    data-name="Group 24"
                    transform="translate(817.302 612)"
                  >
                    <path
                      className={feelings && feelings.includes('Joy') ? "disabled-feel" : ""}
                      id="Intersection_1-8"
                      data-name="Intersection 1"
                      d="M0,150.7a150.208,150.208,0,0,0,104.307-41.93L0,0Z"
                      transform="translate(104.307 150.705) rotate(180)"
                      fill="#ffe948"
                    />
                  </g>
                  <text
                    id="Joy"
                    transform="translate(873 678)"
                    font-size="15"
                    font-family="Roboto-Medium, Roboto"
                    font-weight="500"
                  >
                    <tspan x="0" y="0">Joy</tspan>
                  </text>
                </a>
              </g>
            </g>
          </svg>
        </div>

        <div className="outerCircle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="400"
            height="400"
            viewBox="0 0 465 449"
          >
            <g
              id="Group_3"
              data-name="Group 7"
              transform="translate(-1313 -1080)"
            >
              <g id="Group_5" data-name="Group 5">
                <g id="Group_4" data-name="Group 4">
                  <g
                    id="Group_3"
                    data-name="Group 3"
                    transform="translate(537 308)"
                  >
                    <g
                      id="Group_1"
                      data-name="Group 1"
                      transform="translate(-82 -17)"
                    >
                      <circle
                        id="Ellipse_centerCircle"
                        cx="175"
                        cy="175"
                        r="175"
                        transform="translate(915 831)"
                        fill="none"
                      />
                    </g>
                    <g id="tail-7" className="tails">
                      <a onClick={() => { handleChange('Optimism') }}>
                        <circle
                          className={feelings && feelings.includes('Optimism') ? "disabled-feel" : ""}
                          id="Ellipse_8"
                          cx="115"
                          cy="115"
                          r="115"
                          transform="translate(776 851)"
                          fill="#febf91"
                        />
                        <text
                          id="Optimism"
                          transform="translate(794.371 952.638) rotate(-41)"
                          font-size="16"
                          font-family="Roboto-Medium, Roboto"
                          font-weight="500"
                        >
                          <tspan x="0" y="0">Optimism</tspan>
                        </text>
                      </a>
                    </g>
                    <g id="tail-6" className="tails">
                      <a onClick={() => { handleChange('Aggression') }}>
                        <circle
                          className={feelings && feelings.includes('Aggression') ? "disabled-feel" : ""}
                          id="Ellipse_7"
                          cx="115"
                          cy="115"
                          r="115"
                          transform="translate(785 933)"
                          fill="#f0a09e"
                        />
                        <text
                          id="Aggression"
                          transform="matrix(0.105, -0.995, 0.995, 0.105, 816.759, 1097.905)"
                          font-size="16"
                          font-family="Roboto-Medium, Roboto"
                          font-weight="500"
                        >
                          <tspan x="0" y="0">Aggression</tspan>
                        </text>
                      </a>
                    </g>
                    <g id="tail-5" className="tails">
                      <a onClick={() => { handleChange('Contempt') }}>
                        <circle
                          className={feelings && feelings.includes('Contempt') ? "disabled-feel" : ""}
                          id="Ellipse_6"
                          cx="115"
                          cy="115"
                          r="115"
                          transform="translate(840 987)"
                          fill="#b4a1de"
                        />
                        <text
                          id="Contempt"
                          transform="matrix(0.719, 0.695, -0.695, 0.719, 865.342, 1132.07)"
                          font-size="16"
                          font-family="Roboto-Medium, Roboto"
                          font-weight="500"
                        >
                          <tspan x="0" y="0">Contempt</tspan>
                        </text>
                      </a>
                    </g>
                    <g id="tail-4" className="tails">
                      <a onClick={() => { handleChange('Remorse') }}>
                        <circle
                          className={feelings && feelings.includes('Remorse') ? "disabled-feel" : ""}
                          id="Ellipse_5"
                          cx="115"
                          cy="115"
                          r="115"
                          transform="translate(923 991)"
                          fill="#86bbf1"
                        />
                        <text
                          id="Remorse"
                          transform="translate(986.576 1186.389) rotate(16)"
                          font-size="16"
                          font-family="Roboto-Medium, Roboto"
                          font-weight="500"
                        >
                          <tspan x="0" y="0">Remorse</tspan>
                        </text>
                      </a>
                    </g>
                    <g id="tail-3" className="tails">
                      <a onClick={() => { handleChange('Disapproval') }}>
                        <circle
                          className={feelings && feelings.includes('Disapproval') ? "disabled-feel" : ""}
                          id="Ellipse_4"
                          cx="115"
                          cy="115"
                          r="115"
                          transform="translate(991 951)"
                          fill="#86ddf3"
                        />
                        <text
                          id="Disapproval"
                          transform="matrix(0.914, -0.407, 0.407, 0.914, 1102.387, 1165.48)"
                          font-size="16"
                          font-family="Roboto-Medium, Roboto"
                          font-weight="500"
                        >
                          <tspan x="0" y="0">Disapproval</tspan>
                        </text>
                      </a>
                    </g>
                    <g id="tail-3-2" data-name="tail-3" className="tails">
                      <a onClick={() => { handleChange('Awe') }}>
                        <circle
                          className={feelings && feelings.includes('Awe') ? "disabled-feel" : ""}
                          id="Ellipse_3"
                          cx="115"
                          cy="115"
                          r="115"
                          transform="translate(1011 885)"
                          fill="#93d6a9"
                        />
                        <text
                          id="Awe"
                          transform="translate(1202.586 1054.367) rotate(-70)"
                          font-size="16"
                          font-family="Roboto-Medium, Roboto"
                          font-weight="500"
                        >
                          <tspan x="0" y="0">Awe</tspan>
                        </text>
                      </a>
                    </g>
                    <g id="tail-2" className="tails">
                      <a onClick={() => { handleChange('Anxiety') }}>
                        <circle
                          className={feelings && feelings.includes('Anxiety') ? "disabled-feel" : ""}
                          id="Ellipse_2"
                          cx="115"
                          cy="115"
                          r="115"
                          transform="translate(990 807)"
                          fill="#b8dd67"
                        />
                        <text
                          id="Anxiety"
                          transform="translate(1173.309 872.477) rotate(70)"
                          font-size="16"
                          font-family="Roboto-Medium, Roboto"
                          font-weight="500"
                        >
                          <tspan x="0" y="0">Anxiety</tspan>
                        </text>
                      </a>
                    </g>
                    <g id="tail-1" className="tails">
                      <a onClick={() => { handleChange('Submission') }}>
                        <circle
                          className={feelings && feelings.includes('Submission') ? "disabled-feel" : ""}
                          id="Ellipse_1"
                          cx="115"
                          cy="115"
                          r="115"
                          transform="translate(931 772)"
                          fill="#d0e89a"
                        />
                        <text
                          id="Submission"
                          transform="translate(1046.534 796.812) rotate(30)"
                          font-size="16"
                          font-family="Roboto-Medium, Roboto"
                          font-weight="500"
                        >
                          <tspan x="0" y="0">Submission</tspan>
                        </text>
                      </a>
                    </g>
                  </g>
                </g>
              </g>

              <g id="Group_6" data-name="Group 6" className="tails">
                <a onClick={() => { handleChange('Love') }}  >
                  <path
                    className={feelings && feelings.includes('Love') ? "disabled-feel" : ""}
                    id="Subtraction_2"
                    data-name="Subtraction 2"
                    d="M225.733,214.8v0A114.528,114.528,0,0,0,48.981,92,120.986,120.986,0,0,1,63.514,61.166,133.6,133.6,0,0,1,88.647,32.552a124.091,124.091,0,0,1,32.387-20.17,104.8,104.8,0,0,1,37.124-8.369c1.345-.047,2.71-.071,4.058-.071A115.786,115.786,0,0,1,181.4,5.54,114.385,114.385,0,0,1,223.1,21.4,115.342,115.342,0,0,1,266.509,70.51a114.459,114.459,0,0,1,7.45,21.34,116.117,116.117,0,0,1,1.617,46.325,114.385,114.385,0,0,1-15.858,41.7A115.615,115.615,0,0,1,229.759,212c-1.313.956-2.667,1.9-4.024,2.8Z"
                    transform="matrix(1, 0.017, -0.017, 1, 1315.934, 1085.869)"
                    fill="#fff085"
                  />
                  <g id="tail-8" transform="translate(537 310)">
                    <text
                      id="Love"
                      transform="translate(907.219 815.277)"
                      font-size="18"
                      font-family="Roboto-Medium, Roboto"
                      font-weight="500"
                    >
                      <tspan x="0" y="0">Love</tspan>
                    </text>
                  </g>
                </a>
              </g>
            </g>
          </svg>
        </div>
      </div>

    </div>
  );
}

export default PlutchikWheel;