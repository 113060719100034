import React, { forwardRef, useImperativeHandle } from 'react'
import { useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { fetchEmojis, insertEmojis } from '../../redux/actions/storiesAction'
import { timeCalculation } from '../utils/timeUtils'
import { seeProfileInformation } from '../utils/userProfile'
import $ from 'jquery'
import AppConstants from '../../ApplicationConstants'
import socketClient from 'socket.io-client'
import staticContent from '../helpers/staticContent'
// const Sockette = require('sockette');
// import Sockette from 'sockette'
// import { socket, SocketContext } from './context/socket'

const Storyemojis = forwardRef(
  ({ dropDownStatus, setDropDownStatus, ref, ...props }) => {
    // const emojisMaster = JSON.parse(localStorage.getItem('emojisMaster' || []))
    console.log(props.story, 'story')
    const storyId = props.story._id
    const story = props.story
    const dispatch = useDispatch()
    // const getEmojis = useSelector(state => state.users.emojis)

    const [emojis, setEmojis] = useState([])
    const [showNoData, setShowNoData] = useState(false)
    const [seeAllEmojis, setSeeAllEmojis] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const [emojisMaster, setEmojisMaster] = useState([])

    useImperativeHandle(ref, () => ({
      pauseVolume1: val => {
        setSeeAllEmojis(1)
      }
    }))

    //  ********************************************
    //   Get story emojis and emojis master data
    //  ********************************************
    const getStoryEmojis = async storyId => {
      let data = {
        storyId: storyId,
        sLimit: 0,
        eLimit: 1000
      }
      dispatch(
        fetchEmojis(data, res => {
          if (res.data.emojis.length > 0) {
            // setGetEmojis(res.data.emojis)
            setShowNoData(false)
            getEmojisData(res.data.emojis)
          } else {
            let latestEmojis = []
            getEmojisData(latestEmojis)
            setIsLoading(false)
            if (story.userId == localStorage.userId) {
              setShowNoData(true)
            }
          }
        })
      )
    }
    const getEmojisData = async latestEmojis => {
      setEmojis([])
      if (story && story.allowEmojis) {
        setShowNoData(false)
      }
      // let latestEmojis = getEmojis1 && getEmojis1.data.emojis
      if (
        story.allowEmojis == 'private' &&
        latestEmojis &&
        latestEmojis.length == 0
      ) {
        setEmojis([])
        setShowNoData(false)
      } else if (latestEmojis && latestEmojis.length == 0) {
        setEmojis([])
        setShowNoData(true)
        setIsLoading(false)
      } else {
        timeCalculation(latestEmojis && latestEmojis).then(res => {
          setIsLoading(false)
          setEmojis(res)
          setShowNoData(false)
        })
      }
    }
    const getEmojisDataFromLStorage = async storyId => {
      setEmojis([])
      setShowNoData(false)
      setDropDownStatus(!dropDownStatus)
      let emojis = await JSON.parse(localStorage.getItem('emojisMaster' || []))
      setEmojisMaster(emojis)
      getStoryEmojis(storyId)
    }
    // useEffect(() => {
    //   setShowNoData(false)
    //   getEmojisDataFromLStorage(storyId)
    // }, [])

    const configureSocket = () => {
      var socket = socketClient(
        'wss://iyd7fnycec.execute-api.ap-south-1.amazonaws.com/dev',
        {
          Headers: {
            Authorization: `Bearer ${localStorage.accessToken}`,
            universityId: `${localStorage.universityId}`,
            userId: `${localStorage.userId}`
          },
          withCredentials: true,
          transports: ['websocket', 'polling'] // use WebSocket first, if available
          // cors: {
          //   origin: "*",
          //   methods: ["GET", "POST"]
          // }
        }
      )

      console.log(socket, 'socket')
      socket.on('connect', () => {
        console.log('connection established')
      })
      socket.on('commentcreated', message => {
        console.log({ message })
      })
    }

    //  ********************************************
    //         Submit Emoji functionality
    //  ********************************************
    const postEmojiData = async (
      postEmojiContent,
      postEmojiId,
      icon,
      emoji,
      title
    ) => {
      setIsLoading(true)
      let data = {
        userId: localStorage.userId,
        storyId: storyId,
        universityId: localStorage.universityId,
        content: postEmojiContent,
        emojiMasterId: postEmojiId,
        icon: icon,
        emoji: emoji,
        title: title
      }
      dispatch(
        insertEmojis(data, (res, error) => {
          if (res.status == 'success') {
            getStoryEmojis(storyId)
          } else return error
        })
      )
    }

    //  ********************************************
    //         See all emojis functionality
    //  ********************************************
    const seeAllEmojiResponse = value => {
      if (value == 2) {
        var elmnt = document.getElementById('scroll2')
        elmnt.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
        // myRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "end"});
        setSeeAllEmojis(1)
      } else {
        setSeeAllEmojis(2)
      }
    }

    const moveToProfileDetails = id => {
      seeProfileInformation(id)
    }
    console.log(story, 'story324683264')
    return (
      <div>
        {dropDownStatus ? (
          <div className='smily-comment'>
            <div className='scrollbar'>
              {story && !story.responseTypes.includes('emojis') ? (
                ''
              ) : (
                <div className='select-reaction'>
                  <h6 className='mb-3'>{staticContent.emo_title_cnt}</h6>

                  <div
                    className={
                      seeAllEmojis == 2 ? 'moreContent' : ' lessContent'
                    }
                  >
                    {story && !story.responseTypes.includes('emojis')
                      ? ''
                      : emojisMaster &&
                        emojisMaster.map((obj, index) => {
                          return (
                            <div className='cont me-3 mb-2' id='scroll2'>
                              <div
                                className='d-flex align-items-center border-0'
                                key={index}
                                onClick={() => {
                                  postEmojiData(
                                    `${obj.emoji} ${obj.title}`,
                                    `${obj._id}`,
                                    `${obj.icon}`,
                                    `${obj.emoji}`,
                                    `${obj.title}`
                                  )
                                }}
                              >
                                {obj.emoji}
                                <p className='mb-0'>{obj.title}</p>
                              </div>
                            </div>
                          )
                        })}
                  </div>
                  <div className='my-2'>
                    <span
                      className='smileToggle mb-2 mt-2'
                      onClick={() => seeAllEmojiResponse(seeAllEmojis)}
                    >
                      {seeAllEmojis == 2 ? 'Show less' : 'See All'}
                    </span>
                  </div>
                </div>
              )}
              {isLoading ? <div className='StoryEmojis loading' /> : ''}
              <div className='responses'>
                <h6>{staticContent.emo_resp_cnt}</h6>
                <div>
                  {showNoData ? (
                    <div>
                      <p className='noResponseMessage'>
                        {staticContent.no_resps_cnt}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {story &&
                  story.allowEmojis == 'private' &&
                  story.userId !== localStorage.userId ? (
                    <div>
                      <p className='noResponseMessage'>
                        {staticContent.auth_can_see_rctns}
                      </p>
                    </div>
                  ) : emojis && emojis ? (
                    emojis.map((e, index) => {
                      return (
                        <div key={index}>
                          <div className='card-text mt-2 mb-0 d-flex'>
                            {/* <i className="bi bi-person-circle"></i> */}
                            <div className='userImg me-2'>
                              <a onClick={() => moveToProfileDetails(e.userId)}>
                                <img
                                  src={
                                    (e.userProperties[0] &&
                                      e.userProperties[0].profileImg ==
                                        undefined) ||
                                    (e.userProperties[0] &&
                                      e.userProperties[0].profileImg == '')
                                      ? 'https://www.careerquo.com/assets/images/18.png'
                                      : e.userProperties[0] &&
                                        e.userProperties[0].profileImg
                                  }
                                  width='100%'
                                  height='100%'
                                  alt=''
                                />
                              </a>
                            </div>
                            <div>
                              <h6 className='mb-0'>
                                <a
                                  onClick={() => moveToProfileDetails(e.userId)}
                                >
                                  {' '}
                                  {e.userProperties[0] &&
                                  e.userProperties[0].firstName == ''
                                    ? 'xxx'
                                    : e.userProperties[0] &&
                                      e.userProperties[0].firstName}{' '}
                                  {e.userProperties[0] &&
                                  e.userProperties[0].lastName == ''
                                    ? 'yyy'
                                    : e.userProperties[0] &&
                                      e.userProperties[0].lastName}
                                </a>
                              </h6>
                              <p className='card-text mb-2'>{e.commentedOn}</p>
                              <div className='responses-cont'>
                                <div className='d-flex align-items-center'>
                                  {/* <div className="emoji-icons">
                                    <img src="images/New folder/like.png" width="100%"
                                        height="100%" />
                                </div> */}
                                  <p className='mb-2'>{e.content}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        {story &&
        story.responseTypes.includes('emojis') &&
        ['approved', 'aws_pending'].includes(story && story.storyStatus) ? (
          <div className=''>
            <button
              type='button'
              className='border-0 bg-transparent trianglePostion'
              onClick={() => getEmojisDataFromLStorage(storyId)}
            >
              <span>
                <i className='bi bi-emoji-smile smileRes'></i>
              </span>
              {dropDownStatus ? (
                <div className='triangle-arrow'>
                  <i className='bi bi-triangle-fill'></i>
                </div>
              ) : (
                ''
              )}
            </button>
            {/* <div className='responseCloseLink1' >
              <Storyemojis
                ref={resetEmojis}
                story={showSingleStoryPlayer ? story[2] && story[2] : story[0] && story[0]}
              />
            </div> */}
          </div>
        ) : (
          <span className='disableReactionsAction'>
            <i className='bi bi-emoji-smile smileRes'></i>
          </span>
        )}
      </div>
    )
  }
)
const mapStateToProps = state => {
  return {
    emojis: state.users.emojis
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchEmojis: () => dispatch(fetchEmojis())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Storyemojis)
