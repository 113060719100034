import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {
  reportStory,
  deleteStory,
  setUploadedEditStoryInReduxStore
}
  from '../../redux/actions/storiesAction'
import swal from 'sweetalert';
import MSGContent from '../helpers/messageContent';
import staticContent from '../helpers/staticContent';

function Storymoreicon({ dropDownStatus, setDropDownStatus, ...props }) {
  var storyId = props.story._id
  var story = props.story
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const dispacth = useDispatch();

  const [userId, setUserId] = useState(localStorage.userId)
  const { reportedStory } = useSelector(store => store.users);




  //  ********************************************
  //         Report story functionality
  //  ********************************************
  const ReportingStory = async (storyId) => {
    swal({
      content: {
        element: "input",
        attributes: {
          placeholder: `${MSGContent.report_story_placeholder_msg}`,
          type: "text",
          required: true,
        },
      },
      text: `${MSGContent.report_story_title_msg}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((inputValue) => {
      if (inputValue.trim() == "") {
        swal({
          text: `${MSGContent.report_story_alert_msg}`,
          icon: "error"
        })
      }
      else if (inputValue.trim() == null) {
      }
      else {
        let userId = localStorage.getItem('userId')
        let data = {
          userId: userId,
          universityId: localStorage.universityId,
          reportHistory: {
            storyId: storyId,
            userId: userId,
            comments: inputValue
          }
        }
        dispatch(reportStory(data, (callback) => {
          if (callback.status == "success") {
            swal({
              text: `${MSGContent.report_story_success_msg}`,
              icon: "success",
            })
            props.removeReportedStory(storyId)
          }
          else {
            swal({
              text: `${MSGContent.report_story_fail_msg}`,
              icon: "success",
            })
          }
        }))
      }
    })
  }



  //  ********************************************
  //         Edit story functionality
  //  ********************************************
  const editUserOwnStory = (storyId) => {
    let data = {
      uploadedStoryEdit: true,
      editStoryId: storyId
    }
    dispacth(setUploadedEditStoryInReduxStore(data))
    navigate('/Y3JlYXRlc3Rvcmllcw==',
    )
  }


  //  ********************************************
  //         Delete story functionality
  //  ********************************************
  const deleteUserOwnStory = (storyId) => {
    swal({
      // title: "Are you sure?",
      text: "Are you sure you want to delete this story ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(deleteStory(storyId, res => {
            if (res.status == "success") {
              swal({
                text: `${MSGContent.story_delete_success_msg}`,
                icon: "success",
              })
              props.removeReportedStory(storyId)
            }
            else {
              swal({
                text: `${MSGContent.story_delete_fail_msg}`,
                icon: "success",
              })
            }
          }))
        } else {
        }
      });
  }

  const showMoreScreen = () => {
    setDropDownStatus(!dropDownStatus)
  }
  return (
    <div>
      {
        dropDownStatus ? <div className='report-comment'>
          <p className='mb-0' onClick={() => { ReportingStory(storyId) }}>
            <span className={userId == story.userId ? "disableAction" : ""}>
              <i className='bi bi-exclamation-circle'></i> {staticContent.more_report_cnt}
            </span>
          </p>

          <p className='mb-0' onClick={() => { editUserOwnStory(storyId) }}>
            <span className={userId == story.userId ? "" : "disableAction"}>

              <i class="bi bi-pencil-square"></i> {staticContent.more_edit_cnt}
            </span>
          </p>
          <p className='mb-0' onClick={() => { deleteUserOwnStory(storyId) }}>
            <span className={userId == story.userId ? "" : "disableAction"}>
              <i class="bi bi-trash3"></i> {staticContent.more_del_cnt}
            </span>
          </p>
        </div> : ""
      }



      {
        (
          ["approved", "aws_pending"].includes(story && story.storyStatus)) ?
          <div className=''>
            <button
              type='button'
              className='border-0 bg-transparent trianglePostion'
              onClick={() => showMoreScreen()}
            >
              <span>
                <i className='bi bi-three-dots-vertical moreRes'></i>
              </span>
              {
                dropDownStatus ? <div className="triangle-arrow ">
                  <i className="bi bi-triangle-fill"></i>
                </div> : ""
              }

            </button>
            <div className=''>
            </div>
          </div>
          :
          <span className='disableReactionsAction'>
            <i className='bi bi-three-dots-vertical moreRes'></i>
          </span>
      }
    </div>
  )
}

export default Storymoreicon
