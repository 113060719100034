import React, { useEffect, useState } from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input
} from 'reactstrap'
import AppConstants from '../../ApplicationConstants'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getProfileOrganizationsList } from '../../redux/actions/storiesAction'
const UpsquadHeader = ({
  sendDataToPlayStories,
  sendDataToCreateStories,
  identifyKey,
  sendDataToPlayStoriesStoriesStatus
}) => {
  const [userOrganisations, setUserOrganisations] = useState([])
  const [loginUniversityId, setLoginUniversityId] = useState([
    localStorage.universityId
  ])
  const [dmsEnabled, setDmsEnabled] = useState(
    localStorage.dmsEnabled
  )

  const [isJobsEnabled, setJobsEnabled] = useState(
    localStorage.isJobsEnabled
  )

  const [socialPgeEnableStatus, setSocialPgeEnableStatus] = useState(
    localStorage.socialPgeEnableStatus
  )
  const dispatch = useDispatch()

  const profileSettingsList = useSelector(
    state => state.users.profileOrganizationsList
  )
  useEffect(() => {
    let data1 = {}
    dispatch(getProfileOrganizationsList(data1, async res => {
      let loginCommunity = await res.data.filter((obj, index) => {
        return obj._id == loginUniversityId
      })
      console.log(loginCommunity[0].isJobsEnabled)
      localStorage.setItem('dmsEnabled', loginCommunity[0].dmsEnabled)
      localStorage.setItem('isJobsEnabled', loginCommunity[0].isJobsEnabled)
      localStorage.setItem('socialPgeEnableStatus', loginCommunity[0].socialPgeEnableStatus)
    }))
  }, [])
  useEffect(() => {
    getSignOrganisation(profileSettingsList)
  }, [profileSettingsList && profileSettingsList])
  const getSignOrganisation = async profileSettingsList => {
    let orgs =
      (await profileSettingsList) &&
      profileSettingsList.data.length > 0 &&
      profileSettingsList.data
      console.log(orgs)
    setUserOrganisations(orgs)
  }
  const handleChangeImage = (logo, id, dmsEnabled, isJobsEnabled, socialPgeEnableStatus, isAdmin) => {
    setDmsEnabled(dmsEnabled)
    setJobsEnabled(isJobsEnabled)
    setSocialPgeEnableStatus(socialPgeEnableStatus)
    localStorage.setItem('isAdmin', isAdmin)
    localStorage.setItem('dmsEnabled', dmsEnabled)
    localStorage.setItem('isJobsEnabled', isJobsEnabled)
    localStorage.setItem('socialPgeEnableStatus', socialPgeEnableStatus)
    localStorage.setItem('universityId', id)
    setLoginUniversityId([id])
    if (identifyKey == 'createStories') {
      sendDataToCreateStories(id)
    } else {
      sendDataToPlayStories(id)
      sendDataToPlayStoriesStoriesStatus("All Stories")
    }
  }
  return (
    <div className='header-fix'>
      <header >
        <nav className='navbar navbar-expand-lg navbar-light bg-white '>
          <div className='container-fluid'>
            <div className='logoDropdown'>
              <UncontrolledDropdown>
                {userOrganisations &&
                  (userOrganisations.length == 0 ||
                    userOrganisations.length == undefined) ? (
                  ''
                ) : (
                  <DropdownToggle caret isOpen={true}>
                    {loginUniversityId != ''
                      ? userOrganisations != undefined &&
                      userOrganisations.length > 0 &&
                      userOrganisations.map((res, id) => {
                        return loginUniversityId.find(o => o == res._id) ? (
                          res.universityLogo != '' ? (
                            <div className='community-logos'>
                              <img
                                src={res.universityLogo}
                                width='100%'
                                height='100%'
                              />
                            </div>
                          ) : (
                            // res.universityName.toUpperCase()
                            <h4>{res.universityName.toUpperCase()}</h4>
                          )
                        ) : (
                          ''
                        )
                      })
                      : ''}
                  </DropdownToggle>
                )}

                <DropdownMenu>
                  {userOrganisations != undefined &&
                    userOrganisations.length > 0 &&
                    userOrganisations.map((res, id) => {
                      return (
                        res.universityLogo != '' &&
                          res.socialPgeEnableStatus == true ? (
                          <DropdownItem
                            value={res.universityLogo}
                            onClick={() =>
                              handleChangeImage(res.universityLogo, res._id, res.dmsEnabled, res.isJobsEnabled, res.socialPgeEnableStatus, res.subscriptionsData.isAdmin)
                            }
                            dropDownValue={res._id}
                          >
                            <div className='community-list-logos'>
                              <img
                                src={res.universityLogo}
                                width='100%'
                                height='100%'
                              />
                            </div>
                          </DropdownItem>
                        ) : // <img src={res.universityLogo} className="img-fluid" />
                          res.socialPgeEnableStatus == true &&
                            res.universityLogo == '' ? (
                            <DropdownItem
                              value={res.universityLogo}
                              onClick={() =>
                                handleChangeImage(res.universityLogo, res._id, res.dmsEnabled, res.isJobsEnabled, res.socialPgeEnableStatus)
                              }
                              dropDownValue={res._id}
                            >
                              {/* <img src={res.universityLogo} className="img-fluid" /> */}{' '}
                              <h4>{res.universityName.toUpperCase()}</h4>
                            </DropdownItem>
                          ) : // <img src={res.universityLogo} className="img-fluid" />
                            res.socialPgeEnableStatus == true ? (
                              <DropdownItem
                                value={res.universityLogo}
                                onClick={() =>
                                  handleChangeImage(res.universityLogo, res._id, res.dmsEnabled, res.isJobsEnabled, res.socialPgeEnableStatus)
                                }
                                dropDownValue={res._id}
                              >
                                {' '}
                                <h4>{res.universityName.toUpperCase()}</h4>
                              </DropdownItem>
                            ) : (
                              ''
                            )
                      )
                    })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <button
              className='navbar-toggler'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon'></span>
            </button>
            <div
              className='collapse navbar-collapse'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
                <li className='nav-item'>
                  <div
                    className='nav-link '
                    aria-current='page'
                    href=''
                  >
                    <div className='nav-icons'>
                      {/* <i className="bi bi-circle"></i> */}
                      {/* <i className="far fa-circle"></i> */}
                      <div className='blooming'>
                        <img
                          src='assets/images/bloomingIcon.png'
                          width='100%'
                          height='100%'
                          alt=''
                        />
                      </div>
                    </div>
                    <span>Blooming</span>
                  </div>
                </li>
                {localStorage.socialPgeEnableStatus == "true" ?
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      href={
                        AppConstants.SOCIAL_DEV_URL +
                        `/?` +
                        localStorage.userId +
                        `&` +
                        localStorage.accessToken +
                        `&` +
                        localStorage.isAdmin +
                        `&` +
                        localStorage.universityId +
                        `&` +
                        localStorage.isUpsquadStatus +
                        `&` +
                        localStorage.refreshToken
                      }
                    >
                      <div className='nav-icons'>
                        {/* <i className="bi bi-house-door-fill"></i> */}
                        <i className='fas fa-home'></i>
                      </div>
                      <span>Social</span>
                    </a>
                  </li>
                  : ""}

                <li className='nav-item'>
                  <a
                    className='nav-link'
                    href={
                      AppConstants.BASE_DEV_URL + `/EIS/discover`
                      // + localStorage.universityId
                    }
                  >
                    <div className='nav-icons'>
                      {/* <i className="bi bi-people-fill"></i> */}
                      <i className='fas fa-users'></i>
                    </div>
                    <span>People</span>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    href={
                      AppConstants.MESSAGE_DEV_URL +
                      `/?` +
                      localStorage.userId +
                      `&` +
                      localStorage.accessToken +
                      `&` +
                      localStorage.isAdmin +
                      `&` +
                      localStorage.universityId +
                      `&` +
                      localStorage.isUpsquadStatus +
                      `&` +
                      localStorage.refreshToken
                    }
                  >
                    <div className='nav-icons'>
                      {/* <i className="bi bi-chat"></i> */}
                      {/* <i className='far fa-comments'></i> */}
                      <i class="far fa-comments"></i>
                    </div>
                    <span>Messages</span>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    href={
                      AppConstants.BASE_DEV_URL +
                      `/sessionDetails/adminDomain/` +
                      // localStorage.userId +
                      // `&` +
                      // localStorage.accessToken +
                      // `&` +
                      // localStorage.isAdmin +
                      // `&` +
                      localStorage.universityId
                      // `&` +
                      // localStorage.isUpsquadStatus +
                      // `&` +
                      // localStorage.refreshToken
                    }
                  >
                    <div className='nav-icons'>
                      {/* <i className="bi bi-camera-video-fill"></i> */}
                      <i className='bi bi-camera-video'></i>
                    </div>
                    <span>Meetings</span>
                  </a>
                </li>
                {
                  localStorage.dmsEnabled == "true" ?
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        href={
                          AppConstants.FILES_DEV_URL +
                          `/?` +
                          localStorage.userId +
                          `&` +
                          localStorage.accessToken +
                          `&` +
                          localStorage.isAdmin +
                          `&` +
                          localStorage.universityId +
                          `&` +
                          localStorage.isUpsquadStatus +
                          `&` +
                          localStorage.refreshToken
                        }
                      >
                        <div className='nav-icons'>
                          {/* <i className="bi bi-calendar3"></i> */}
                          {/* <i className='far fa-file'></i> */}
                          <i class="fas fa-file-signature"></i>
                        </div>
                        <span>Files</span>
                      </a>
                    </li>
                    : ""
                }

                <li className='nav-item'>
                  <a
                    className='nav-link'
                    href={
                      AppConstants.BASE_DEV_URL + `/events-list`
                      // localStorage.userId +
                      // `&` +
                      // localStorage.accessToken +
                      // `&` +
                      // localStorage.isAdmin +
                      // `&` +
                      // localStorage.universityId +
                      // `&` +
                      // localStorage.isUpsquadStatus +
                      // `&` +
                      // localStorage.refreshToken
                    }
                  >
                    <div className='nav-icons'>
                      {/* <i className="bi bi-calendar3"></i> */}
                      <i className='far fa-calendar-alt'></i>
                    </div>
                    <span>Events</span>
                  </a>
                </li>
                {
                  localStorage.isJobsEnabled == 'true' ?
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        href={
                          AppConstants.BASE_DEV_URL + `/job/jobs-list`
                        }
                      >
                        <div className='nav-icons'>
                          {/* <i className="bi bi-calendar3"></i> */}
                          {/* <i className='far fa-file'></i> */}
                          <i class="fas fa-briefcase"></i>
                        </div>
                        <span>Jobs</span>
                      </a>
                    </li>
                    : ""
                }
                <li className='nav-item dropdown'>
                  <a
                    className='nav-link dropdown-toggle'
                    href='https://dev.upsquad.com/'
                    id='navbarDropdown'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <div className='nav-icons'>
                      {/* <i className="bi bi-person-fill"></i> */}
                      {/* <i className='fas fa-user'></i> */}
                      <i class="fas fa-user"></i>
                    </div>
                    <span className='fw-normal'>
                      {' '}
                      {localStorage.fName} {localStorage.lName}
                    </span>
                  </a>
                  <ul
                    className='dropdown-menu'
                    aria-labelledby='navbarDropdown'
                  >
                    <li>
                      {localStorage.isProfessional == 'true' ? (
                        <a
                          className='dropdown-item'
                          href={
                            AppConstants.BASE_DEV_URL +
                            `/professional-profile/adminDomain/` +
                            localStorage.universityId
                          }
                        >
                          My Profile
                        </a>
                      ) : (
                        <a
                          className='dropdown-item'
                          href={
                            AppConstants.BASE_DEV_URL +
                            `/student-profile/adminDomain/` +
                            localStorage.universityId
                          }
                        >
                          My Profile
                        </a>
                      )}
                    </li>
                    <li>
                      <a
                        className='dropdown-item'
                        href={
                          AppConstants.BASE_DEV_URL +
                          `/user-account/adminDomain/` +
                          localStorage.universityId
                        }
                      >
                        Edit Profile
                      </a>
                    </li>
                    <li>
                      <a
                        className='dropdown-item'
                        href={
                          AppConstants.BASE_DEV_URL + `/calendar-connections`
                        }
                      >
                        Sync Calendar
                      </a>
                    </li>
                    <li>
                      <a
                        className='dropdown-item'
                        href={
                          AppConstants.BASE_DEV_URL +
                          `/set-availability/` +
                          localStorage.userId
                        }
                      >
                        Set Availability
                      </a>
                    </li>
                    <li>
                      <a
                        className='dropdown-item'
                        href={AppConstants.BASE_DEV_URL + `/settings`}
                      >
                        Settings
                      </a>
                    </li>
                    <li>
                      <a
                        className='dropdown-item'
                        href={AppConstants.BASE_DEV_URL + `/auth/login`}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}
export default UpsquadHeader
