import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
    getUserSettingsFromProfile,//User profile data
    getProfileOrganizationsList,// User organisations data
    visibilitySettingsObjectData,// If story settings(visibility settings) edit
    storeDataObjectData //While edit store story data in Redux store
} from '../../redux/actions/storiesAction'
import UpsquadHeader from '../layouts/header'

const Storysettings = props => {
    const [selectTeam, setSelectTeams] = useState([]); // total user active teams array
    const [selectedTeams, setSelectedTeams] = useState([])
    const [accessTo, setAccessTo] = useState() //who can see(access) my post
    const [allowEmojis, setAllowEmojis] = useState("") //who can see responses (public or private)
    const [allowComments, setAllowComments] = useState("") //who can see response (public or private) 
    const [allowVideos, setAllowVideos] = useState('') //who can see responses (public or private)
    const [allowSharing, setAllowSharing] = useState(false)
    const [emojis, setEmojis] = useState() // reaction for the story
    const [comments, setComments] = useState() // reaction for the story
    const [videos, setVideos] = useState() // reaction for the story
    const [responseTypes, setResponseTypes] = useState([]) //comments,emojis,videos
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //User Organisations data
    const profileOrganizationsList = useSelector(
        state => state.users.profileOrganizationsList
    )
    // While edit store story data in Redux store
    const storyObjectData = useSelector(
        state => state.users.storyObjectData
    )
    // If story settings(visibility settings) edit
    const visibilitySettingsObject = useSelector(
        state => state.users.storeVisibilitySettingsStoryObjectDataSuccess
    )
    const teamsArrToCheck = visibilitySettingsObject && visibilitySettingsObject.selectedTeamsEdit


    /**  **************************************************
               Get Profile and organisations data 
         ************************************************** */
    const demofun = async (storyObjectData) => {
        if (visibilitySettingsObject && visibilitySettingsObject.isEdit == false) {
            const { accessTo, allowComments, allowEmojis, allowVideos, comments, emojis, videos } = storyObjectData
            setAllowEmojis(allowEmojis)
            setAllowComments(allowComments)
            setAllowVideos(allowVideos)
            setAccessTo(accessTo)
            setEmojis(emojis)
            setComments(comments)
            setVideos(videos)
            await getCommunityTeams(profileOrganizationsList && profileOrganizationsList)
            setIsLoading(false)
        } else if (visibilitySettingsObject && visibilitySettingsObject.isEdit == true) {
            const {
                allowEmojisEdit,
                selectedTeamsEdit,
                allowCommentsEdit,
                allowVideosEdit,
                allowSharingEdit,
                accessToEdit,
                responseTypesEdit,
            } = visibilitySettingsObject
            setSelectedTeams(selectedTeamsEdit)
            setAllowEmojis(allowEmojisEdit)//who can see response (public or private)
            setAllowComments(allowCommentsEdit)//who can see response (public or private)
            setAllowVideos(allowVideosEdit)//who can see response (public or private)
            setAccessTo(accessToEdit)
            setEmojis(emojis)
            setComments(comments)
            setVideos(videos)
            setAllowSharing(allowSharingEdit)
            if (responseTypesEdit.length > 0) {
                await responseTypesEdit.map(async obj => {
                    return obj == '1'
                        ? setComments(1)
                        : obj == '2'
                            ? setEmojis(2)
                            : obj == '3'
                                ? setVideos(3)
                                : ''
                })
            }
            if (accessToEdit == 'team') {
                let data2 = ""
                dispatch(getProfileOrganizationsList(data2, async res => {
                    await getCommunityTeams(res.data)
                }))
            }
            else {
                getCommunityTeams(profileOrganizationsList.data)
                setIsLoading(false)
            }
        }
        else {
            let data1 = ""
            dispatch(getUserSettingsFromProfile(data1, async data => {
                let Storysettings = await data.status == false ? "" :
                    data && data.data[0].visibilitySettings.Story
                setAllowEmojis(Storysettings == "" ? 'private' : Storysettings.responsesEmoji == '1' ? 'public' : 'private')
                setAllowComments(Storysettings == "" ? 'private' : Storysettings.responsesComment == '1' ? 'public' : 'private')
                setAllowVideos(Storysettings == "" ? 'private' : Storysettings.responsesVideo == '1' ? 'public' : 'private')
                if (Storysettings == "") {
                    setAccessTo("private")
                }
                else {
                    setAccessTo(
                        Storysettings.storyPost == '1'
                            ? 'private'
                            : Storysettings.storyPost == '3'
                                ? 'public'
                                : 'team'
                    )
                }
                if (Storysettings == "") {
                }
                else {
                    await Storysettings.responsesStory.map(async obj => {
                        return obj == '1'
                            ? setComments('1')
                            : obj == '2'
                                ? setEmojis('2')
                                : obj == '3'
                                    ? setVideos('3')
                                    : ''
                    })
                }
            }))
            let data2 = ""
            dispatch(getProfileOrganizationsList(data2, async res => {
                await getCommunityTeams(res.data)
            }))
        }
    }

    // Get squads 
    const getCommunityTeams = async (profileOrgData) => {
        let universityId = localStorage.getItem('universityId');
        let communityProps = await profileOrgData.filter(obj => obj._id == universityId)
        let teamArr =
            (await communityProps) &&
            communityProps[0].subscriptionsData.teamDetails.filter(obj => {
                return obj.isChecked == true
            })
        let selectTeam = await communityProps[0].teamsData.filter(el => {
            return teamArr.find(element => {
                return element.teamId === el._id;
            });
        });

        // Remove duplicate squads
        let uniq = {};
        let arrFiltered = selectTeam.filter(obj => !uniq[obj._id] && (uniq[obj._id] = true));
        setSelectTeams(arrFiltered)
        setIsLoading(false)
    }



    /**   *****************************************************
            Get Profile and organisations data functionality
          *****************************************************   */
    const getData = async () => {
        dispatch(getUserSettingsFromProfile())
        dispatch(getProfileOrganizationsList())
    }
    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        demofun(storyObjectData)
    }, [storyObjectData])




    /**  **************************************************
                Update state functionality
         **************************************************  */
    const handleChangeTeams = async (obj, event) => {
        if (event) {
            setSelectedTeams((prevstate) => {
                return [...prevstate, ...[obj._id]]
            })
        }
        else {
            await removeItem(selectedTeams, obj);
            async function removeItem(selectedTeams, obj) {
                let team = await selectedTeams.filter(f => {
                    return f !== obj._id
                })
                setSelectedTeams(team)
            }
        }
    }
    const handleChangeEmojis = async (res, event) => {
        if (event) {
            setEmojis(res);
        } else {
            setEmojis('');
        }
    }
    const handleChangeComments = async (res, event) => {
        if (event) {
            setComments(res);
        } else {
            setComments('');
        }
    }
    const handleChangeVideos = async (res, event) => {
        if (event) {
            setVideos(res);
        } else {
            setVideos('');
        }
    }
    const handleChange = () => {
        setAllowSharing(!allowSharing);
    }
    const handleChangeAccessTo = (value) => {
        if (value == "private" || value == "pubilc") {
            setAccessTo(value)
            setSelectedTeams([])
        }
        else {
            setAccessTo(value)
        }
    }



    /**  **************************************************
           Submit story visibility settings functionality
         **************************************************  */
    const handleSubmitStorySettings = async (e) => {
        e.preventDefault();
        responseTypes.push(emojis, comments, videos)
        var filtered = responseTypes.filter(function (el) {
            return el != '';
        });
        const { title, description, video, feelings, isAnonymous, uploadedEditStoryURL, uploadedStoryEditStatus } = storyObjectData
        let settingObj = {
            title: title,
            description: description,
            video: video,
            uploadedEditStoryURL: uploadedEditStoryURL,
            feelings: feelings,
            isAnonymous: isAnonymous,
            selectedTeamsEdit: selectedTeams,
            allowEmojisEdit: allowEmojis,
            allowCommentsEdit: allowComments,
            allowVideosEdit: allowVideos,
            allowSharingEdit: allowSharing,
            responseTypesEdit: typeof filtered[0] == 'undefined' ? [] : filtered,
            accessToEdit: accessTo,
            isEdit: true,
            uploadedStoryEditStatus: uploadedStoryEditStatus
        }
        dispatch(storeDataObjectData(settingObj))
        dispatch(visibilitySettingsObjectData(settingObj));
        navigate('/Y3JlYXRlc3Rvcmllcw==');
        setResponseTypes([])
        window.history.replaceState({}, document.title)
    }


    /**  **************************************************
              Without Edit Move back to creat stories
         **************************************************  */
    const moveBackToCreateStoryScreen = async (e) => {
        e.preventDefault();
        responseTypes.push(emojis, comments, videos)
        var filtered = responseTypes.filter(function (el) {
            return el != '';
        });
        const { title, description, video, feelings, isAnonymous, uploadedEditStoryURL, uploadedStoryEditStatus } = storyObjectData
        let settingObj = {
            title: title,
            description: description,
            video: video,
            uploadedEditStoryURL: uploadedEditStoryURL,
            feelings: feelings,
            isAnonymous: isAnonymous,
            selectedTeams: selectedTeams,
            allowEmojis: allowEmojis,
            allowComments: allowComments,
            allowVideos: allowVideos,
            allowSharing: allowSharing,
            responseTypes: typeof filtered[0] == 'undefined' ? [] : filtered,
            accessTo: accessTo,
            isEdit: uploadedStoryEditStatus == true ? true : false,
            uploadedStoryEditStatus: uploadedStoryEditStatus
        }
        dispatch(storeDataObjectData(settingObj));
        navigate('/Y3JlYXRlc3Rvcmllcw==');
        setResponseTypes([])
        window.history.replaceState({}, document.title)
    }
    return (
        <div>
            <UpsquadHeader />
            {
                isLoading ? (<div className='playtStories loading' />) :

                    <div className="storySettingsPage">
                        <div className=" ourStorySettings">
                            <div className="ps-5 py-4">
                                <span className="backArrowButton" onClick={(e) => { moveBackToCreateStoryScreen(e) }}>
                                    <i className="bi bi-arrow-left"></i>
                                </span>
                            </div>
                            <div className="ourStorySetBlock">
                                <div className="create-story-left">
                                    <div className="create-story-head">
                                        <h6>Choose who can see and react to your story.</h6>
                                        <div>
                                            <p>Who can see my Story?</p>
                                        </div>
                                    </div>
                                    <div onChange={(e) => handleChangeAccessTo(e.target.value)}>
                                        <div className="addMyPost">
                                            <div>
                                                <h6>Public</h6>
                                                <div>
                                                    {" "}
                                                    <p>Anyone on UpSquad</p>
                                                </div>
                                            </div>
                                            <label className="custom_radio">
                                                <input
                                                    type="radio"
                                                    name="accessTo"
                                                    value="public"
                                                    checked={accessTo === "public"}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        <div className="addMyPost">
                                            <div>
                                                <h6>Private</h6>
                                                <div>
                                                    {" "}
                                                    <p>Only within the Community</p>
                                                </div>
                                            </div>
                                            <label className="custom_radio">
                                                <input
                                                    type="radio"
                                                    name="accessTo"
                                                    value="private"
                                                    checked={accessTo === "private"}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        <div className="addMyPost">
                                            <div>
                                                <h6>Specific Squads</h6>
                                                <div>
                                                    <p>Only with Squads within the Community</p>
                                                </div>
                                            </div>
                                            <label className="custom_radio">
                                                <input
                                                    type="radio"
                                                    name="accessTo"
                                                    value="team"
                                                    checked={accessTo === "team"}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {accessTo == "team" ?
                                        <div>
                                            <div className="create-story-head">
                                                <h6>Select your team(s)</h6>
                                            </div>
                                            <div className="selectYourTeams">
                                                {selectTeam &&
                                                    selectTeam.map((obj, index) => {
                                                        return (
                                                            <label className="custom_check">
                                                                <input
                                                                    type="checkbox"
                                                                    defaultChecked={teamsArrToCheck && teamsArrToCheck.includes(obj._id) ? true : false}
                                                                    onChange={(e) =>
                                                                        handleChangeTeams(obj, e.target.checked)
                                                                    }
                                                                />
                                                                <span className="checkmark"></span>
                                                                <h6 key={index}>{obj.teamTitle}</h6>
                                                            </label>
                                                        );
                                                    })}
                                            </div>{" "}
                                        </div>
                                        : (
                                            ""
                                        )}

                                    {accessTo && accessTo == "public" ? (
                                        <div className="responses-MyStory d-flex justify-content-between mt-2">
                                            <div className="responses-MyStory-cont">
                                                <h6>Allow Sharing</h6>
                                                <p>
                                                    Allow my story to be shared on other social media
                                                    platforms
                                                </p>
                                            </div>
                                            <div>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexSwitchCheckDefault"
                                                        name="allowSharing"
                                                        checked={allowSharing}
                                                        onChange={(e) => {
                                                            handleChange();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    <div className="create-story-head">
                                        <h6>What type of responses do I want?</h6>
                                    </div>

                                    <div className="selectYourTeams">
                                        <form action="">
                                            <label className="custom_check">
                                                <input
                                                    type="checkbox"
                                                    name="emojis"
                                                    checked={emojis == "2" ? "checked" : ""}
                                                    onChange={(e) => {
                                                        handleChangeEmojis("2", e.target.checked);
                                                    }}
                                                />
                                                <span className="checkmark"></span>
                                                <h6>Emojis</h6>
                                            </label>

                                            <label className="custom_check">
                                                <input
                                                    type="checkbox"
                                                    name="comments"
                                                    checked={comments == "1" ? "checked" : ""}
                                                    onChange={(e) => {
                                                        handleChangeComments("1", e.target.checked);
                                                    }}
                                                />
                                                <span className="checkmark"></span>
                                                <h6>Comments</h6>
                                            </label>

                                            <label className="custom_check">
                                                <input
                                                    type="checkbox"
                                                    name="videos"
                                                    value="videos"
                                                    checked={videos == '3' ? "checked" : ""}
                                                    onChange={(e) => {
                                                        handleChangeVideos("3", e.target.checked);
                                                    }}
                                                />
                                                <span className="checkmark"></span>
                                                <h6>Videos</h6>
                                            </label>
                                        </form>
                                    </div>
                                </div>
                                <div className="create-story-right">
                                    <div className="myStory">
                                        <p>Who can see responses to my story?</p>
                                    </div>

                                    <div
                                        className="responses-MyStory-radio"
                                        onChange={(e) => setAllowEmojis(e.target.value)}
                                    >
                                        <h6>Emojis</h6>
                                        <div className="responses-MyStory-radio-buttons">
                                            <div>
                                                <p>Everyone</p>
                                            </div>
                                            <label className="custom_radio">
                                                <input
                                                    type="radio"
                                                    name="allowEmojis"
                                                    value="public"
                                                    checked={allowEmojis === "public"}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="responses-MyStory-radio-buttons">
                                            <div>
                                                <p>Only Me</p>
                                            </div>
                                            <label className="custom_radio">
                                                <input
                                                    type="radio"
                                                    name="allowEmojis"
                                                    value="private"
                                                    checked={allowEmojis === "private"}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div
                                        className="responses-MyStory-radio"
                                        onChange={(e) => setAllowComments(e.target.value)}
                                    >
                                        <h6>Comments</h6>
                                        <div className="responses-MyStory-radio-buttons">
                                            <div>
                                                <p>Everyone</p>
                                            </div>
                                            <label className="custom_radio">
                                                <input
                                                    type="radio"
                                                    name="allowComments"
                                                    value="public"
                                                    checked={allowComments === "public"}
                                                // checked
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="responses-MyStory-radio-buttons">
                                            <div>
                                                <p>Only Me</p>
                                            </div>
                                            <label className="custom_radio">
                                                <input
                                                    type="radio"
                                                    name="allowComments"
                                                    value="private"
                                                    checked={allowComments === "private"}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div
                                        className="responses-MyStory-radio"
                                        onChange={(e) => setAllowVideos(e.target.value)}
                                    >
                                        <h6>Videos</h6>
                                        <div className="responses-MyStory-radio-buttons">
                                            <div>
                                                <p>Everyone</p>
                                            </div>
                                            <label className="custom_radio">
                                                <input
                                                    type="radio"
                                                    name="allowVideos"
                                                    value="public"
                                                    checked={allowVideos === "public"}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="responses-MyStory-radio-buttons">
                                            <div>
                                                <p>Only Me</p>
                                            </div>
                                            <label className="custom_radio">
                                                <input
                                                    type="radio"
                                                    name="allowVideos"
                                                    value="private"
                                                    checked={allowVideos === "private"}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            className="btn btn-primary save-but pull-right"
                                            onClick={(e) => {
                                                handleSubmitStorySettings(e);
                                            }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default Storysettings

