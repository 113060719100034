export async function FilterAuthorizedStories(allStories, loginCommunity) {
    return new Promise(async (resolve, reject) => {
        let teamArr =
            (await loginCommunity) &&
            loginCommunity[0].subscriptionsData.teamDetails.filter(obj => {
                return obj.isChecked == true
            })
        let activeTeams = await loginCommunity[0].teamsData.filter(el => {
            return teamArr.find(element => {
                return element.teamId === el._id;
            });
        });
        let myTeamsArray = [];
        if (activeTeams && activeTeams.length > 0) {
            for (let i = 0; i < activeTeams.length; i++) {
                myTeamsArray.push(activeTeams[i]._id)
            }
        }
        let filteredStories = []
        allStories.map(obj => {
            new Promise(async (resolve, reject) => {
                if (obj.accessTo == 'team') {
                    await obj.selectedTeam.filter(u => {
                        if (myTeamsArray.includes(u)) {
                            filteredStories.push(obj)
                        }
                        else {
                            resolve();
                        }
                    })
                }
                else {
                    filteredStories.push(obj)
                }
            })

        })
        resolve(filteredStories)
    })


}