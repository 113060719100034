import React from 'react';
import AppConstants from '../../ApplicationConstants'

function PublicStoryHeader({ isAuthunicated }) {
    return (
        <div>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light bg-white">
                    <div className="container-fluid ">
                        <a className="navbar-brand" href={`${AppConstants.BASE_DEV_URL}/auth/login`}>
                            <div className="publicStoryLogo">
                                <img src="../../assets/images/career-quo-logo.acb4dc0c.png" alt="" width="100%" height="100%" />
                            </div>
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                {isAuthunicated ?
                                    <li className="nav-item">
                                        <a className="nav-link"
                                            href={
                                                AppConstants.BLOOMING_DEV_URL +
                                                `/?` +
                                                localStorage.userId +
                                                `&` +
                                                localStorage.accessToken +
                                                `&` +
                                                localStorage.isAdmin +
                                                `&` +
                                                localStorage.universityId +
                                                `&` +
                                                localStorage.isUpsquadStatus +
                                                `&` +
                                                localStorage.refreshToken
                                            }>
                                            <div className="nav-icons">
                                                <div className='blooming'>
                                                    <img
                                                        src='assets/images/bloomingIcon.png'
                                                        width='100%'
                                                        height='100%'
                                                        alt=''
                                                    />
                                                </div>
                                            </div>
                                            <span>
                                                Blooming
                                            </span>
                                        </a>
                                    </li>
                                    : ""
                                }

                                {isAuthunicated ?
                                    <li className="nav-item">
                                        <a className="nav-link"
                                            href={
                                                AppConstants.SOCIAL_DEV_URL +
                                                `/?` +
                                                localStorage.userId +
                                                `&` +
                                                localStorage.accessToken +
                                                `&` +
                                                localStorage.isAdmin +
                                                `&` +
                                                localStorage.universityId +
                                                `&` +
                                                localStorage.isUpsquadStatus +
                                                `&` +
                                                localStorage.refreshToken
                                            }>
                                            <div className="nav-icons">
                                                <i className='fas fa-home'></i>
                                            </div>
                                            <span>
                                                Social
                                            </span>
                                        </a>
                                    </li>
                                    : ""
                                }

                                <li className="nav-item">
                                    <a className="nav-link" href={`${AppConstants.BASE_DEV_URL}/discover`}>
                                        <div className="nav-icons">
                                            <i className='fas fa-users'></i>
                                        </div>
                                        <span>
                                            People
                                        </span>
                                    </a>
                                </li>

                                {isAuthunicated ?
                                    <li className="nav-item">
                                        <a className="nav-link"
                                            href={
                                                AppConstants.MESSAGE_DEV_URL +
                                                `/?` +
                                                localStorage.userId +
                                                `&` +
                                                localStorage.accessToken +
                                                `&` +
                                                localStorage.isAdmin +
                                                `&` +
                                                localStorage.universityId +
                                                `&` +
                                                localStorage.isUpsquadStatus +
                                                `&` +
                                                localStorage.refreshToken
                                            }
                                        >
                                            <div className="nav-icons">
                                                <i className='far fa-comments'></i>
                                            </div>
                                            <span>
                                                Messages
                                            </span>
                                        </a>
                                    </li>
                                    : ""}


                                {isAuthunicated ?
                                    <li className="nav-item">
                                        <a className="nav-link"
                                            href={
                                                AppConstants.BASE_DEV_URL +
                                                `/sessionDetails/adminDomain/` +
                                                // localStorage.userId +
                                                // `&` +
                                                // localStorage.accessToken +
                                                // `&` +
                                                // localStorage.isAdmin +
                                                // `&` +
                                                localStorage.universityId
                                                // `&` +
                                                // localStorage.isUpsquadStatus +
                                                // `&` +
                                                // localStorage.refreshToken
                                            }
                                        >
                                            <div className="nav-icons">
                                                <i className='bi bi-camera-video'></i>
                                            </div>
                                            <span>
                                                Meetings
                                            </span>
                                        </a>
                                    </li>
                                    : ""}


                                <li className="nav-item">
                                    <a className="nav-link" href={`${AppConstants.BASE_DEV_URL + `/events-list`}`}>
                                        <div className="nav-icons">
                                            <i className="bi bi-calendar3"></i>
                                        </div>
                                        <span>
                                            Events
                                        </span>
                                    </a>
                                </li>
                                {isAuthunicated ?
                                <li className='nav-item dropdown'>
                                <a
                                  className='nav-link dropdown-toggle'
                                  href='https://dev.upsquad.com/'
                                  id='navbarDropdown'
                                  role='button'
                                  data-bs-toggle='dropdown'
                                  aria-expanded='false'
                                >
                                  <div className='nav-icons'>
                                    {/* <i className="bi bi-person-fill"></i> */}
                                    {/* <i className='fas fa-user'></i> */}
                                    <i class="fas fa-user"></i>
                                  </div>
                                  <span className='fw-normal'>
                                    {' '}
                                    {localStorage.fName} {localStorage.lName}
                                  </span>
                                </a>
                                <ul
                                  className='dropdown-menu'
                                  aria-labelledby='navbarDropdown'
                                >
                                  <li>
                                    {localStorage.isProfessional == 'true' ? (
                                      <a
                                        className='dropdown-item'
                                        href={
                                          AppConstants.BASE_DEV_URL +
                                          `/professional-profile/adminDomain/` +
                                          localStorage.userId
                                        }
                                      >
                                        My Profile
                                      </a>
                                    ) : (
                                      <a
                                        className='dropdown-item'
                                        href={
                                          AppConstants.BASE_DEV_URL +
                                          `/student-profile/adminDomain/` +
                                          localStorage.universityId
                                        }
                                      >
                                        My Profile
                                      </a>
                                    )}
                                  </li>
                                  <li>
                                    <a
                                      className='dropdown-item'
                                      href={
                                        AppConstants.BASE_DEV_URL +
                                        `/user-account/adminDomain/` +
                                        localStorage.userId
                                      }
                                    >
                                      Edit Profile
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className='dropdown-item'
                                      href={
                                        AppConstants.BASE_DEV_URL + `/calendar-connections`
                                      }
                                    >
                                      Sync Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className='dropdown-item'
                                      href={
                                        AppConstants.BASE_DEV_URL +
                                        `/set-availability/` +
                                        localStorage.userId
                                      }
                                    >
                                      Set Availability
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className='dropdown-item'
                                      href={AppConstants.BASE_DEV_URL + `/settings`}
                                    >
                                      Settings
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className='dropdown-item'
                                      href={AppConstants.BASE_DEV_URL + `/auth/login`}
                                    >
                                      Logout
                                    </a>
                                  </li>
                                </ul>
                              </li>
                                    :
                                    <>
                                        <li className="nav-item">
                                            <a className="nav-link" href={`${AppConstants.BASE_DEV_URL}`}>
                                                <span>
                                                    Login
                                                </span>
                                            </a>
                                        </li>
                                        <a href={`${AppConstants.BASE_DEV_URL}/`}>
                                            <button className=" post-but text-white">
                                                Sign up for Free
                                            </button>
                                        </a>
                                    </>
                                }

                            </ul>
                        </div>
                    </div>
                </nav >
            </header>
        </div>
    );
}

export default PublicStoryHeader;